import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { ISubscriptionApiClient, Products } from '@cyber-range/cyber-range-api-subscription-client';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { defineStore } from 'pinia';

export const useSubscriptionStore = defineStore('subscriptionStore', 
{
    state: () =>
    ({
        products: [] as string[],
    }),
    getters:
    {
        canAllowPublicAccess(): boolean
        {
            return this.products.includes(Products.CompetitionAllowPublicRegistration);
        },
        canAllowDirectLogin(): boolean 
        {
            return this.products.includes(Products.CompetitionAllowDirectLogin);
        },
        canAllowIntegratedEnvironment(): boolean
        {
            return this.products.includes(Products.CompetitionAllowIntegratedEnvironmentsFeature);
        },
    },
    actions:
    {
        async fetchSubscribedProducts(): Promise<void>
        {
            const subscriptionAliClient: ISubscriptionApiClient = useApiClientStore().subscriptionApiClient;
            const organizationId = (useCompetitionStore().currentCompetition as ICompetition).organizationId;

            this.products = await subscriptionAliClient.getOrganizationSubscribedProducts(organizationId);
        }
    }
});