<template>
    <v-menu v-bind="$attrs">
        <template #activator="{on, value}">
            <slot name="menuToggle" v-bind="{on, value, listId}">
                <v-btn v-on="on" :disabled="disabled" icon plain small :aria-label="label" :aria-owns="listId" aria-haspopup="true" :aria-expanded="`${value}`" ref="defaultToggleButton">
                    <v-icon>{{ icon }}</v-icon>
                </v-btn>
            </slot>
        </template>
        <div :id="listId" ref="listWrapper">
            <slot />
        </div>
    </v-menu>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { v4 as uuid } from 'uuid';

@Component
export default class AccessibleMenu extends Vue
{   
    @Prop({type: Boolean}) disabled: boolean;
    @Prop({ type: String, required: true }) label: string;
    @Prop({ type: String, default: 'settings' }) icon: string;

    listId = uuid();
}
</script>
