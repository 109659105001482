<template>
    <div v-if="showDialogs">
        <confirmation-dialog v-if="!isConfirmed && limitsViolationMessage" :value="true" @confirm="cancel" :cancel="false" :confirm="$t('CHALLENGES_ADD_CHALLENGES_FROM_LIBRARY_COMPETITION_FULL_CONFIRM')" :loading="isLoading" :title="$t('CHALLENGES_ADD_CHALLENGES_FROM_LIBRARY_COMPETITION_FULL_TITLE')" class="addChallengesFromLibraryLimitsViolationDialog">
            <span class="addChallengesFromLibraryLimitsViolationMessage">
                {{ limitsViolationMessage }}
            </span>
        </confirmation-dialog>
        <confirmation-dialog v-else-if="!isConfirmed" :value="true" @confirm="confirm" @cancel="cancel" :loading="isLoading" class="addChallengesFromLibraryConfirmDialog">
            <span class="addChallengesFromLibraryDialogBodyMessage">{{ $t('CHALLENGES_ADD_CHALLENGES_FROM_LIBRARY_DIALOG_MESSAGE', { challengeCount, categoryCount }) }}</span>
        </confirmation-dialog>
        <job-progress-dialog v-else :jobId="jobId" :value="true" @confirm="onJobDone" :dialogTitle="$t('CHALLENGES_ADD_CHALLENGES_FROM_LIBRARY_DIALOG_TITLE')" @cancel="redirectToRefreshedChallenges" :cancelText="$t('CHALLENGES_ADD_CHALLENGES_FROM_LIBRARY_REDIRECT_TEXT')"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import StoreAction from '@/interfaces/storeAction';
import { IJobApiClient } from '@cyber-range/cyber-range-api-job-client';
import { Prop, VModel, Watch } from 'vue-property-decorator';
import { Challenge, ChallengeArtifact, ChallengeChoice, ChallengeSettings, Flag, Hint, IChallenge, IChallengeApiClient } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { ILibraryEntry } from '@cyber-range/cyber-range-api-ctf-library-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import Config from '@/config';
import { TranslateResult } from 'vue-i18n';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';

@Component({})
export default class AddChallengesFromLibraryDialog extends Vue 
{
    @Action(StoreAction.FetchChallenges) fetchChallenges: ()=>Promise<void>;

    @VModel({ type: Boolean, default: false }) showDialogs: boolean;
    @Prop({ type: Array, required: true }) libraryEntries:ILibraryEntry[];

    isConfirmed: boolean = false;
    jobId:string = '';

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get bulkChallengeApiClient(): IChallengeApiClient
    {
        return useApiClientStore().bulkChallengeApiClient;
    }
    get jobApiClient(): IJobApiClient
    {
        return useApiClientStore().jobApiClient;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO
    
    get challenges(): IChallenge[]
    {
        return this.libraryEntries.map(entry => new Challenge({
            competitionId: this.competition.id,
            name: entry.name,
            description: entry.description,
            hints: entry.hints.map(h => new Hint(h)),
            artifacts: entry.artifacts.map(a => ChallengeArtifact.fromJson(a)),
            category: entry.category || Config.UNCATEGORIZED,
            choice: entry.choice ? new ChallengeChoice(entry.choice) : undefined,
            flags: entry.flags.map(flag => new Flag(flag)),
            settings: new ChallengeSettings(entry.settings),
            points: entry.points,
            tags: entry.tags,
            libraryId: entry.id,
            enabled: entry.enabled,
            notes: entry.notes,
            solution: entry.solution
        }));
    }

    get bulkAddArtifactSizeInMb(): number
    {
        return this.libraryEntries.map(entry=> (entry.artifacts || []).map(artifact=>artifact.sizeInMb || 0)).flat().reduce((total,current)=> {return total + current},0);
    }

    get limitsViolationMessage(): TranslateResult
    {
        if((this.competition?.statistics?.fileStorageInMb || 0) + this.bulkAddArtifactSizeInMb  > (this.competition.limits?.maxFileStorageInMb ?? Number.MAX_VALUE))
        {
            return this.$t('CHALLENGES_ADD_CHALLENGES_FROM_LIBRARY_STORAGE_FULL_MESSAGE');
        }
        else if((this.competition?.statistics?.numberOfChallenges || 0) + this.libraryEntries.length > (this.competition.limits?.maxChallenges ?? Number.MAX_VALUE))
        {
            return this.$t('CHALLENGES_ADD_CHALLENGES_FROM_LIBRARY_CHALLENGES_FULL_MESSAGE');
        }
        else
        {
            return undefined;
        }
    }

    get challengeCount(): number
    {
        return this.challenges.length;
    }

    get categoryCount(): number
    {
        return new Set(this.challenges.map(challenge => challenge.category)).size
    }
    
    redirectToRefreshedChallenges()
    {
        this.onJobDone();
        this.$router.push(`/challenges/${encodeURIComponent(this.libraryEntries[0].category).toLowerCase()}`);
    }

    @Watch('showDialogs', { immediate: true })
    onValueChanged()
    {
        // reset isConfirmed value anytime the visibility of this component is changed
        this.isConfirmed = false;
    }

    async confirm()
    {
        await Promise.all(this.challenges.map(challenge => this.bulkChallengeApiClient.add(challenge)));
        this.jobId = await this.jobApiClient.submit();
        this.isConfirmed = true;
    }

    onJobDone()
    {
        this.fetchChallenges()
        this.$emit('confirm', this.challenges);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showDialogs = false;
    }
}
</script>
