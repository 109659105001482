<template>
     <v-row class="mt-0">
        <v-col cols="12" md="10" sm="8">
            <h1>
                <span>
                    {{$t('TEAM_TITLE')}}
                </span> 
                <span v-if="team && team.name">
                     - {{team.name}}
                </span>
            </h1>
        </v-col>
        <v-col cols="12" md="2" sm="4">
            <v-layout justify-end>
                <v-flex ml-3 flex-grow-0 v-if="(allowTeamNameChange || allowTeamMembershipChange) && canUpdateTeam(team.id, competition.id, competition.organizationId)">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" :disabled="!isReady" @click="onEditClicked" small fab outlined class='teamEditButton'>
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        {{$t('TEAM_EDIT')}}
                    </v-tooltip>
                </v-flex>
                <v-flex ml-3 flex-grow-0 v-if="allowTeamMembershipChange && canKickPlayerFromTeam(team.id, competition.id, competition.organizationId)">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" :disabled="!isReady" @click="onLeaveClicked" small fab outlined class='teamLeaveButton'>
                                <v-icon>exit_to_app</v-icon>
                            </v-btn>
                        </template>
                        {{$t('TEAM_LEAVE')}}
                    </v-tooltip>
                </v-flex>
            </v-layout>
        </v-col>

        <v-col cols="12" class="ma-0 pa-0 loadingRow">
            <loading v-if="isForegroundFetching" class="teamLoading" />
            <loading v-else-if="isBackgroundFetching" class="teamUpdating" :message="$t('TEAM_UPDATING')" />
        </v-col>
        <v-col v-if="isCurrentPlayerPendingApproval" cols="12" align="center" class="ma-6 pa-6 teamPendingApproval">
            <p class="headline">{{$t('TEAM_PENDING_APPROVAL_TITLE')}}</p>
            <template v-if="teamIsFull">
                <div class="mb-0">{{$t('TEAM_PENDING_APPROVAL_IS_FULL_SUBTITLE')}}</div>
                <div class="mb-12">{{$t('TEAM_PENDING_APPROVAL_IS_FULL_CONSIDER_CHANGING_TEAMS')}}</div>
            </template>
            <div v-else class="mb-12">{{$t('TEAM_PENDING_APPROVAL_SUBTITLE')}}</div>
            <v-btn v-if="canRejectTeamMembershipRequest(competition.id, competition.organizationId)" :dark="!isConfirmLight" :light="isConfirmLight" color="confirm" @click="onLeaveClicked" class="mt-12 elevation-0" >{{$t('TEAM_LEAVE')}}</v-btn>
        </v-col>
        <template v-else-if="isReady">
            <v-col cols="12" sm="6" md="4" class="teamMemberBoxParent">
                <v-card :dark="isDark" flat class="teamMemberBox pa-4 elevation-0 alphaBackground">
                    <team-members :team="team" :players="players" :isDark="isDark" :editable="false" class="teamMember" />
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="8">
                <score-chart :teamId="myTeamId" :height="300" :isDark="isDark" class="teamScoreChart" />
            </v-col>
            <v-col cols="12">
                <team-submissions-table :teamId="myTeamId" :isDark="isDark" class="teamSubmissionsTable" />
            </v-col>
        </template>
        <v-col v-if="!isFetching && !isReady" cols="12" align="center" class="ma-5 pa-5">
            <p class="teamError">
                {{$t('TEAM_ERROR')}}
            </p>
        </v-col>
        <edit-team-dialog v-model="showTeamEditDialog" :team="team" @confirm="refresh" @kicked="onPlayerKicked" />
        <leave-team-dialog v-model="showTeamLeaveDialog" />
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import StoreGetter from '@interfaces/storeGetter';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import StoreAction from '@/interfaces/storeAction';
import { IPlayer, PlayerStatus } from '@cyber-range/cyber-range-api-ctf-player-client';
import { ITeam, Team as TeamApiTeam } from '@cyber-range/cyber-range-api-ctf-team-client';
import { Watch } from 'vue-property-decorator';
import titleStrings from '@/entities/strings/definitions/titleStrings';
import { useThemeStore } from '@/stores/themeStore';
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';

@Component({
    metaInfo() {
        return { title: this.team?.name || titleStrings.en.TITLE_TEAM };
    }
})
export default class Team extends Vue 
{   
    @Getter(StoreGetter.GetMyTeam) myTeamId:string;
    @Getter(StoreGetter.GetCurrentPlayer) currentPlayer:IPlayer;
    @Getter(StoreGetter.GetTeam) getTeam: (teamId) => ITeam;
    @Getter(StoreGetter.IsSubmissionFetched) isSubmissionFetched:boolean;
    @Getter(StoreGetter.IsTeamsFetched) isTeamsFetched:boolean;
    @Getter(StoreGetter.GetMyTeamPlayers) myTeamPlayers:IPlayer[];
    @Getter(StoreGetter.IsMyTeamPlayersFetched) isMyTeamPlayersFetched:boolean;

    @Action(StoreAction.FetchTeams) fetchTeams: (options?:{background:boolean}) => Promise<void>;
    @Action(StoreAction.FetchSubmissions) fetchSubmissions: (options?:{background:boolean}) => Promise<void>;
    @Action(StoreAction.FetchMyTeamPlayers) fetchMyTeamPlayers: (options?:{background:boolean}) => Promise<void>;
    @Action(StoreAction.FetchCurrentPlayer) fetchCurrentPlayer: () => Promise<IPlayer>;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isLight;
    }
    get isConfirmLight(): boolean
    {
        return useThemeStore().isConfirmLight;
    }
    get myUserId(): string
    {
        return useAuthenticationStore().identityId;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    canKickPlayerFromTeam(teamId:string, competitionId: string, organizationId: string): boolean
    {
        return useAuthorizationStore().canKickPlayerFromTeam(teamId, competitionId, organizationId);
    }
    canRejectTeamMembershipRequest(teamId:string, competitionId: string, organizationId: string): boolean
    {
        return useAuthorizationStore().canRejectTeamMembershipRequest(teamId, competitionId, organizationId);
    }
    canUpdateTeam(teamId:string, competitionId:string, organizationId:string): boolean
    {
        return useAuthorizationStore().canUpdateTeam(teamId, competitionId, organizationId);
    }
    // END TODO

    isFetching = true;
    isReady = false;
    isForegroundFetching = false;
    isBackgroundFetching = false;
    team:ITeam = new TeamApiTeam();
    players:IPlayer[] = [];
    showTeamEditDialog:boolean = false;
    showTeamLeaveDialog:boolean = false;

    get isCurrentPlayerPendingApproval():boolean
    {
        return this.currentPlayer?.status === PlayerStatus.Joining;
    }
    
    get allowTeamNameChange(): boolean
    {
        return this.competition.settings?.allowTeamNameChange || false;
    }

    get allowTeamMembershipChange(): boolean
    {
        return this.competition.settings?.allowTeamMembershipChange || false;
    }

    get teamIsFull():boolean
    {
        return !!this.competition.limits && !!this.team?.statistics && (this.competition.limits.maxPlayersPerTeam <= this.team?.statistics?.numberOfPlayers);
    }

    @Watch('currentPlayer')
    onCurrentPlayerChanged()
    {
        if(this.currentPlayer.status === PlayerStatus.Unassigned)
        {
            window.location.reload();
        }
    }

    onEditClicked()
    {
        this.showTeamEditDialog = true;
    }

    onLeaveClicked()
    {
        this.showTeamLeaveDialog = true;
    }

    async onPlayerKicked(player:IPlayer)
    {
        if(player.userId === this.myUserId)
        {
            window.location.reload();
        }
    }

    async created()
    {
        await this.refresh();
    }

    async refresh()
    {
        try
        {
            let foregroundPromises = [];
            let backgroundPromises = [];

            if(!this.isMyTeamPlayersFetched)
            {
                foregroundPromises.push(this.fetchMyTeamPlayers());
            }
            else
            {
                backgroundPromises.push(this.fetchMyTeamPlayers({background:true}));
            }

            if(!this.isTeamsFetched) 
            {
                foregroundPromises.push(this.fetchTeams());
            }
            else
            {
                backgroundPromises.push(this.fetchTeams({background:true}));
            }

            if(!this.isSubmissionFetched)
            {
                foregroundPromises.push(this.fetchSubmissions());
            }
            else
            {
                backgroundPromises.push(this.fetchSubmissions({background:true}));
            }

            if(this.currentPlayer === undefined)
            {
                foregroundPromises.push(this.fetchCurrentPlayer());
            }

            if(foregroundPromises.length > 0)
            {
                this.isForegroundFetching = true;
                try
                {
                    await Promise.all(foregroundPromises);
                }
                finally
                {
                    this.isForegroundFetching = false;
                }
            }
            
            this.team = this.getTeam(this.myTeamId);
            this.players = this.myTeamPlayers; //Must be set after this.team

            //All data should be fetched and ready to show the scoreboard.
            this.isReady = true;
            
            //Continue to fetch more data in the background. This is to get the most up-to-date scores.
            if(backgroundPromises.length > 0)
            {
                this.isBackgroundFetching = true;

                try
                {
                    await Promise.all(backgroundPromises);
                    
                    this.team = this.getTeam(this.myTeamId);
                    this.players = this.myTeamPlayers; //Must be set after this.team
                }
                finally
                {
                    this.isBackgroundFetching = false;
                }
            }
        }
        catch{}
        finally
        {
            this.isFetching = false;
        }
    }
}
</script>
<style scoped>
.teamMemberBoxParent
{
    display: grid;
}
.theme--dark.teamMemberBox
{
    height: 100%;
}
.loadingRow{
    min-height: 25px;
}
</style>