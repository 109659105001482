<template>
    <v-row align="center" justify="center">
        <template v-if="isAllItemsSelected">
            <span>{{ $t('TABLE_HEADER_ALL_ITEMS_SELECTED', { count: selected, itemName: item }) }}</span>
            <v-btn text color="link" @click="onAllItemsSelectedClicked">{{ $t('TABLE_HEADER_CLEAR_SELECTION') }}</v-btn>
        </template>
        <template v-else>
            <span>{{ $t('TABLE_HEADER_ALL_ITEMS_ON_PAGE_SELECTED', { count: selected, itemName: item }) }}</span>
            <v-btn text color="link" @click="onAllItemsOnPageClicked">{{ $t('TABLE_HEADER_SELECT_ALL_PROMPT', { count: total, itemName: item }) }}</v-btn>
        </template>
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class SelectAllTableItems extends Vue {
    @Prop(Number) total: number;
    @Prop(Number) selected: number;
    @Prop(String) item: string;

    get isAllItemsSelected(): boolean
    {
        return this.total === this.selected;
    }

    onAllItemsSelectedClicked()
    {
        this.$emit('clear')
    }

    onAllItemsOnPageClicked()
    {
        this.$emit('select-all');
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none !important;
}
</style>
