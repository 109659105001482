<template>
    <confirmation-dialog v-model="showConfirmationDialog" :title="$t('HEADER_JOINING_REQUEST_ALERT_DIALOG_TITLE')" :confirm="$t('HEADER_JOINING_REQUEST_ALERT_DIALOG_CONFIRM')" :cancel="$t('HEADER_JOINING_REQUEST_ALERT_DIALOG_REJECT')" @confirm="approve" @cancel="reject" :disabled="isLoading" id="teamJoiningRequestAlertDialog">
        <p class="pt-3">
            {{message}}
        </p>
        <template slot="bottomLeft">
            <v-btn text :disabled="isLoading" @click="cancel" class="bottomLeftButton">{{$t('HEADER_JOINING_REQUEST_ALERT_DIALOG_CANCEL')}}</v-btn>
        </template>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Mutation } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { ITeamApiClient } from '@cyber-range/cyber-range-api-ctf-team-client';
import IAlert from '@/interfaces/iAlert';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import JoiningRequestAlert from '@/entities/joiningRequestAlert';
import StoreMutation from '../../../interfaces/storeMutation';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';

@Component({components:{}})
export default class JoiningRequestAlertDialog extends Vue 
{  
    @Prop(Boolean) value: boolean;
    @Prop(Object) alert:JoiningRequestAlert;

    @Mutation(StoreMutation.RemoveAlert) removeAlert: (alert:IAlert)=>void; 

    showConfirmationDialog:boolean = false;

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get teamApiClient(): ITeamApiClient
    {
        return useApiClientStore().teamApiClient;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO
    
    @Watch('value')
    onValueChanged()
    {
        this.showConfirmationDialog = this.value;
    }

    mounted() 
    {
        this.showConfirmationDialog = this.value;
    }

    get message():string
    {
        return this.alert?.message;
    }
    
    async approve()
    {
        await this.teamApiClient.approve(this.alert.player.teamId, this.alert.player.id);

        this.removeAlert(this.alert);

        this.$emit('approved', true);
        this.close();
    }

    async reject()
    {
        await this.teamApiClient.reject(this.alert.player.teamId, this.alert.player.id);

        this.removeAlert(this.alert);

        this.$emit('rejected', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.$emit('input', false);
    }
}
</script>