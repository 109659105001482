import IExportedSubmission from "@interfaces/iExportedSubmission";

export default class ExportedSubmission implements IExportedSubmission
{
    playerName:string;
    teamName:string;
    challengeName:string;
    challengeCategory:string;
    scoreAdjustment:number;
    createdTimestamp:string;
    flag: string;

    constructor(data?:Partial<IExportedSubmission>)
    {
        this.playerName = data?.playerName;
        this.teamName = data?.teamName;
        this.challengeName = data?.challengeName;
        this.challengeCategory = data?.challengeCategory;
        this.scoreAdjustment = data?.scoreAdjustment;
        this.createdTimestamp = data?.createdTimestamp;
        this.flag = data?.flag;
    }

    static toCsvHeaders(): string[]
    {
        return ['player', 'team', 'challenge', 'category', 'points', 'timestamp', 'flag'];
    }

    toCsvRow(): (string|number)[]
    {
        return [this.playerName,
                this.teamName,
                this.challengeName,
                this.challengeCategory,
                this.scoreAdjustment,
                this.createdTimestamp,
                this.flag];
    }
};