import { IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { ILibraryEntry } from '@cyber-range/cyber-range-api-ctf-library-client';

import MultipleChoiceChallengeFlagInput from "./multipleChoiceChallengeFlagInput.default.vue";
import MultipleSelectChallengeFlagInput from "./multipleSelectChallengeFlagInput.default.vue";
import TextChallengeFlagInput from "./textChallengeFlagInput.default.vue";

export function selectChallengeFlagInput(challenge: IChallenge|ILibraryEntry)
{
    if (challenge && 'choice' in challenge && challenge.choice)
    {
        return challenge.choice.required === 1 ? MultipleChoiceChallengeFlagInput : MultipleSelectChallengeFlagInput
    }
    else
    {
        return TextChallengeFlagInput;
    }
}
