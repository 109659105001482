export default {
    en: {
        MANAGE: 'Admins',
        MANAGE_SETTINGS: 'Settings',
        MANAGE_PLAYERS: 'Players',
        MANAGE_TEAMS: 'Teams',
        MANAGE_CHALLENGES: 'Challenges',
        MANAGE_LIBRARY: 'Library'
    }
};
