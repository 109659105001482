<template>
    <v-row wrap>
        <v-col cols="12" class="mt-1">
            <v-checkbox v-if="canUpdateDefaultChallenge()" v-model="settings.isDefaultChallenge" :label='$t("LIBRARY_ENTRY_SETTINGS_IS_DEFAULT_CHALLENGE")' 
                        :dark="isDark" :light="isLight" color="unset" :disabled="isLoading" class="ml-0 pl-0 isDefaultChallenge" id="isDefaultChallenge" />
            <v-text-field type="number" v-model.number="settings.maxNumberOfAttempts" :label="$t('LIBRARY_ENTRY_SETTINGS_MAX_NUMBER_OF_ATTEMPTS')" :dark="isDark" 
                        :rules="maxNumberOfAttemptsRule" :counter="maxMaxNumberOfAttempts" :disabled="isLoading" class="maxNumberOfAttempts" id="maxNumberOfAttempts" />
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ILibraryEntrySettings } from '@cyber-range/cyber-range-api-ctf-library-client';
import { Prop, Watch } from 'vue-property-decorator';
import Rule from '@/validations/Rule';
import Config from '@/config';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useAuthorizationStore } from '@stores/authorizationStore';

@Component
export default class LibraryEntrySettings extends Vue 
{  
    @Prop(Object) settings:ILibraryEntrySettings;
    @Prop(Boolean) choice:boolean;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    canUpdateDefaultChallenge(): boolean
    {
        return useAuthorizationStore().canUpdateDefaultChallenge();
    }
    // END TODO
    
    maxMaxNumberOfAttempts:number = 256;

    maxNumberOfAttemptsRule = [(v)=> !!v ? Rule.require(v) : true, 
                               (v) => !!v ? Rule.minValue(v, 1) : true, 
                               (v) => !!v ? Rule.maxValue(v, this.maxMaxNumberOfAttempts) : true]

    @Watch('choice')
    onFlagTypeChanged(value: boolean) {
        if (this.settings.maxNumberOfAttempts === Config.DEFAULT_MAX_NUMBER_OF_ATTEMPTS ||
            this.settings.maxNumberOfAttempts === Config.DEFAULT_MULTIPLE_CHOICE_MAX_NUMBER_OF_ATTEMPTS)
        {
            this.settings.maxNumberOfAttempts = value ?
                Config.DEFAULT_MULTIPLE_CHOICE_MAX_NUMBER_OF_ATTEMPTS :
                Config.DEFAULT_MAX_NUMBER_OF_ATTEMPTS;
        }
    }
}
</script>