export default {
    en: {
        HINT_TITLE: 'Hint - {cost} pts',
        HINT_ADD: 'Add a Hint',
        HINTS_ADD_DIALOG_TITLE: 'Add a Hint',
        HINTS_EDIT_DIALOG_TITLE: 'Edit a Hint',
        HINT_ADD_EDIT_DIALOG_MESSAGE: 'Hint message',
        HINT_ADD_EDIT_DIALOG_COST: 'Points to unlock this hint',
        HINT_ADD_EDIT_DIALOG_DELETE: 'Delete'
    }
};
