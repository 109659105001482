export default {
    en: {
        SCOREBOARD_TITLE: 'Scoreboard',
        SCOREBOARD_ALL_TEAMS: 'All teams',
        SCOREBOARD_TOP_10_TEAMS_TITLE: 'Top 10 teams',
        SCOREBOARD_TOP_10_AND_YOUR_TEAM_TITLE: 'Top 10 teams and your team',
        SCOREBOARD_TABLE_RANK: 'Rank',
        SCOREBOARD_TABLE_TEAM: 'Team',
        SCOREBOARD_TABLE_SCORES: 'Scores',
        SCOREBOARD_TABLE_EMPTY: 'The competition has no teams.',
        SCOREBOARD_CHART_EMPTY: 'No scores have been recorded.',
        SCOREBOARD_ERROR: 'Could not show the scoreboard at the moment.',
        SCOREBOARD_UPDATE: 'Updating...',
        SCOREBOARD_EXPORT: 'Export Submissions',
        SCOREBOARD_HIDDEN_ALERT_TEXT: 'This scoreboard is currently only visible to competition admins.',
    }
};
