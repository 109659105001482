import ITheme from '@/interfaces/iTheme';
import backgroundImageVaCyberEduConf from '@assets/themes/usCyberGamesSeasonII/backgroundImageUSCyberGamesSeasonII.svg';

export class USCyberGamesSeasonII implements ITheme
{
    static Id = 'usCyberGamesSeasonII';
    id = USCyberGamesSeasonII.Id;

    name = 'U.S Cyber Games Season II';

    colors = {
        application: '#001A53', //#051c29
        text: '#E0E0E0',
        confirm: '#001A53',
        cancel: '#000000',
        secondary: '#62B4E4',
        primary: '#FFFFFF',
        falseswitch: '#808080',
        solved: '#001A53',
        unsolved: '#FFFFFF',
        unsolvedText: '#001A53',
        noMoreAttempts: '#A8545A',
        disabled: '#FF4B1F',
        tag: '#62B4E4',
        activeTab: '#62B4E4',
        dialogText: '#303030',
        dialogSuccessText: '#4CAF50',
        dialogFailureText: '#F44336',
        dialogIcon: '#001A53',
        success: '#4CAF50',
        warning: '#FF9800',
        link: '#62B4E4'
    };

    fontFamily = "Blinker-Bold";
    isDark = true;
    isDialogDark = false;
    backgroundImage = backgroundImageVaCyberEduConf;
}
