import { IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';

export default class StatisticsEntryView
{
    get name():string
    {
        return this.challenge.name;
    }

    get category():string
    {
        return this.challenge.category;
    }

    get points():Number
    {
        return this.challenge.points;
    }

    get successfulAttempts():number
    {
        return this.challenge.statistics?.successfulAttempts || 0;
    }

    get failedAttempts():number
    {
        return this.challenge.statistics?.failedAttempts || 0;
    }

    get creationDate(): string
    {
        return this.challenge.protectedInformation?.creationDate;
    }

    constructor(public challenge:IChallenge)
    {
    }
};