<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import BaseSettings from './Settings.vue';

@Component({components:{}})
export default class Settings extends mixins(BaseSettings) 
{   
}
</script>

<style scoped>
#settingsConfirmButton
{
  background-color: var(--v-primary-base) !important;
}
.floatingIconTooltip{
    position: absolute;
    margin-top: 4px;
    margin-left: 210px;
}
.show-scoreboard-tooltip{
    position: absolute;
    margin-top: 4px;
    margin-left: 235px;
    z-index: 1;
}
</style>