<template>
    <confirmation-dialog v-model="showConfirmationDialog" :title="isNewInvitation ? $t('PLAYERS_INVITATION_EDIT_DIALOG_TITLE_ADD') : $t('PLAYERS_INVITATION_EDIT_DIALOG_TITLE_EDIT')" @confirm="confirm" @cancel="cancel" :loading="isLoading" id="invitationAddEditDialog">
        <v-form v-model="valid" ref="form">
            <v-text-field v-model="name" :label="$t('PLAYERS_INVITATION_EDIT_DIALOG_NAME')" :rules="nameRules" :counter="maxNameLength" :disabled="isLoading" class="invitationAddEditDialogName" id="invitationAddEditDialogName"/>
            <v-text-field v-model="email" :label="$t('PLAYERS_INVITATION_EDIT_DIALOG_EMAIL')" :rules="emailRules" :counter="maxEmailLength" :disabled="isLoading" class="invitationAddEditDialogEmail" id="invitationAddEditDialogEmail"/>
            
            <template v-if="isNewInvitation">
                <v-subheader class="pl-0 mt-3">{{$t('PLAYERS_INVITATION_EDIT_DIALOG_ROLE')}}</v-subheader>
                <v-radio-group v-model="role" :dark="isDark" :light="isLight" class='invitationAddEditDialogRole'>
                    <v-radio v-for="role in availableRoles" :key="role" color="unset" :label="role" :value="role" :dark="isDark" :light="isLight" :class="`invitationAddEditDialogRole_${role}`"/>
                </v-radio-group>
            </template>
        </v-form>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { Prop, Watch } from 'vue-property-decorator';
import Rule from '@/validations/Rule';
import { IInvitationApiClient, IInvitation, Invitation } from '@cyber-range/cyber-range-api-invitation-client';
import { UserRole } from '@cyber-range/cyber-range-api-user-client';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';

@Component
export default class AddEditInvitationDialog extends Vue 
{
    @Prop(Boolean) value: boolean;
    @Prop(Object) invitation:IInvitation;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get invitationApiClient(): IInvitationApiClient
    {
        return useApiClientStore().invitationApiClient;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO
    
    valid = false;
    showConfirmationDialog:boolean = false;
    maxNameLength:number = 100;
    maxEmailLength:number = 256;
    nameRules = [Rule.require, (v)=>Rule.maxLength(v, this.maxNameLength)];
    emailRules = [Rule.require, (v)=>Rule.maxLength(v, this.maxNameLength), Rule.email];

    name:string = '';
    email:string = '';
    role:string = '';
    availableRoles = [UserRole.CtfAdmin, UserRole.CtfPlayer];

    get isNewInvitation():boolean
    {
        return this.invitation?.id === undefined;
    }

    async mounted() 
    {
        await this.load()
    }

    @Watch('value')
    async onValueChanged(value:boolean)
    {
        await this.load()
    }

    async load()
    {
        if(this.$refs.form)
        {
            await (<any>this.$refs.form).reset();
        }
        
        this.name = this.invitation?.name || '';
        this.email = this.invitation?.email || '';
        this.role = UserRole.CtfAdmin;
        
        this.showConfirmationDialog = this.value;
    }
    
    async confirm()
    {
        (<any>this.$refs.form).validate();

        if(!this.valid) return;
        
        if(this.isNewInvitation)
        {
            let invitationToAdd = new Invitation({name: this.name, email: this.email, roles: [this.role]});
            invitationToAdd.organizationId = this.competition.organizationId;
            invitationToAdd.ctfCompetitionId = this.competition.id;

            await this.invitationApiClient.add([invitationToAdd]);
        }
        else
        {
            let invitationToUpdate = new Invitation({name: this.name, email: this.email});
            invitationToUpdate.roles = undefined;

            await this.invitationApiClient.update(this.invitation.id, invitationToUpdate);
        }
    
        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.$emit('input', false);
    }
}
</script>