//Adaptation from: https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/mixins/validatable/index.ts
//Based on: https://github.com/vuetifyjs/vuetify/issues/3464

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Inject, Watch } from 'vue-property-decorator';

@Component
export default class Validatable extends Vue
{  
    @Inject('form') form
    @Prop(Array) rules;

    toValidateValue:any = 0;

    hasError: boolean = false
    errors:string[] = [];

    @Watch('toValidateValue')
    async onToValidateValueChanged()
    {
        this.validate();
    }

    reset () 
    {
        this.hasError = false;
        this.errors = [];
    }

    created () 
    {
        this.form && this.form.register(this)
    }

    beforeDestroy () 
    {
        this.form && this.form.unregister(this)
    }

    validate(force: boolean = false, value: any = undefined)
    {
        const errors = []
        value = value || this.toValidateValue

        if(this.rules && this.rules.length > 0)
        {
            for (let index = 0; index < this.rules.length; index++) {
                const rule = this.rules[index]
                const valid = typeof rule === 'function' ? rule(value) : rule

                if (valid === false || typeof valid === 'string') {
                    errors.push(valid || '')
                } else if (typeof valid !== 'boolean') {
                    console.error(`Rules should return a string or boolean, received '${typeof valid}' instead`, this)
                }
            }
        }

        this.errors = errors
        
        let errorIsGone = this.hasError && errors.length === 0;
        let errorOccurs = !this.hasError && errors.length > 0;

        if(errorIsGone)
        {
            this.$emit('update:error', false);
        }
        else if(errorOccurs)
        {
            this.$emit('update:error', true);
        }

        this.hasError = errors.length !== 0


        return !this.hasError
    }
}
</script>