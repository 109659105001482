<template>
    <ul class="countdownClock">
        <li v-if="days > 0" class="days">
            <p class="digit">{{ twoDigits(days) }}</p>
            <p class="unit">{{ daysUnit }}</p>
        </li>
        <li v-if="days > 0" class='separator'>
            <p class="digit">:</p>
            <p class="unit">&nbsp;</p>
        </li>
        <li class="hours">
            <p class="digit">{{ twoDigits(hours) }}</p>
            <p class="unit">{{ hoursUnit }}</p>
        </li>
        <li class='separator'>
            <p class="digit">:</p>
            <p class="unit">&nbsp;</p>
        </li>
        <li class="minutes">
            <p class="digit">{{ twoDigits(minutes) }}</p>
            <p class="unit">{{ minutesUnit }}</p>
        </li>
        <li class='separator'>
            <p class="digit">:</p>
            <p class="unit">&nbsp;</p>
        </li>
        <li class="seconds">
            <p class="digit">{{ twoDigits(seconds) }}</p>
            <p class="unit">{{ secondsUnit }}</p>
        </li>
    </ul>
</template>


<script lang="ts">
import Vue from "vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import { mixins } from 'vue-class-component';
import Countdown from './Countdown.vue';

@Component({})
export default class CountDownClock extends mixins(Countdown) {
}
</script>

<style>
.countdownClock {
  padding: 0 !important;
  margin: 0 !important;    
  text-overflow: clip;
  display: inline-flex;
}
.countdownClock li {
  display: inline-block;
  text-align: center;
  position: relative;
}
.countdownClock li.separator{
    margin: 0px;
    padding: 0px;
}
.countdownClock li p {
    margin: 0;
}
.countdownClock li:first-of-type {
  margin-left: 0;
}
.countdownClock li:last-of-type {
  margin-right: 0;
}
.countdownClock li:last-of-type:after {
  content: "";
}
.countdownClock .digit {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 0;
}
.countdownClock .text {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 10px;
}
</style>