<template>
    <confirmation-dialog v-model="showConfirmationDialog" :confirm="$t('PLAYERS_DELETE_DIALOG_CONFIRM')" @confirm="confirm" @cancel="cancel" :loading="isLoading" id="playerDeleteDialog">
        <span v-html="$t('PLAYERS_DELETE_DIALOG_MESSAGE', {name:this.name})" />
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import PlayerView from './PlayerView';
import { Prop, Watch } from 'vue-property-decorator';
import { IInvitationApiClient } from '@cyber-range/cyber-range-api-invitation-client';
import { IUserApiClient } from '@cyber-range/cyber-range-api-user-client';
import { encode } from 'html-entities';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';

@Component({components:{}})
export default class DeletePlayerDialog extends Vue 
{
    @Prop(Boolean) value: boolean;
    @Prop(Object) player:PlayerView;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get userApiClient(): IUserApiClient
    {
        return useApiClientStore().userApiClient;
    }
    get invitationApiClient(): IInvitationApiClient
    {
        return useApiClientStore().invitationApiClient;
    }
    // END TODO

    showConfirmationDialog:boolean = false;

    @Watch('value')
    onValueChanged()
    {
        this.showConfirmationDialog = this.value;
    }

    mounted() 
    {
        this.showConfirmationDialog = this.value;
    }

    get name():string
    {
        return encode(this.player?.name || '');
    }
    
    async confirm()
    {
        if(this.player.isPlayer)
        {
            await this.userApiClient.removeUserFromCompetition(
                this.player.player.userId, 
                this.player.player.competitionId
            );
        }
        else
        {
            await this.invitationApiClient.delete(this.player.invitation.id);
        }
        
        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.$emit('input', false);
    }
}
</script>

<style scoped>
.theme--dark .v-data-table
{
    background-color: rgba(42,42,42, 0.5);
}
</style>
<style>
.v-data-table td
{
    color: var(--v-text-darken1) !important;
}
</style>
