<template>
    <div>
        <delete-confirmation-dialog v-model="showConfirmationDialog" :checkbox="true" :confirm="$t('CHALLENGES_DELETE_DIALOG_CONFIRM')" @confirm="confirm" @cancel="cancel" :loading="isLoading" id="deleteChallengesDialog">
            <span class="deleteChallengesDialogBodyMessage">{{ $t('CHALLENGES_DELETE_CHALLENGES_DIALOG_MESSAGE', {count}) }}</span>
        </delete-confirmation-dialog>
        <job-progress-dialog :jobId="jobId" v-model="showJobProgressDialog" @confirm="onJobDone" :dialogTitle="$t('CHALLENGES_DELETE_JOB_PROGESS_DIALOG_TITLE')"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IJobApiClient } from '@cyber-range/cyber-range-api-job-client';
import { Prop, Watch } from 'vue-property-decorator';
import { IChallenge, IChallengeApiClient } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { useApiClientStore } from '@stores/apiClientStore';

@Component({})
export default class DeleteChallengesDialog extends Vue 
{
    @Prop(Boolean) value: boolean;
    @Prop(Array) challenges:IChallenge[];

    showConfirmationDialog:boolean = false;
    showJobProgressDialog:boolean = false;
    jobId:string = null;

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get bulkChallengeApiClient(): IChallengeApiClient
    {
        return useApiClientStore().bulkChallengeApiClient;
    }
    get jobApiClient(): IJobApiClient
    {
        return useApiClientStore().jobApiClient;
    }
    // END TODO

    @Watch('value')
    onValueChanged()
    {
        this.showConfirmationDialog = this.value;
    }

    mounted() 
    {
        this.showConfirmationDialog = this.value;
    }

    get count(): number
    {
        return this.challenges?.length || 0;
    }
    
    async confirm()
    {
        await Promise.all(this.challenges.map(challenge => this.bulkChallengeApiClient.delete(challenge.id)));
        this.jobId = await this.jobApiClient.submit();
        this.showConfirmationDialog = false;
        this.showJobProgressDialog = true;
    }

    onJobDone()
    {
        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.showJobProgressDialog = false;
        this.$emit('input', false);
    }
}
</script>
