<script lang="ts">
import BaseChallenge from './Challenge.vue';
import { mixins } from 'vue-class-component';

export default class Landing extends mixins(BaseChallenge) 
{   
    
}
</script>

<style scoped>
.comicbook .v-card
{
    font-family: 'ComicNeue-Bold';
    letter-spacing: normal;
    border-radius: 0%;
}
.comicbook .v-card:not(:focus)
{
    outline: 2px solid black;
}
.comicbook .v-card__text
{
    text-align: center !important;
}
</style>
