<template>
    <v-dialog v-model="selectedValue" max-width="50%" persistent :dark='isDark' :light="isLight">
        <v-card class="confirmationDialog" :dark='isDark' role="dialog" :aria-labelledby="dialogTitleId" aria-modal="true">
            <v-card-title class="headline">
                <span v-if="titleIcon" class="titleIcon">
                    <slot name="titleIcon"></slot>
                </span>
                <span :id="dialogTitleId">
                    {{ title || $t('TITLE')}}
                </span>
                <v-spacer/>
                <slot name="titleActions"></slot>
            </v-card-title>
            <v-card-text :class="dense ? 'pa-0' : 'body'">
                <slot></slot>
            </v-card-text>
            <v-card-actions class="pl-6 pr-6 pb-4 pt-4">
                <slot name="bottomLeft"></slot>
                <v-spacer></v-spacer>
                <slot name="bottom"></slot>
                <v-btn class="cancelButton"
                    v-if="cancel!==false"
                    text
                    :dark="isDark"
                    :color="cancelColor || 'cancel'"
                    @click.native="show = false"
                    @click="onCancelClicked"
                    :disabled="disabled || loading || cancelDisabled"
                >{{ cancel || $t('CANCEL') }}</v-btn>
                <v-btn class="confirmButton elevation-0"
                    v-if="confirm!==false"
                    :color="confirmColor || 'confirm'"
                    :dark="!isConfirmLight" :light="isConfirmLight"
                    @click.native="show = false"
                    @click="onConfirmClicked"
                    :disabled="disabled || loading || confirmDisabled"
                >{{ confirm || $t('CONFIRM') }}</v-btn>
                <slot name="bottomRight"></slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { v4 as uuid } from 'uuid';
import { useThemeStore } from "@/stores/themeStore";

@Component({})
export default class ConfirmationDialog extends Vue {
    // Until rewriting correctly, using Prop() is equivalient to any type.
    // For some reason we use cancel and confirm with many different types.
    @Prop() cancel;
    @Prop() confirm;
    @Prop(String) title: string;
    @Prop(Boolean) disabled: boolean;
    @Prop(Boolean) value: boolean;
    @Prop(Boolean) loading: boolean;
    @Prop(Boolean) cancelDisabled: boolean;
    @Prop(Boolean) confirmDisabled: boolean;
    @Prop(String) confirmColor: string;
    @Prop(String) cancelColor: string;
    @Prop(Boolean) dense:boolean;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get isConfirmLight():boolean
    {
        return useThemeStore().isConfirmLight;
    }
    // END TODO

    isOpen: boolean = true;
    selectedValue: boolean = false;
    dialogTitleId = uuid();

    get titleIcon() {
        return this.$slots["titleIcon"];
    }

    @Watch('value')
    onValueChanged()
    {
        this.selectedValue = this.value;
    }
    
    onCancelClicked()
    {
        if(this.cancel!==false && !this.disabled && !this.loading && !this.cancelDisabled)
        {
            this.$emit('cancel', true); 
            this.$emit('input', false);
        }
    }

    onConfirmClicked()
    {
        if(this.confirm!==false && !this.disabled && !this.loading && !this.confirmDisabled)
        {
            this.$emit('confirm', true);
        }
    }

    mounted() 
    {
        this.selectedValue = this.value;
    }
}
</script>

<style>
.titleIcon {
    display: inline-block;
    padding-right: 10px;
}
.confirmationDialog .body{
    padding-left: 2px;
    padding-bottom: 0px !important;
    overflow-y: auto;
    max-height: 60%;
    min-height: 40px;
}
</style>