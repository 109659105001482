<script lang="ts">
import BaseHeader from './Header.default.vue';
import { mixins } from 'vue-class-component';

export default class Header extends mixins(BaseHeader) {}
</script>

<style scoped>
@import './header.css';
.header-item {
    font-family: ShareTechMono;
}
</style>
