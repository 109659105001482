<template>
    <fieldset>
        <v-checkbox
            v-for="choice in challenge.choice.values"
            :key="choice.id"
            class="py-0 my-0"
            :value="choice.id"
            :label="choice.text"
            multiple
            color="blue"
            v-model="flags"
            :disabled="disabled"
            hide-details
            :rules="rules"
        />
        <v-input dense :value="flags" :rules="rules" />
    </fieldset>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import Rule from '@/validations/Rule';

@Component
export default class MultipleSelectChallengeFlagInput extends Vue 
{
    @Prop([Array,String]) value: string|string[];
    @Prop(Object) challenge: IChallenge
    @Prop(Boolean) disabled: boolean;

    rules = [Rule.require]

    get flags(): string[]
    {
        const value = this.value || []
        return (typeof value === 'string') ? [value] : value;
    }
    set flags(flag: string[])
    {
        this.$emit('input', flag);
    }
}
</script>

<style scoped>
fieldset {
    border: none;
}
</style>
