<template>
    <confirmation-dialog v-model="showDialog" :title="dialogTitle" :dense="true" :confirm="confirmButtonText" @confirm="confirm" @cancel="cancel" :loading="isLoadingOrUploading" class="addEditChallengeDialog">
        <loading v-if="dataFetching" class="mt-5 ml-5 mb-5 loading" color="dialogText" />
        <v-form v-else ref="form" :lazy-validation="true">
            <tabbed-pane :tabs="tabs" :errorTabIndexes="errorTabIndexes">
                <template #0>
                    <v-text-field v-model="challenge.name" :label="$t('CHALLENGE_ADD_EDIT_DIALOG_NAME')" :rules="nameRules" :counter="maxNameLength" :disabled="isLoadingOrUploading" @update:error="(e)=>onError(e, 0)" class="challengeAddEditDialogName" id="challengeAddEditDialogName" />
                    <v-textarea v-model="challenge.description" :label="$t('CHALLENGE_ADD_EDIT_DIALOG_DESCRIPTION')" rows="3" :rules="descriptionRules" :counter="maxDescriptionLength" :disabled="isLoadingOrUploading" @update:error="(e)=>onError(e, 0)" class="challengeAddEditDialogDescription" id="challengeAddEditDialogDescription" />
                    <v-text-field v-model="challenge.points" :label="$t('CHALLENGE_ADD_EDIT_DIALOG_POINTS')" :rules="pointsRules" :disabled="isLoadingOrUploading" @update:error="(e)=>onError(e, 0)" class="challengeAddEditDialogPoints" id="challengeAddEditDialogPoints" />
                    <flags :flags.sync="challenge.flags" :choice.sync="challenge.choice" class="challengeAddEditDialogFlags" :rules="flagRules" @update:error="(e)=>onError(e, 0)" />
                </template>
                <template #1>
                    <v-subheader class="pl-0 mt-3">{{$t('CHALLENGE_ADD_EDIT_DIALOG_ARTIFACTS')}}</v-subheader>
                    <artifacts :artifacts="challenge.artifacts" :editable="true" class="challengeAddEditDialogArtifacts" :disabled="isLoadingOrUploading"/>
                    <v-subheader class="pl-0 mt-6">{{$t('CHALLENGE_ADD_EDIT_DIALOG_HINTS')}}</v-subheader>
                    <hints :challenge="challenge" :editable="true" class="challengeAddEditDialogHints" :disabled="isLoadingOrUploading"/>
                </template>
                <template #2>
                    <v-subheader class="pl-0 mt-3">{{$t('CHALLENGE_ADD_EDIT_DIALOG_SETTINGS')}}</v-subheader>
                    <v-checkbox v-model="challenge.enabled" :label='$t("CHALLENGE_ADD_EDIT_DIALOG_ENABLED")' :dark="isDark" color="unset" :light="isLight" :disabled="isLoadingOrUploading" class="challengeAddEditDialogEnabled" id="challengeAddEditDialogEnabled" />
                    <sorted-combobox v-model="challenge.category" :items="allKnownCategories" item-text="name" item-value="name" @update:search-input="onCategoryChanged" @keydown.enter.stop :label="$t('CHALLENGE_ADD_EDIT_DIALOG_CATEGORY')" :disabled="isLoadingOrUploading" :hide-no-data="true" :return-object="false" :dark="isDark" class="challengeAddEditDialogCategory" />
                    <challenge-settings :settings="challenge.settings" :choice="!!challenge.choice" @error="e => onError(e, 2)" class="challengeAddEditDialogSettings" />
                </template>
                <template #3>
                    <v-subheader class="pl-0 mt-3">{{$t('CHALLENGE_ADD_EDIT_DIALOG_TAGS')}}</v-subheader>
                    <tags :tags="challenge.tags" class="challengeAddEditDialogTags" />
                </template>
                <template #4>
                    <v-textarea v-model="challenge.notes" :label="$t('CHALLENGE_ADD_EDIT_DIALOG_NOTES')" :aria-label="$t('CHALLENGE_ADD_EDIT_DIALOG_NOTES')" rows="5" :rules="notesRules" :counter="maxNotesLength" :disabled="isLoadingOrUploading" class="challengeAddEditDialogNotes"/>
                    <v-textarea v-model="challenge.solution" :label="$t('CHALLENGE_ADD_EDIT_DIALOG_SOLUTION')"
                    :aria-label="$t('CHALLENGE_ADD_EDIT_DIALOG_SOLUTION')" rows="5" :rules="solutionRules" :counter="maxSolutionLength" :disabled="isLoadingOrUploading" class="challengeAddEditDialogSolution"/> 
                </template>
                <template #5>
                    <v-subheader class="pl-0 mt-3">{{$t('CHALLENGE_ADD_EDIT_DIALOG_PROTECTED_INFORMATION_NOTICE')}}</v-subheader>
                    <v-text-field v-model="challenge.protectedInformation.authorName" :aria-label="$t('CHALLENGE_ADD_EDIT_DIALOG_AUTHOR_NAME')" :label="$t('CHALLENGE_ADD_EDIT_DIALOG_AUTHOR_NAME')" :rules="authorNameRules" :counter="authorNameLength" :disabled="isLoadingOrUploading" class="challengeAddEditDialogAuthorName mt-4"/>
                    <v-text-field v-model="challenge.protectedInformation.authorEmail" :aria-label="$t('CHALLENGE_ADD_EDIT_DIALOG_AUTHOR_EMAIL')" :label="$t('CHALLENGE_ADD_EDIT_DIALOG_AUTHOR_EMAIL')" :rules="authorEmailRules" :counter="authorEmailLength" :disabled="isLoadingOrUploading" class="challengeAddEditDialogAuthorEmail mt-4 mb-4"/>
                    <v-text-field ref="picker-activator" @click="openDateDialog" @keyup.space="openDateDialog" @click:clear="challenge.protectedInformation.creationDate = ''" clearable readonly :value="challenge.protectedInformation.creationDate" :label="$t('CHALLENGE_ADD_EDIT_DIALOG_CREATION_DATE')" :aria-label="$t('CHALLENGE_ADD_EDIT_DIALOG_CREATION_DATE')" aria-haspopup="true" :dark="isDark" :disabled="isLoading" class="challengeAddEditDialogCreationDate"/>
                    <v-dialog v-model="showDateDialog" max-width="290">
                        <v-date-picker ref="picker" tabindex="0" v-model="challenge.protectedInformation.creationDate" @change="closeDateDialog"/>
                    </v-dialog>
                </template>
            </tabbed-pane>
        </v-form>
            
       <template #bottomLeft>
            <v-btn v-if="isEditing && canDeleteChallenge(competition.id, competition.organizationId)" @click="onDeleteChallenge" :disabled="isLoadingOrUploading" :light="isLight" text class='deleteButton'>
                {{$t('CHALLENGE_ADD_EDIT_DIALOG_DELETE')}}
            </v-btn>
       </template>
        <delete-challenge-dialog v-model="showDeleteChallengeDialog" :challenge="challenge" @confirm="onChallengeDeleted" />
        <save-challenge-to-existing-library-dialog v-model="showSaveToExistingLibraryDialog" @confirm="onSaveToExistingLibraryConfirmed" @cancel="onSaveToExistingLibraryCanceled"/>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import StoreGetter from '@/interfaces/storeGetter';
import { Prop, Watch } from 'vue-property-decorator';
import Rule from '@/validations/Rule';
import { IChallenge, Challenge, IChallengeApiClient, ChallengeSettings, Hint, ChallengeArtifact, Flag, IChallengeArtifact, ChallengeChoice } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { ILibraryApiClient, LibraryVisibility, ProtectedInformation } from '@cyber-range/cyber-range-api-ctf-library-client';
import { File } from '@cyber-range/cyber-range-api-file-client';
import { decode, encode} from 'html-entities';
import Config from '@/config';
import { ApiRequestConfig } from '@cyber-range/cyber-range-api-client';
import { apiCalledHandler, apiCallingHandler, customApiErrorHandler } from '@stores/apiClientStore';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore, doNothing } from '@stores/apiClientStore';
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';

@Component
export default class AddEditChallengeDialog extends Vue 
{ 
    @Prop(Boolean) value:boolean;
    @Prop(String) challengeId:string;
    @Prop(String) libraryEntryId:string;

    @Getter(StoreGetter.GetChallenges) challenges: ()=>IChallenge[];

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get challengeApiClient(): IChallengeApiClient
    {
        return useApiClientStore().challengeApiClient;
    }
    get libraryApiClient(): ILibraryApiClient
    {
        return useApiClientStore().libraryApiClient;
    }
    get myUserId(): string
    {
        return useAuthenticationStore().identityId;
    }
    get myName(): string
    {
        return useAuthenticationStore().identityName;
    }
    get myEmail(): string
    {
        return useAuthenticationStore().identityEmail;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    canDeleteChallenge(competionId:string, organizationId:string): boolean
    {
        return useAuthorizationStore().canDeleteChallenge(competionId, organizationId);
    }
    // END TODO
    
    showDeleteChallengeDialog:boolean = false;
    showSaveToExistingLibraryDialog:boolean = false;

    isUploading: boolean = false;
    dataFetching:boolean = false;
    showDialog:boolean = false;
    showDateDialog = false;
    challenge:IChallenge = new Challenge({});
    errorTabIndexes:number[] = [];
    allKnownCategories:string[] = [];
    saveToExistingLibrary:boolean = false;

    maxNameLength = 256;
    maxDescriptionLength = 4096;
    maxPoints = 100000;
    minPoints = 0;
    percentCompleted = 0;
    maxNotesLength = 10000;
    maxSolutionLength = 4096;
    authorNameLength = 256;
    authorEmailLength = 320;

    nameRules = [Rule.require, v => Rule.maxLength(v, this.maxNameLength)];
    descriptionRules = [Rule.require, v => Rule.maxLength(v, this.maxDescriptionLength)];
    pointsRules = [Rule.require, v => Rule.maxValue(v, this.maxPoints), v => Rule.minValue(v, this.minPoints)];
    flagRules = [Rule.require];
    notesRules = [v => Rule.maxLength(v, this.maxNotesLength)];
    solutionRules = [v => Rule.maxLength(v, this.maxSolutionLength)];
    authorNameRules = [v => Rule.maxLength(v, this.authorNameLength)];
    authorEmailRules = [v => Rule.maxLength(v, this.authorEmailLength)];

    get isLoadingOrUploading()
    {
        return this.isLoading || this.isUploading;
    }

    get tabs()
    {
        const tabs = [
            {icon: 'flag', text: this.$t('CHALLENGE_ADD_EDIT_DIALOG_TAB_CHALLENGE_INFORMATION')},
            {icon: 'attachment', text: this.$t('CHALLENGE_ADD_EDIT_DIALOG_TAB_ARTIFACTS_AND_HINTS')},
            {icon: 'settings', text: this.$t('CHALLENGE_ADD_EDIT_DIALOG_TAB_ADVANCED_SETTINGS')},
            {icon: 'local_offer', text: this.$t('CHALLENGE_ADD_EDIT_DIALOG_TAB_TAGS')},
            {icon: 'feed', text: this.$t('CHALLENGE_ADD_EDIT_DIALOG_TAB_NOTES_AND_SOLUTIONS')},
        ];
        if (useAuthorizationStore().canUpdateChallengeProtectedInformation()) {
            tabs.push({icon: 'privacy_tip', text: this.$t('CHALLENGE_ADD_EDIT_DIALOG_TAB_PROTECTED_INFORMATION')})
        }
        return tabs;
    }
    
    get isEditing(): boolean
    {
        return !!this.challengeId;
    }

    get dialogTitle()
    {
        return this.isEditing ? this.$t('CHALLENGE_ADD_EDIT_DIALOG_EDIT_TITLE') : this.$t('CHALLENGE_ADD_EDIT_DIALOG_ADD_TITLE');
    }

    get confirmButtonText()
    {
        return this.percentCompleted ? this.$t('CHALLENGE_ADD_EDIT_DIALOG_UPLOAD_PROGRESS', {percent: this.percentCompleted}) : this.$t('CONFIRM');
    }

    async canSaveChallengeToExistingLibrary()
    {
        try
        {
            // Any error from this route indicates the user cannot save to this library entry
            const library = await this.libraryApiClient.getOne(this.challenge.libraryId, {errorHandler: doNothing});

            switch (library?.visibility)
            {
                case LibraryVisibility.Global:
                    return useAuthorizationStore().canSaveChallengeWithGlobalVisibility();
                case LibraryVisibility.Organizational:
                    return useAuthorizationStore().canSaveChallengeWithOrganizationalVisibility(library.organizationId);
                case LibraryVisibility.Personal:
                    return this.myUserId === library.userId;
            }
        } 
        catch (e)
        {
            return false;
        }
    };

    openDateDialog() {
        this.showDateDialog = true;
    }

    closeDateDialog() {
        this.showDateDialog = false;
    }

    onError(error:boolean, index:number): void
    {
        if(error)
        {
            this.errorTabIndexes = [...new Set([...this.errorTabIndexes, index])];
        }
        else
        {
            this.errorTabIndexes = this.errorTabIndexes.filter(i => i !== index);
        }
    }

    onDeleteChallenge()
    {
        this.showDeleteChallengeDialog = true;
    }

    async onChallengeDeleted(): Promise<void>
    {
        this.$emit('deleted', true);
        this.close();
    }

    async onSaveToExistingLibraryConfirmed(): Promise<void>
    {
        this.saveToExistingLibrary = true;
        this.submitForm();
    }

    async onSaveToExistingLibraryCanceled(): Promise<void>
    {
        this.saveToExistingLibrary = false;
        this.submitForm();
    }

    onCategoryChanged(value)
    {
        this.challenge.category = value;
    }

    @Watch('value')
    async onValueChanged(value:boolean)
    {
        this.showDialog = value;
        if(this.showDialog) await this.load();
    }

    async mounted() 
    {
        this.showDialog = this.value;
        if(this.showDialog) await this.load();
    }

    async load() 
    {
        this.challenge = new Challenge({
            flags: [], tags: [], hints: [], artifacts: [],
            protectedInformation: new ProtectedInformation({}),
            settings: new ChallengeSettings()
        });

        await this.$nextTick(); 
        this.errorTabIndexes = [];
        (<any>this.$refs.form)?.reset();

        this.challenge.protectedInformation = new ProtectedInformation({
            authorName : this.myName,
            authorEmail : this.myEmail,
            creationDate: new Date().toISOString().substring(0,10)
        });
        this.challenge.enabled = true;
        this.challenge.category = Config.UNCATEGORIZED;
        this.challenge.settings = new ChallengeSettings({ 
            maxNumberOfAttempts: Config.DEFAULT_MAX_NUMBER_OF_ATTEMPTS
        });

        if(this.challengeId)
        {
            try
            {
                this.dataFetching = true;

                this.challenge = await this.challengeApiClient.getOne(this.challengeId);
                this.challenge.protectedInformation ||= new ProtectedInformation();
                this.challenge.description = decode(this.challenge?.description);
                this.challenge.settings ??= new ChallengeSettings();
                this.challenge.settings.maxNumberOfAttempts ||= Config.DEFAULT_MAX_NUMBER_OF_ATTEMPTS;
            }
            catch
            {
                this.close();
            }
            finally
            {
                this.dataFetching = false;
            }
        }
        else if(this.libraryEntryId)
        {
            try
            {
                this.dataFetching = true;

                let entry = this.libraryEntry = await this.libraryApiClient.getOne(this.libraryEntryId);
                this.challenge.name = entry.name;
                this.challenge.description = decode(entry.description);
                this.challenge.hints = entry.hints.map(h => new Hint(h));
                this.challenge.artifacts = entry.artifacts.map(a => new ChallengeArtifact(<object>a));
                this.challenge.category = entry.category;
                this.challenge.choice = entry.choice ? new ChallengeChoice(entry.choice) : undefined;
                this.challenge.flags = entry.flags.map(flag => new Flag(flag));
                this.challenge.settings = new ChallengeSettings(entry.settings);
                this.challenge.settings.maxNumberOfAttempts ||= Config.DEFAULT_MAX_NUMBER_OF_ATTEMPTS;
                this.challenge.points = entry.points;
                this.challenge.tags = entry.tags;
                this.challenge.libraryId = entry.id;
                this.challenge.notes = entry.notes;
                this.challenge.solution = entry.solution;
                this.challenge.protectedInformation = entry.protectedInformation || new ProtectedInformation();
            }
            catch
            {
                this.close();
            }
            finally
            {
                this.dataFetching = false;
            }
        }

        this.allKnownCategories = this.challenges().map(c => c.category).reduce((allCategories, category)=>allCategories.concat(category), []);
    }

    async confirm()
    {
        if (this.isEditing && this.challenge.libraryId && await this.canSaveChallengeToExistingLibrary())
        {
            this.showSaveToExistingLibraryDialog = true;
        }
        else
        {
            this.submitForm();
        }
    }

    async submitForm()
    {
        if((<any>this.$refs.form).validate() === false) return;

        //Extract new file artifacts
        let newFileArtifacts = this.challenge.artifacts.filter(a => !!a['file']);

        let challengeId = this.challengeId;

        let desiredChallenge = new Challenge(
        {
            name: this.challenge.name,
            description: encode(this.challenge.description),
            category: this.challenge.category || Config.UNCATEGORIZED,
            points: Number(this.challenge.points),
            artifacts: this.challenge.artifacts.filter(a => !a['file']),
            hints: this.challenge.hints.map(h => { h.id = h.id?.startsWith(Config.TEMP_HINT_ID_PREFIX) ? undefined : h.id; return h }),
            choice: this.challenge.choice,
            flags: this.challenge.flags,
            settings: this.challenge.settings,
            tags: this.challenge.tags,
            enabled: this.challenge.enabled,
            notes: this.challenge.notes,
            solution: this.challenge.solution
        });

        if (useAuthorizationStore().canUpdateChallengeProtectedInformation()) {
            desiredChallenge.protectedInformation = this.challenge.protectedInformation;
        }

        if(this.isEditing)
        {
            await this.challengeApiClient.update(this.challengeId, desiredChallenge);
            if (this.saveToExistingLibrary)
            {
                await this.challengeApiClient.saveToExistingLibraryEntry(this.challengeId, this.challenge.libraryId)
            }
        }
        else
        {
            desiredChallenge.competitionId = this.competition.id;
            desiredChallenge.libraryId = this.challenge.libraryId;

            desiredChallenge.id = <any> await this.challengeApiClient.add(desiredChallenge);
        }

        //Upload new artifact files
        try
        {
            this.isUploading = true;
            await this.uploadArtifactFiles(this.challengeId || desiredChallenge.id, newFileArtifacts);
        }
        finally
        {
            this.percentCompleted = 0;
            this.isUploading = false;
        }

        this.$emit('confirm', desiredChallenge);
        this.close();
    }

    async uploadArtifactFiles(challengeId:string, artifacts:IChallengeArtifact[]): Promise<void>
    {
        //Upload the smallest files first
        artifacts = artifacts.sort((a,b) => a['file'].size < b['file'].size ? -1 : 1);

        //Get the total upload size
        let totalSize = artifacts.map(artifact => artifact['file'].size).reduce((a,b) => a + b, 0);

        let uploadedSize = 0;

        //Prepare api request configurations
        let config = new ApiRequestConfig({},
                undefined,
                customApiErrorHandler,
                apiCallingHandler,
                apiCalledHandler
        );
        config['onUploadProgress'] =  (progressEvent) =>
        {
            this.percentCompleted = Math.min(Math.round(((uploadedSize + progressEvent.loaded) * 100) / totalSize), 99);
        }

        //Upload each artifact file
        for(let artifact of artifacts)
        {
            let file = new File({
                name: artifact.name,
                type: artifact['file'].type
            });

            try
            {
                await this.challengeApiClient.upload(challengeId, file, artifact['blob'], artifact['file'].name, config);
                uploadedSize += artifact['file'].size; 
            }
            finally
            {
                //Work around a bug in ApiClient that a per-request config overrides a global config
                config.headers['Content-Type'] = "application/json";
            }
        }
    }

    async cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showDialog = false;
        this.$emit('input', false);
    }
}
</script>
<style>
.v-subheader
{
    height: unset;
}
</style>