<template>
    <v-rating :value="value" half-increments readonly tabindex="0" :aria-label="$t(ariaLabelString, {percentage, entryName})" class="correctFlagStars">
        <template v-slot:item="props">
            <v-icon :color="correspondingColor(props)">
                {{correspondingStarIcon(props)}}
            </v-icon>
        </template>
    </v-rating>
</template>

<script lang="ts">
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Stars extends Vue {
    @Prop(Number) value: number;
    @Prop(String) entryName: string;
    @Prop(String) ariaLabelString: string;
    
    get percentage(): string {
        return (this.value * 20).toLocaleString(undefined, {maximumFractionDigits: 2});
    }

    correspondingStarIcon(props) {
        if (props.isFilled) {
            return 'mdi-star';
        }
        else if (props.isHalfFilled) {
            return 'mdi-star-half'
        }
        return 'mdi-star-outline'
    }

    correspondingColor(props) {
        return props.isFilled || props.isHalfFilled ? 'primary' : 'accent';
    }
}
</script>