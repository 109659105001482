export default {
    en: {
        HEADER_HOME_LOGO_LINK_LABEL: 'Cloud CTF Home',
        HEADER_CHALLENGES: 'Challenges',
        HEADER_TEAM: 'My Team',
        HEADER_MANAGE: 'Admins',
        HEADER_SCOREBOARD: 'Scoreboard',
        HEADER_LOGOUT: 'Logout',
        HEADER_ACCOUNT_MENU: 'Account Menu',
        HEADER_EDIT_PROFILE: 'Edit Profile',
        HEADERS_PLAYER_PROFILE_TITLE: 'Play as',
        HEADER_JOINING_REQUEST_ALERT_DIALOG_TITLE: 'Team Joining Request',
        HEADER_JOINING_REQUEST_ALERT_DIALOG_CONFIRM: 'Approve',
        HEADER_JOINING_REQUEST_ALERT_DIALOG_CANCEL: 'Cancel',
        HEADER_JOINING_REQUEST_ALERT_DIALOG_REJECT: 'Reject'
    }
};
