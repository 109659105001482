<script lang="ts">
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import BaseScoreChart from './scoreChart.vue';

export default class ScoreChart extends mixins(BaseScoreChart) 
{
    gridlineColor = "white";
    textColor = "white";
}
</script>

<style>
.scoreChartDark
{
  background-color: rgba(0, 0, 0, 0.7);
  letter-spacing: normal;
}
</style>