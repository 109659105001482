import ITheme from "@/interfaces/iTheme";
import backgroundImage from "@assets/themes/default/backgroundImage.svg";

export class DefaultTheme implements ITheme
{
    static Id = 'default';
    id = DefaultTheme.Id;

    name = 'Default';

    colors = {
                application: '#553A97', //#051c29
                text: '#E0E0E0',
                confirm: '#553A97',
                cancel: '#000000',
                secondary: '#62B4E4',
                primary: '#FFFFFF',
                falseswitch: '#808080',
                solved: '#455A64',
                unsolved: '#2E9CDC',
                noMoreAttempts: '#521414',
                disabled: '#FF4B1F',
                tag: '#62B4E4',
                activeTab: '#62B4E4',
                dialogText: '#303030',
                dialogSuccessText: '#4CAF50',
                dialogFailureText: '#F44336',
                dialogIcon: '#553A97',
                success: '#4CAF50',
                warning: '#FF9800',
                link: '#62B4E4'
             };
        
    fontFamily = "vcr_osd_mono";
    backgroundImage = backgroundImage;
    isDark = true;
    isDialogDark = false;
}