<template>
    <confirmation-dialog v-model="showConfirmationDialog" :title="$t('CHALLENGE_EXPORT_TITLE')" :confirm="$t('CHALLENGE_EXPORT_CONFIRM_BUTTON')"  :cancel="false" @confirm="confirm" :loading="isLoading" id="challengeExportDialog">
        <loading v-if="isLoading" :message="$t('CHALLENGE_EXPORT_CREATING_BUNDLE')" class="exportChallengesLoadingComponent"/>
        <div v-else>
            <span v-html="$t('CHALLENGE_EXPORT_AUTOMATIC_DOWNLOAD')" class="challengeExportAutomaticDownloadMessage" />
            <a @click.prevent="downloadExportData" target="_blank" class="exportChallengeFileDownloadAnchor">{{$t('CHALLENGE_EXPORT_MANUAL_DOWNLOAD')}}</a>
        </div>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IChallengeApiClient, IChallenge, ExportFormat } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { Prop, Watch } from 'vue-property-decorator';
import Config from "@/config";
import { ApiClientError } from '@cyber-range/cyber-range-api-client';
import { useApiClientStore } from '@stores/apiClientStore';
import { useErrorStore } from '@stores/errorStore';

@Component({components:{}})
export default class ExportChallengesDialog extends Vue 
{      
    @Prop(Boolean) value: boolean;
    @Prop(Array) challenges:IChallenge[];

    exportData:string|Buffer = "";

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get challengeApiClient(): IChallengeApiClient
    {
        return useApiClientStore().challengeApiClient;
    }
    // END TODO
    
    @Watch('value')
    async onValueChanged()
    {

        if (this.value && this.tooManyChallenges)
        {
            useErrorStore().setError(new ApiClientError(this.$t('CHALLENGE_EXPORT_TOO_MANY_SELECTED_TEXT', {limit: this.maxChallenges})));
            this.close();
            return;
        }

        if(this.value)
        {
            this.exportData = await this.challengeApiClient.export(this.challenges.map(entry=>entry.id), ExportFormat.Compressed);
            this.downloadExportData();
        }else
        {
            this.exportData = "";
        }
    }

    get showConfirmationDialog():boolean
    {
        return this.value;
    }

    get tooManyChallenges(): boolean
    {
        return this.challenges.length > this.maxChallenges;
    }

    get maxChallenges(): number
    {
        return Config.MAX_EXPORT_IMPORT_CHALLENGE_COUNT;
    }

    downloadExportData()
    {
        let anchor = document.createElement('a');
        anchor.href = window.URL.createObjectURL(new Blob([this.exportData]));
        anchor.download = this.$t('CHALLENGE_EXPORT_DEFAULT_FILE_NAME',{date:new Date().toISOString().substr(0,10)}).toString();
        anchor.click();
    } 
    
    async confirm()
    {
        this.$emit('confirm', true);
        this.close();
    }

    close()
    {
        this.$emit('input', false);
    }
}
</script>