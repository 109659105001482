export default {
    en: {
        CYBER_RANGE_CONNECTION_MESSAGE: "Please wait while your environment is {status}...",
        CYBER_RANGE_CONNECTION_HAD_ERROR_MESSAGE: 'This operation is taking longer than usual. Please continue to wait while your environment is {status}...',
        CYBER_RANGE_CONNECTION_FAILED_MESSAGE: 'Failed to launch this environment. Please close this window and try again later.',
        CYBER_RANGE_CLOCK_OUT_OF_SYNC_MESSAGE: 'Your clock may be out of sync.',
        CYBER_RANGE_ENVIRONMENT_TIME_REMAINING: 'Environment Time Remaining',
        CYBER_RANGE_ENVIRONMENT_ENDED_MESSAGE: 'Your session has ended.',
        CYBER_RANGE_ENVIRONMENT_EARLY_TERMINATION_BUTTON: 'Terminate',
        CYBER_RANGE_ENVIRONMENT_EARLY_TERMINATION_DIALOG_TITLE: 'Are you sure you want to terminate this exercise environment?',
        CYBER_RANGE_ENVIRONMENT_EARLY_TERMINATION_DIALOG_UNDERSTANDING_LABEL: 'I understand that a deleted environment cannot be restored.',
        CYBER_RANGE_ENVIRONMENT_EARLY_TERMINATION_DIALOG_CONFIRM: 'Terminate',
        CYBER_RANGE_ENVIRONMENT_EARLY_TERMINATION_DIALOG_CONFIRMATION_TEXT: 'Upon termination, this environment will be permanently deleted. A new and fresh environment will be provided to you upon your re-entry. If you do not take action, this environment will self-terminate when the time limit is reached.',
    }
};
