import {Subject, interval, Subscription} from 'rxjs';
import { throttle } from 'rxjs/operators';
import { defineStore } from 'pinia';

const EVENT_THROTTLING_IN_MS = 60000;

export const useNotificationStore = defineStore('notificationStore', 
{
    state: () =>
    ({
        subscriptions: {},
        timers: {} //This state can be removed once Notification API is ready.
    }),
    getters:
    {
    },
    actions:
    {
        isSubscribed(event:string)
        {
            return this.subscriptions[event] !== undefined && !this.subscriptions[event].closed;
        },
        getSubscription(event:string)
        {
            return this.subscriptions[event];
        },
        getSubscriptions()
        {
            return this.subscriptions;
        },
        subscribe(payload:{event:string,callback:(value:unknown)=>void}): Subscription
        {
            let subject = new Subject();
            let subscription = subject.pipe(throttle(()=>interval(EVENT_THROTTLING_IN_MS), {leading:true, trailing: true}))
                                      .subscribe({next:payload.callback})

            if(this.subscriptions[payload.event] !== undefined)
            {
                this.subscriptions[payload.event].unsubscribe();

                //The following lines can be removed once Notification API is ready.
                clearInterval(this.timers[payload.event]);
            }

            this.subscriptions[payload.event] = subscription;

            //The following line can be removed once Notification API is ready.
            this.timers[payload.event] = setInterval(()=>subject.next(payload.event), EVENT_THROTTLING_IN_MS);

            return subscription;
        },
        unsubscribe(): void
        {
            for(let event of Object.keys(this.subscriptions))
            {
                this.subscriptions[event].unsubscribe();

                //The following lines can be removed once Notification API is ready.
                clearInterval(this.timers[event]);
            }
        }
    }
});