<template>
    <confirmation-dialog v-model="showConfirmationDialog" :confirm="$t('LIBRARY_DELETE_DIALOG_CONFIRM')" @confirm="confirm" @cancel="cancel" :loading="isLoading" id="libraryEntryDeleteDialog">
        <span v-html="$t('LIBRARY_DELETE_DIALOG_MESSAGE', {name:this.name})" />
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ILibraryApiClient, ILibraryEntry } from '@cyber-range/cyber-range-api-ctf-library-client';
import { Prop, Watch } from 'vue-property-decorator';
import { encode } from 'html-entities';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';

@Component({components:{}})
export default class DeleteLibraryEntryDialog extends Vue 
{
    @Prop(Boolean) value: boolean;
    @Prop(Object) entry:ILibraryEntry;

    showConfirmationDialog:boolean = false;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get libraryApiClient(): ILibraryApiClient
    {
        return useApiClientStore().libraryApiClient;
    }
    // END TODO

    @Watch('value')
    onValueChanged()
    {
        this.showConfirmationDialog = this.value;
    }

    mounted() 
    {
        this.showConfirmationDialog = this.value;
    }

    get name():string
    {
        return encode(this.entry?.name || '');
    }
    
    async confirm()
    {
        await this.libraryApiClient.delete(this.entry.id);
    
        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.$emit('input', false);
    }
}
</script>