<template>
    <div>
        <v-row v-if="!isTeamScoresFetched" class="scoreTableLoading">
            <v-col cols="12" align="center" class="mt-10 pt-10">
                <loading />
            </v-col>
        </v-row>

        <v-row v-else-if="teamsScores.length === 0">
            <v-col cols="12" align="center" class="mt-10 pt-10">
                <p class="scoreTableEmpty">
                    <span v-if="showNoScoreMessage">
                        {{$t('SCOREBOARD_TABLE_EMPTY')}}
                    </span>
                </p>
            </v-col>
        </v-row>

        <template v-else>
            <h2 v-if="showHeader" class="ma-2">{{ $t('SCOREBOARD_ALL_TEAMS') }}</h2>
            <v-simple-table :dark="isDark" :light="isLight" :class="classNames">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-center scoreTableRankColumn">{{$t('SCOREBOARD_TABLE_RANK')}}</th>
                        <th class="text-left scoreTableTeamColumn">{{$t('SCOREBOARD_TABLE_TEAM')}}</th>
                        <th class="text-right scoreTableScoreColumn pr-5">{{$t('SCOREBOARD_TABLE_SCORES')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="teamScore in teamsScores" :key="teamScore['rank']">
                        <td :class="`text-center scoreTableRankColumn ${getTrackedTeamScoreClass(teamScore)}`">{{ teamScore['rank'] }}</td>
                        <td :class="`text-left scoreTableTeamColumn ${getTrackedTeamScoreClass(teamScore)}`">{{ teamScore.teamName }}</td>
                        <td :class="`text-right scoreTableScoreColumn pr-5 ${getTrackedTeamScoreClass(teamScore)}`">{{ teamScore.score }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </template>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import StoreGetter from '@interfaces/storeGetter';
import { ITeamSubmissionStatistics } from '@cyber-range/cyber-range-api-ctf-submission-client';
import { Prop } from 'vue-property-decorator';
import { ITeam } from '@cyber-range/cyber-range-api-ctf-team-client';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { useScoreStore } from '@stores/scoreStore';

const TRACKED_SIZE = 3; //when tracked is enabled, only show 3 items on the table.

@Component({})
export default class ScoreTable extends Vue 
{   
    @Prop(Boolean) showNoScoreMessage:boolean;
    @Prop(String) trackId:string;
    @Prop(Boolean) showHeader:boolean;
    @Prop(Boolean) alphaBackground:boolean;

    @Getter(StoreGetter.GetTeams) teams:ITeam[];

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    isTeamScoresFetched = storeToRefs(useScoreStore()).isTeamScoresFetched;
    get rawTeamsScores():ITeamSubmissionStatistics[]
    {
        return useScoreStore().getTeamsScores;
    }
    // END TODO

    get classNames()
    {
        return {
            scoreTable: true,
            alphaBackground: this.alphaBackground
        }
    }

    getTrackedTeamScoreClass(teamScore:ITeamSubmissionStatistics):string
    {
        return teamScore.teamId === this.trackId ? 'tracked' : '';
    }

    get teamsScores():ITeamSubmissionStatistics[]
    {
        //Remove hidden teams
        let visibleTeamsIds = new Set(this.teams.filter(team => !team.hidden).map(team => team.id));
        
        let visibleTeamScores = this.rawTeamsScores.filter(teamScore => visibleTeamsIds.has(teamScore.teamId));

        //Assign Ranks
        for(let [index, teamScore] of visibleTeamScores.entries())
        {
            teamScore['rank'] = index + 1;
        }

        //Return raw team scores if trackId is not given.
        if(!this.trackId)
        {
            return visibleTeamScores;
        }

        //Return raw team scores if the number of team scores is less than what to show.
        let numberOfTeamScores = visibleTeamScores.length;

        if(numberOfTeamScores <= TRACKED_SIZE)
        {
            return visibleTeamScores;
        }

        //Return the tracked score and its neighbor entries.
        let teamScores = [];

        let trackedObjectIndex = visibleTeamScores.findIndex(t => t.teamId === this.trackId);

        //Tracked object is not in the list
        if(trackedObjectIndex === -1)
        {
            return [...visibleTeamScores].splice(0, TRACKED_SIZE)
        }
        //Tracked object is at the top of the list.
        if(trackedObjectIndex === 0)
        {
            return [...visibleTeamScores].splice(0, TRACKED_SIZE)
        }
        //Tracked object is at the bottom of the list.
        else if(trackedObjectIndex === numberOfTeamScores - 1)
        {
            return [...visibleTeamScores].splice(numberOfTeamScores - TRACKED_SIZE, TRACKED_SIZE);
        }
        //Tracked object is in the middle of the list
        else
        {
            return [...visibleTeamScores].splice(trackedObjectIndex - 1, TRACKED_SIZE);
        }
    }
}
</script>
<style scoped>
.tracked
{
    font-weight: bold;
}
</style>