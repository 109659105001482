import { ILibraryEntry } from '@cyber-range/cyber-range-api-ctf-library-client';

export default class LibraryEntryView
{
    get name():string
    {
        return this.entry.name;
    }

    get category():string
    {
        return this.entry.category;
    }

    get isEnabled():boolean
    {
        return this.entry.enabled;
    }

    get status():string
    {
        return `${this.isDefaultEntry}`;
    }

    get isDefaultEntry():boolean
    {
        return this.entry.settings?.isDefaultChallenge || false;
    }

    get isGlobalEntry():boolean
    {
        return this.entry.organizationId === undefined && this.entry.userId === undefined;
    }

    get isOrganizationEntry():boolean
    {
        return this.entry.organizationId !== undefined;
    }

    get isPersonalEntry():boolean
    {
        return this.entry.userId !== undefined;
    }

    get author(): string
    {
        return this.entry.protectedInformation?.authorName;
    }

    get creationDate(): string
    {
        return this.entry.protectedInformation?.creationDate;
    }

    get stars():number
    {
        return (this.entry.statistics?.successAttempts > 0 || this.entry.statistics?.failedAttempts > 0) 
                ? 
                (this.entry.statistics.successAttempts / (this.entry.statistics.successAttempts + this.entry.statistics.failedAttempts)) * 5  //5 stars
                :
                0;
    }
    
    get appearance():number
    {
        return this.entry.statistics?.appearance || 0;
    }

    constructor(public entry:ILibraryEntry)
    {
    }
};