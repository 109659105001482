import moment from 'moment';
import TimeframeStrings from '../entities/strings/definitions/timeframeStrings';

export function getTimeframe(from:string, to:string)
{
    let fromTime = from ? moment(from) : undefined;
    let toTime = to ? moment(to) : undefined;

    if(!fromTime && !toTime)
    {
        return '';
    }
    else if(!fromTime)
    {
        return `${TimeframeStrings.en.TIMEFRAME_FROM_FOREVER} ${toTime.format('MMM D, YYYY')}`; 
    }
    else if(!toTime)
    {
        return `${TimeframeStrings.en.TIMEFRAME_TO_ETERNITY} ${fromTime.format('MMM D, YYYY')}`; 
    }

    if(fromTime.year() === toTime.year())
    {
        if(fromTime.month() === toTime.month())
        {
            if(fromTime.day() === toTime.day())
            {
                return fromTime.format('MMM D, YYYY');
            }
            else
            {
                return `${fromTime.format('MMM D')}-${toTime.format('D, YYYY')}`;
            }
        }
        else
        {
            return `${fromTime.format('MMM D')} - ${toTime.format('MMM D, YYYY')}`;
        }
    }
    else
    {
        return `${fromTime.format('MMM D, YYYY')} - ${toTime.format('MMM D, YYYY')}`;
    }
}