<template>
    <confirmation-dialog v-model="showConfirmationDialog" :title="$t('TEAMS_APPROVE_DIALOG_TITLE')" :confirm="$t('TEAMS_APPROVE_DIALOG_APPROVE')" :cancel="$t('TEAMS_APPROVE_DIALOG_REJECT')" @confirm="approve" @cancel="reject" :disabled="isLoading" id="teamApproveDialog">
        <p v-html="$t('TEAMS_APPROVE_DIALOG_MESSAGE', {name:this.name})" class="pa=3" />
        <template slot="bottomLeft">
            <v-btn text :disabled="isLoading" @click="cancel" class="bottomLeftButton">{{$t('TEAMS_APPROVE_DIALOG_CANCEL')}}</v-btn>
        </template>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { IPlayer } from '@cyber-range/cyber-range-api-ctf-player-client';
import { encode } from 'html-entities';
import { useApiClientStore } from '@stores/apiClientStore';

@Component({components:{}})
export default class ApproveTeamMemberDialog extends Vue 
{
    @Prop(Boolean) value: boolean;
    @Prop(Object) player:IPlayer;

    showConfirmationDialog:boolean = false;

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    // END TODO
    

    @Watch('value')
    onValueChanged()
    {
        this.showConfirmationDialog = this.value;
    }

    mounted() 
    {
        this.showConfirmationDialog = this.value;
    }

    get name():string
    {
        return encode(this.player?.name || '');
    }
    
    async approve()
    {
        this.$emit('approved', true);
        this.close();
    }

    async reject()
    {
        this.$emit('rejected', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.$emit('input', false);
    }
}
</script>