import { DefaultTheme } from '../themes/themes/defaultTheme';
import ITheme from '../interfaces/iTheme';
import { SimpleTheme } from '../themes/themes/simpleTheme';
import { HalloweenTheme } from '../themes/themes/halloweenTheme';
import { VaCyberEduConfTheme } from '../themes/themes/vaCyberEduConfTheme';
import { LightTheme } from '../themes/themes/lightTheme';
import { DarkTheme } from '../themes/themes/darkTheme';
import { USCyberGamesSeasonII } from '../themes/themes/usCyberGamesSeasonII';
import { CircuitTheme } from '../themes/themes/circuit';
import { ComicBookTheme } from '../themes/themes/comicBookTheme'
import { CyberIconsTheme } from '../themes/themes/cyberIcons';
import { defineStore } from 'pinia';

export const useThemeStore = defineStore('themeStore', {
    state: () =>
    ({
        availableThemes: [
            new DarkTheme(),
            new DefaultTheme(),
            new HalloweenTheme(),
            new LightTheme(),
            new SimpleTheme(),
            new VaCyberEduConfTheme(),
            new USCyberGamesSeasonII(),
            new CircuitTheme(),
            new ComicBookTheme(),
            new CyberIconsTheme(),
        ],
        currentTheme: {isDark:false} as ITheme
    }),
    getters:
    {
        getCurrentTheme: (state) => state.currentTheme,

        isDark: (state) => !!state.currentTheme?.isDark,

        isLight: (state) => !state.currentTheme?.isDark,

        isDialogDark: (state) =>  !!state.currentTheme?.isDialogDark,

        isDialogLight: (state) =>  !state.currentTheme?.isDialogDark,

        isConfirmLight: (state) =>  !!state.currentTheme?.isConfirmLight,
    },
    actions:
    {
        setCurrentTheme(themeId:string): void
        {
            this.currentTheme = this.availableThemes.find(t => t.id === themeId) || this.availableThemes[0];
        },
        resetCurrentTheme(): void
        {
            this.currentTheme = this.availableThemes[0];
        }
    }
});