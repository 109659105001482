<template>
    <confirmation-dialog
        v-model="showConfirmationDialog"
        :title="currentAgreement.name? currentAgreement.name: $t('AGREEMENT_DIALOG_TITLE')"
        @confirm="acceptAgreement(currentAgreement.agreementId)"
        :confirmDisabled="!acceptedCurrentAgreement"
        :confirm="$t('AGREEMENT_DIALOG_CONFIRM_TEXT')"
        @cancel="cancel"
        :loading="isLoading"
        class="acceptAgreementsDialog"
    >
        <template>
            <v-window>
                <v-window-item>
                    <v-text-field v-html="currentAgreement.content"/>
                </v-window-item>
            </v-window>

            <template slot="bottomLeft">
                <v-checkbox class="ma-0 pa-0 acceptCheckbox" id="acceptCheckbox" hide-details mt-0 v-model="acceptedCurrentAgreement" :dark="isDialogDark" color="unset" :label="$t('ACCEPT_AGREEMENTS_DISCLAIMER_TEXT')"/>
            </template>
        </template>
    </confirmation-dialog>
</template>

<script lang="ts">
import { useThemeStore } from '@/stores/themeStore';
import { Agreement, AgreementFilter, AgreementStatus, AgreementType, IAgreement, IAgreementApiClient } from '@cyber-range/cyber-range-api-agreement-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { storeToRefs } from 'pinia';
import Vue from 'vue';
import Component from 'vue-class-component';
import { useAgreementStore } from '@stores/agreementsStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useCompetitionStore } from '@stores/competitionStore';

@Component({})
export default class AcceptAgreementsDialog extends Vue
{    
    // TODO: Change this to composition api
    get isDialogDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    hasPendingAgreements = storeToRefs(useAgreementStore()).hasPendingAgreements
    get agreementApiClient(): IAgreementApiClient
    {
        return useApiClientStore().agreementApiClient;
    }
    get backgroundAgreementApiClient(): IAgreementApiClient
    {
        return useApiClientStore().backgroundAgreementApiClient;
    }
    get identityId(): string
    {
        return useAuthenticationStore().identityId;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO

    acceptedCurrentAgreement: boolean = false;
    showConfirmationDialog: boolean = false;
    agreements: IAgreement[] = [];

    get currentAgreement(): IAgreement
    {
        return this.agreements[0]? this.agreements[0]: Agreement.fromJson({});
    }

    async acceptAgreement(id: string)
    {
        await this.agreementApiClient.accept(this.identityId, id);
        // This is intentional, if we remove an agreement before confirming the dialog
        // it makes the ui flash title change since it's based off the current agreement.
        if ( this.agreements.length > 1 )
        {
            this.agreements.splice(0,1);
            this.acceptedCurrentAgreement = false;
        }
        else
        {
            this.hasPendingAgreements = false;
            this.confirm();
        }
    }

    async mounted()
    {
        await this.load();
    }

    async load()
    {
        try
        {
            let request;
            do
            {
                request = await this.backgroundAgreementApiClient.getMyAgreements(new AgreementFilter({
                    type: AgreementType.CTF,
                    scope: this.competition.id,
                    status: AgreementStatus.Pending,
                    token: request?.nextPageToken
                }));
                this.agreements.push(...request.items);

                let index = 0;
                for ( const agreement of this.agreements )
                {
                    let tmp = await this.backgroundAgreementApiClient.getOne(agreement.agreementId);
                    this.agreements[index].content = tmp.content;
                    index++;
                }
            }
            while( request.nextPageToken )
        }
        finally
        {
            if ( this.agreements.length > 0 )
            {
                this.showConfirmationDialog = true;
            }
            else
            {
                this.hasPendingAgreements = false;
            }
        }
    }

    async close()
    {
        this.showConfirmationDialog = false;
        this.$emit('input', false);
    }

    async confirm()
    {
        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }
}
</script>