<template>
    <confirmation-dialog v-model="showConfirmationDialog" :confirm="$t('PLAYERS_REINVITE_DIALOG_CONFIRM')" @confirm="confirm" @cancel="cancel" :loading="isLoading" id="playerReinviteDialog">
        <span v-html="$t('PLAYERS_REINVITE_DIALOG_MESSAGE', {name:this.name})" />
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import PlayerView from './PlayerView';
import { Prop, Watch } from 'vue-property-decorator';
import { IInvitationApiClient } from '@cyber-range/cyber-range-api-invitation-client';
import { encode } from 'html-entities';
import { useApiClientStore } from '@stores/apiClientStore';

@Component
export default class ReinvitePlayerDialog extends Vue 
{
    @Prop(Boolean) value: boolean;
    @Prop(Object) player:PlayerView;

    showConfirmationDialog:boolean = false;
    showInvitationSentDialog:boolean = false;

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get invitationApiClient(): IInvitationApiClient
    {
        return useApiClientStore().invitationApiClient;
    }
    // END TODO
    
    @Watch('value')
    onValueChanged()
    {
        this.showConfirmationDialog = this.value;
    }

    mounted() 
    {
        this.showConfirmationDialog = this.value;
    }

    get name():string
    {
        return encode(this.player?.invitation?.name || '');
    }
    
    async confirm()
    {
        await this.invitationApiClient.resend(this.player.invitation.id);

        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.$emit('input', false);
    }
}
</script>