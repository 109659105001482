<template>
    <v-text-field
        v-model="flag"
        :label="$t('CHALLENGE_DIALOG_FLAG')"
        :rules="flagRules"
        :counter="maxFlagLength"
        :disabled="disabled"/>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, VModel } from 'vue-property-decorator';
import Rule from '@/validations/Rule';

@Component
export default class TextChallengeFlagInput extends Vue 
{
    @VModel({ type: String, required: true }) flag: string;
    @Prop(Boolean) disabled: boolean;

    maxFlagLength:number = 256;
    flagRules = [Rule.require, (v)=>Rule.maxLength(v, this.maxFlagLength)];
}
</script>
