
<script lang="ts">
import { mixins } from "vue-class-component";
import BaseConfirmationDialog from './ConfirmationDialog.vue';

export default class ConfirmationDialog extends mixins(BaseConfirmationDialog)
{
}
</script>

<style scoped>
.comicbook .confirmationDialog{
    font-family: 'ComicNeue-Bold';
    letter-spacing: normal;
}

.comicbook .confirmButton:not(:disabled){
    color: black;
    outline: 1px solid black;
}

.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
{
    background-color: rgba(0, 0, 0, 0.5)  !important;
    color: rgba(256, 256, 256, 0.3) !important;
}
.theme--dark.v-btn.v-btn--disabled
{
    color: rgba(0, 0, 0, 0.6)  !important;
}


</style>
