<script lang="ts">
import { mixins } from 'vue-class-component';
import BasePlayerHistoryDialog from './PlayerHistoryDialog.vue';

export default class PlayerHistoryDialog extends mixins(BasePlayerHistoryDialog) 
{  
}
</script>

<style>
#playerHistoryDialogList
{
    background-color: white;
    color: rgba(0, 0, 0, 0.7)  !important;
}
#playerHistoryDialogList .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
#playerHistoryDialogList .theme--dark.v-list-item .v-list-item__action-text
{
    color: inherit !important;
}
#playerHistoryDialogList .theme--dark.v-list-item .v-list-item__subtitle
{
    color: rgba(0, 0, 0, 0.4)  !important;
}
</style>