import { IContentApiClient, KnowledgeFilter, IKnowledgeFilter, IKnowledge, IKnowledgePage } from '@cyber-range/cyber-range-api-content-client';
import { useApiClientStore } from './apiClientStore';
import { defineStore } from 'pinia';

export const useKnowledgeStore = defineStore('knowledgeStore', 
{
    state: () =>
    ({
        knowledges: [] as IKnowledge[]
    }),
    getters:
    {
        getKnowledges() 
        {  
            return this.knowledges || [];
        }
    },
    actions:
    {
        async fetchKnowledges(): Promise<void> 
        {
            let client:IContentApiClient = useApiClientStore().contentApiClient;
            let page:IKnowledgePage;
            let knowledges:IKnowledge[] = [];
            let filter:IKnowledgeFilter = new KnowledgeFilter(undefined,undefined,500);

            do
            {
                page = await client.getKnowledges(filter);
                filter.token = page.nextPageToken;
                knowledges = knowledges.concat(page.knowledges);
            }
            while(filter.token)

            this.knowledges = knowledges;
        },
    }
});