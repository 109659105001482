import * as Claims from '@cyber-range/cyber-range-shared-claims';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { useEntitlementStore } from '@stores/entitlementStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { defineStore } from 'pinia';

export const useAuthorizationStore = defineStore('authorizationStore', 
{
    actions:
    {
        canUpdateCompetitionUserRole(competitionId: string, organizationId:string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_USER_COMPETITION_ROLE, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        // Invitation
        canCreateInvitation(competitionId: string, organizationId:string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.POST_V1_INVITATION, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canResendInvitation(competitionId: string, organizationId:string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PUT_V1_INVITATION_RESEND, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canUpdateInvitation(competitionId: string, organizationId:string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_INVITATION, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canDeleteInvitation(competitionId: string, organizationId:string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_INVITATION, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canInviteCtfAdmin(competitionId: string, organizationId:string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.CAN_INVITE_CTFADMIN, [competitionId, organizationId, Claims.Value.ANY], [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canInviteCtfPlayer(competitionId: string, organizationId:string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.CAN_INVITE_CTFPLAYER, [competitionId, organizationId, Claims.Value.ANY], [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        // Competition
        canUpdateCompetition(competitionId: string, organizationId:string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_COMPETITION, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canViewAllCompetitions(organizationId?: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.CAN_VIEW_ALL_COMPETITIONS, [Claims.Value.ANY,organizationId], [
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canDeleteCompetition(competitionId: string, organizationId:string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_COMPETITION, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canUpdatePlayer(competitionId: string, organizationId:string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_PLAYER, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canDeletePlayer(competitionId: string, organizationId:string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_PLAYER, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canAccessCurrentCompetition()
        {
            let thisCompetition:ICompetition = useCompetitionStore().currentCompetition;

            //If the user has this ctf membership, good.
            if(useEntitlementStore().hasClaim(Claims.Type.CTF_COMPETITION,thisCompetition.id,[thisCompetition.id,Claims.Scope.ANY,Claims.Scope.UNDEFINED]))
            {
                return true;
            }

            //If the user can view this organization's competitions, good. (e.g. courseadmin and superadmin)
            if(this.canViewAllCompetitions(thisCompetition.organizationId))
            {
                return true;
            }

            return false;
        },
        canViewHiddenScoreboard(competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.CAN_VIEW_HIDDEN_SCOREBOARD, [competitionId, organizationId, Claims.Value.ANY], [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canRegisterAsAdmin(competitionId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.CAN_REGISTER_CTF_ADMIN, competitionId, [
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canRegisterAsPlayer(competitionId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.CAN_REGISTER_CTF_PLAYER, competitionId, [
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canCreateChallenge(competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.POST_V1_CHALLENGE, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canUpdateChallenge(competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_CHALLENGE, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canSaveChallenge(competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PUT_V1_CHALLENGE_SAVE, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canSaveChallengeWithGlobalVisibility()
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.POST_V1_LIBRARY_ENTRY, [
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canSaveChallengeWithOrganizationalVisibility(organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.CAN_CREATE_LIBRARY_ENTRY_IN_ORGANIZATION, [organizationId, Claims.Value.ANY], [
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canUpdateDefaultChallenge()
        {
            return useEntitlementStore().hasClaim(Claims.Type.CAN_UPDATE_DEFAULT_CHALLENGE, Claims.Value.ANY, [
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canDeleteChallenge(competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_CHALLENGE, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canUpdateChallengeProtectedInformation()
        {
            return useEntitlementStore().hasClaim(
                Claims.Type.CAN_UPDATE_CHALLENGE_PROTECTED_INFORMATION, Claims.Value.ANY, [
                    Claims.Scope.ANY, 
                    Claims.Scope.UNDEFINED
            ])
        },
        canViewFlagsAndHints(competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.CAN_VIEW_FLAGS_AND_HINTS, [competitionId, organizationId, Claims.Value.ANY], [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ])
        },
        canManageSubmission(competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.CAN_MANAGE_SUBMISSIONS_IN_COMPETITION, [competitionId, Claims.Value.ANY], [
                competitionId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]) || useEntitlementStore().hasClaim(Claims.Type.CAN_MANAGE_SUBMISSIONS_IN_ORGANIZATION, [organizationId, Claims.Value.ANY], [
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canAddSolvedSubmission(competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PUT_V1_CHALLENGE_TEAM_SOLVE, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canUnsolveSubmission(competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PUT_V1_CHALLENGE_TEAM_UNSOLVE, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canApproveTeamMembershipRequest(teamId:string, competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PUT_V1_TEAM_APPROVE, [
                teamId,
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canKickPlayerFromTeam(teamId:string, competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PUT_V1_TEAM_KICK, [
                teamId,
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canPromoteTeamMembership(teamId:string, competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PUT_V1_TEAM_PROMOTE, [
                teamId,
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canRejectTeamMembershipRequest(teamId:string, competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PUT_V1_TEAM_REJECT, [
                teamId,
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canUpdateTeam(teamId:string, competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_TEAM, [
                teamId,
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]) &&
            (
                useEntitlementStore().hasClaim(Claims.Type.CTF_TEAM_LEADER, teamId, Claims.Scope.ANY)
                ||
                useEntitlementStore().hasClaim(Claims.Type.CAN_MANAGE_TEAMS_IN_COMPETITION, [competitionId, Claims.Value.ANY], [
                    competitionId,
                    organizationId,
                    Claims.Scope.UNDEFINED,
                    Claims.Scope.ANY])
                ||
                useEntitlementStore().hasClaim(Claims.Type.CAN_MANAGE_TEAMS_IN_ORGANIZATION, [organizationId, Claims.Value.ANY], [
                    organizationId,
                    Claims.Scope.UNDEFINED,
                    Claims.Scope.ANY
                ])
            )
        },
        canHideTeam(competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.CAN_HIDE_TEAM, [competitionId, organizationId, Claims.Value.ANY], [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canDeleteTeam(teamId:string, competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_TEAM, [
                teamId,
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ]);
        },
        canCreateLibraryEntry(competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.POST_V1_LIBRARY_ENTRY, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ])
        },
        canUpdateLibraryEntry(competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_LIBRARY_ENTRY, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ])
        },
        canDeleteLibraryEntry(competitionId: string, organizationId: string)
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_LIBRARY_ENTRY, [
                competitionId,
                organizationId,
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ])
        },
        canImportLibraryEntry()
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PUT_V1_IMPORT, [
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ])
        },
        canExportLibraryEntries()
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PUT_V1_EXPORT, [
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ])
        },
        canExportChallenges()
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PUT_V1_EXPORT, [
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ])
        },
        canImportChallenges()
        {
            return useEntitlementStore().hasClaim(Claims.Type.API, Claims.Value.PUT_V1_COMPETITION_IMPORT, [
                Claims.Scope.UNDEFINED,
                Claims.Scope.ANY
            ])
        },
        canCreateEnvironmentArtifact(competitionId: string, organizationId: string)
        {
            return useEntitlementStore()
                .hasClaim(
                    Claims.Type.CAN_CREATE_ENVIRONMENT_ARTIFACT,
                    [ competitionId, organizationId, Claims.Value.ANY ], 
                    [ competitionId, organizationId, Claims.Scope.ANY, Claims.Scope.UNDEFINED ]
            )
        },
        canLaunchEnvironment(competitionId: string)
        {
            return useEntitlementStore()
                .hasClaim(
                    Claims.Type.API,
                    Claims.Value.PUT_V1_CHALLENGE_ENVIRONMENT_LAUNCH,
                    [ competitionId, Claims.Scope.ANY, Claims.Scope.UNDEFINED ]
            )
        },
    }
});