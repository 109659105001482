<template>
    <v-footer class="elevation-0" height="auto" tag="footer">
        <v-card flat tile class="text-center" width="100%" :dark='isDark'>
            <v-card-text class="pt-3 pb-3">
                <v-row row wrap>
                    <template>
                        <v-col xs6 class="text-right footer-text">
                            <span>{{$t('FOOTER_TEXT')}}</span>
                        </v-col>
                        <v-col xs6 class="text-left footer-logo pl-2">
                            <a :href='logoUrl' target='_blank'>
                                <img :src="logo" height="40" contain/>
                            </a>
                        </v-col>
                    </template>
                </v-row>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Config from "@/config";
import uscrLightLogo from '@/assets/logo/uscr_light.svg'
import uscrDarkLogo from '@/assets/logo/uscr_dark.svg'
import vacrLightLogo from '@/assets/logo/vacr_light.svg'
import vacrDarkLogo from '@/assets/logo/vacr_dark.svg'
import { useThemeStore } from "@/stores/themeStore";

@Component({})
export default class Footer extends Vue 
{
    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    // END TODO
    
    logoUrl = Config.HOMEPAGE_BASE_URL;
    logo = '';

    mounted() 
    {
        if(Config.IS_USCR)
        {
            this.logo = this.isDark ? uscrLightLogo : uscrDarkLogo;
        }   
        else
        {
            this.logo = this.isDark ? vacrLightLogo : vacrDarkLogo;
        }    
    }
}
</script>

<style>
.v-footer, .v-footer .v-card
{
    background-color:inherit !important;
}
.footer-text
{
    padding-top:20px;
}
.footer-logo
{
    background-position: left !important;
}
</style>
