<template>
    <v-row wrap class="teamMembers">
        <v-col cols="12" class="pl-0">
            <v-subheader class="membersHeader">
                {{$t('TEAMS_MEMBERS')}}
            </v-subheader>
        </v-col>
        <v-col cols="12" v-if="activePlayers.length > 0">
            <v-chip v-for="(player, index) of activePlayers" :key="player.id" close-icon="mdi-delete" :aria-label="player.name" @keyup.enter="onKickClicked(player)" @keyup.space="onKickClicked(player)" @click:close="onKickClicked(player)" :disabled="isLoading" :dark="isDark" pill :close="editable && team && allowTeamMembershipChange && canKickPlayerFromTeam(team.id, competition.id, competition.organizationId)" outlined :class="`mr-2 mb-3 member member_${index}`">
                <v-avatar left class="mr-2" :dark="isDark">
                    <v-img :src="getGravatar(player)" />
                </v-avatar>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-if="isLeader(player)" v-on="on" class="mr-2 leader">mdi-police-badge-outline</v-icon>
                    </template>
                    {{$t('TEAMS_MEMBERS_LEADER')}}
                </v-tooltip>
                {{player.name}}
            </v-chip>
        </v-col>
        <v-col cols="12" v-else>
            <p class="noMembers ml-1 caption">
                {{$t('TEAMS_MEMBERS_NO_MEMBERS')}}
            </p>
        </v-col>
        <v-col v-if="pendingPlayers.length > 0" cols="12" class="pl-0">
            <v-subheader class="pendingMembersHeader">
                {{$t('TEAMS_PENDING_MEMBERS')}}
            </v-subheader>
            <div v-if="teamIsFull && editable" class="pt-1 pl-4 centered" id="fullTeamWarningMessage">
                <v-icon color="warning" class="mb-1">warning</v-icon>
                {{$t('TEAMS_FULL_TEAM_WARNING')}}
            </div>
            <v-col cols="12">
                <v-chip v-for="(player, index) of pendingPlayers" :key="player.id" close-icon="thumb_up" :aria-label="player.name" @click:close="onApproveClicked(player)" @keyup.space="onApproveClicked(player)" @keyup.enter="onApproveClicked(player)" :dark="isDark" :disabled="isLoading" pill :close="editable && team && !teamIsFull && canApproveTeamMembershipRequest(team.id, competition.id, competition.organizationId) && canRejectTeamMembershipRequest(team.id, competition.id, competition.organizationId)" outlined :class="`mr-2 mb-3 pending_member pending_member_${index}`">
                    <v-avatar :dark="isDark" left class="mr-2">
                        <v-img :src="getGravatar(player)" />
                    </v-avatar>
                    {{player.name}}
                </v-chip>
            </v-col>
        </v-col>
        <kick-team-member-dialog v-model="showKickTeamMemberDialog" :player="selectedPlayer" :lastPlayer="activePlayers.length + pendingPlayers.length === 1" @confirm="onTeamMemberKicked" />
        <approve-team-member-dialog v-model="showApproveTeamMemberDialog" :player="selectedPlayer" @approved="onTeamMemberApproved" @rejected="onTeamMemberRejected" />
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import StoreGetter from '@interfaces/storeGetter';
import { Prop, Watch } from 'vue-property-decorator';
import { ITeam } from '@cyber-range/cyber-range-api-ctf-team-client';
import { IPlayer, PlayerStatus, Player } from '@cyber-range/cyber-range-api-ctf-player-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import gravatar from 'gravatar';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';

@Component
export default class TeamMembers extends Vue 
{  
    @Prop(Object) team:ITeam;
    @Prop(Array) players:IPlayer[];
    @Prop(Boolean) isDark:boolean;
    @Prop(Boolean) editable:boolean;

    @Getter(StoreGetter.GetMyTeam) myTeamId:string;

    activePlayers:IPlayer[] = [];
    pendingPlayers:IPlayer[] = [];
    showKickTeamMemberDialog:boolean = false;
    showApproveTeamMemberDialog:boolean = false;
    selectedPlayer:IPlayer = new Player();
    
    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    canApproveTeamMembershipRequest(teamId:string, competitionId: string, organizationId: string): boolean
    {
        return useAuthorizationStore().canApproveTeamMembershipRequest(teamId, competitionId, organizationId);
    }
    canKickPlayerFromTeam(teamId:string, competitionId: string, organizationId: string): boolean
    {
        return useAuthorizationStore().canKickPlayerFromTeam(teamId, competitionId, organizationId);
    }
    canRejectTeamMembershipRequest(teamId:string, competitionId: string, organizationId: string): boolean
    {
        return useAuthorizationStore().canRejectTeamMembershipRequest(teamId, competitionId, organizationId);
    }
    // END TODO

    get spaceForMorePlayers(): number
    {
        if(this?.competition?.limits)
        {
            return this.competition.limits.maxPlayersPerTeam - this.activePlayers.length;
        }
        return 1; // if competition limits are undefined, there is always room for one more player
    }

    get allowTeamMembershipChange(): boolean
    {
        return this.competition.settings?.allowTeamMembershipChange || false;
    }

    get teamIsFull():boolean
    {
        return this.spaceForMorePlayers <= 0;
    }

    @Watch("team")
    async onTeamChanged()
    {
        await this.refresh();
    }

    @Watch("players")
    async onPlayersChanged()
    {
        await this.refresh();
    }

    onKickClicked(player:IPlayer)
    {
        this.selectedPlayer = player;
        this.showKickTeamMemberDialog = true;
    }

    onTeamMemberKicked()
    {
        this.activePlayers = this.activePlayers.filter(p => p.id !== this.selectedPlayer.id);

        this.$emit('kicked', this.selectedPlayer);
    }

    onApproveClicked(player:IPlayer)
    {
        this.selectedPlayer = player;
        this.showApproveTeamMemberDialog = true;
    }

    onTeamMemberApproved()
    {
        this.activePlayers.push(this.selectedPlayer);
        this.pendingPlayers = this.pendingPlayers.filter(p => p.id !== this.selectedPlayer.id);

        this.$emit('approved', this.selectedPlayer);
    }

    onTeamMemberRejected()
    {
        this.pendingPlayers = this.pendingPlayers.filter(p => p.id !== this.selectedPlayer.id);

        this.$emit('rejected', this.selectedPlayer);
    }

    async mounted()  
    {
        await this.refresh();
    }

    async refresh()
    {
        this.activePlayers = [];
        this.pendingPlayers = [];

        for(let player of this.players)
        {
            if(player.status === PlayerStatus.Ready)
            {
                this.activePlayers.push(player);
            }
            else if(player.status === PlayerStatus.Joining)
            {
                this.pendingPlayers.push(player);
            }
        }
    }

    isLeader(player:IPlayer): boolean
    {
        return this.team?.leaderUserId === player.userId;
    }

    getGravatar(player:IPlayer): string
    {
        return player ? gravatar.url(player.email, {d:'retro',s:'32'}) : '';
    }
}
</script>

<style>
.teamMembers .v-chip .v-icon:not(.leader) 
{
    color: var(--v-dialogIcon-base);
}
</style>