<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import StoreAction from '@interfaces/storeAction';
import StoreGetter from '../../interfaces/storeGetter';
import moment from 'moment';
import BaseLanding from './Landing.vue';
import { mixins } from 'vue-class-component';

export default class Landing extends mixins(BaseLanding) 
{   
    
}
</script>