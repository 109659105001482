export default {
    en: {
        DAY: "day",
        DAYS: "days",
        HOUR: "hour",
        HOURS: "hours",
        MINUTE: "minute",
        MINUTES: "minutes",
        SECOND: "second",
        SECONDS: "seconds",
        COUNTDOWN_WINDOW_TITLE: 'Ends in',
        COUNTDOWN_WINDOW_TIMED: 'This competition has ended.'
    }
};
