<template>
  <div>
    <h1>{{$t('MANAGE')}}</h1>

     <v-toolbar :dark='isDark' class="mt-5 mb-5 elevation-0" id="manageToolbar">
        <v-toolbar-items class="pr-5">
            <v-btn id="manageSettingsButton" text :to="settingsRoute" :dark='isDark'>{{$t('MANAGE_SETTINGS')}}</v-btn>
            <v-btn id="managePlayersButton" text :to="playerRoute" :dark='isDark'>{{$t('MANAGE_PLAYERS')}}</v-btn>
            <v-btn id="manageTeamsButton" text :to="teamRoute" :dark='isDark'>{{$t('MANAGE_TEAMS')}}</v-btn>
            <v-btn id="manageChallengesButton" text :to="manageChallengesRoute" :dark='isDark'>{{$t('MANAGE_CHALLENGES')}}</v-btn>
            <v-btn id="manageLibraryButton" v-if="canCreateLibraryEntry(competition.id, competition.organizationId)" text :to="libraryRoute" :dark='isDark'>{{$t('MANAGE_LIBRARY')}}</v-btn>
        </v-toolbar-items>
    </v-toolbar>

    <router-view class="view"></router-view>

  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import Route from '@interfaces/route';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { useThemeStore } from '@/stores/themeStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';

@Component({components:{}})
export default class Manage extends Vue 
{
  // TODO: Change this to composition api
  get isDark():boolean
  {
    return useThemeStore().isDark;
  }
  get competition(): ICompetition
  {
    return useCompetitionStore().currentCompetition;
  }
  canCreateLibraryEntry(competitionId:string, organizationId:string): boolean
  {
    return useAuthorizationStore().canCreateLibraryEntry(competitionId, organizationId);
  }
  // END TODO

  get settingsRoute() { return {name: Route.Settings.name}}
  get playerRoute() { return {name: Route.Players.name}}
  get teamRoute() { return {name: Route.Teams.name}}
  get manageChallengesRoute() { return { name: Route.ManageChallenges.name}}
  get libraryRoute() { return {name: Route.Library.name}}
}
</script>

<style scoped>
#manageToolbar{
  background-color: unset;
}
.view{
  margin-bottom:100px;
}
</style>