export default {
    en: {
        SETTINGS_TITLE: 'Settings',
        SETTINGS_COMPETITION_NAME: 'Competition Name',
        SETTINGS_COMPETITION_SUBDOMAIN: 'Competition Subdomain',
        SETTINGS_START: 'Start at',
        SETTINGS_END: 'End at',
        SETTINGS_DISALLOW_PUBLIC_REGISTRATION_TOOLTIP: 'Your CloudCTF subscription does not allow public registration.',
        SETTINGS_ALLOW_PUBLIC_REGISTRATION: 'Allow public registration',
        SETTINGS_ALLOW_DIRECT_LOGIN_TOOLTIP: 'Your CloudCTF subscription does not allow direct login using username/password.',
        SETTINGS_ALLOW_DIRECT_LOGIN: 'Allow direct login using username/password',
        SETTINGS_ALLOW_TEAM_NAME_CHANGED: 'Allow players to change their team names',
        SETTINGS_ALLOW_TEAM_PLAY: 'Allow team play',
        SETTINGS_ALLOW_PLAYER_NAME_CHANGED: 'Allow player to change their names',
        SETTINGS_ALLOW_UNREGISTERED_USERS_TO_VIEW_SCOREBOARD: 'Allow unregistered users to view scoreboard',
        SETTINGS_SHOW_SCOREBOARD: 'Show scoreboard to players',
        SETTINGS_SHOW_SCOREBOARD_TOOLTIP: 'While disabled, the scoreboard will still be visible for competition admins.',
        SETTINGS_THEME: 'Theme',
        SETTINGS_CONFIRM: 'Confirm',
        SETTINGS_RESET: 'Reset',
        SETTINGS_RELOAD_DIALOG_TITLE: 'Reload the New Theme Now?',
        SETTINGS_SUBDOMAIN_CHANGED_DIALOG_TITLE: 'The competition subdomain has changed!',
        SETTINGS_SUBDOMAIN_CHANGED_DIALOG_MESSAGE: 'Changing the competition subdomain will reload the application.',
        SETTINGS_RELOAD_DIALOG_MESSAGE: 'Otherwise, the new theme will appear the next time you log in.',
        SETTINGS_RELOAD_DIALOG_CANCEL: 'Later',
        SETTINGS_RELOAD_DIALOG_CONFIRM: 'Reload',
        SETTINGS_REDIRECT_DIALOG_CONFIRM: 'Redirect',
        SETTINGS_MAX_TEAMS_LABEL: 'Max Teams',
        SETTINGS_MAX_CHALLENGES_LABEL: 'Max Challenges',
        SETTINGS_MAX_PLAYERS_LABEL: 'Max Players',
        SETTINGS_MAX_PLAYERS_PER_TEAM_LABEL: 'Max Team Size',
        SETTINGS_MAX_FILE_STORAGE_LABEL: 'Max File Storage In MB',
        SETTINGS_MAX_ENVIRONMENTS_LABEL: 'Max Environments',
        SETTINGS_MAX_ENVIRONMENTS_PER_CHALLENGE_LABEL: 'Max Environments per Challenge',
        SETTINGS_LIMITS_TOOLTIP_TEXT: 'This limit is based on an organization subscription.<br/>Please contact your organization admin to increase the limit.'
    }
};
