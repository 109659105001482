<template>
    <v-card class="elevation-0 pa-5 alphaBackground" :dark="isDark">
        <h3 class="mb-5" v-if="showAllOptions">{{$t('WELCOME_TITLE')}}</h3>
        <loading v-if="isCreating"/>
        <template v-else>
            <play-solo-option v-if="showAllOptions" />
            <template v-if="allowTeamMembershipChange">
                <join-team-option v-model="showJoinTeamOption" v-if="showJoinTeamOption || showAllOptions" />
                <new-team-option v-model="showCreateTeamOption" v-if="showCreateTeamOption || showAllOptions" />
            </template>
        </template>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import { storeToRefs } from 'pinia';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import StoreGetter from '@interfaces/storeGetter';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { Watch } from 'vue-property-decorator';
import StoreAction from '@/interfaces/storeAction';
import Route from '@/interfaces/route';
import { useThemeStore } from '@/stores/themeStore';
import { useAgreementStore } from '@stores/agreementsStore';
import { useCompetitionStore } from '@stores/competitionStore';

@Component
export default class PlayingOptions extends Vue 
{
    showCreateTeamOption:boolean = false;
    showJoinTeamOption:boolean = false;
    isCreating:boolean = false;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    hasPendingAgreements = storeToRefs(useAgreementStore()).hasPendingAgreements
    // END TODO

    @Watch('hasPendingAgreements', { immediate: true })
    async onHasAcceptedAllAgreements(hasPendingAgreementsNewValue: boolean)
    {
        if (!this.allowTeamMembershipChange && !hasPendingAgreementsNewValue)
        {
            this.isCreating = true;
            await this.$store.dispatch(StoreAction.JoinCompetition);
            this.$router.replace(Route.Challenges);
        }
    }

    get allowTeamMembershipChange(): boolean
    {
        return this.competition.settings?.allowTeamMembershipChange || false;
    }

    get showAllOptions():boolean
    {
        return !this.showCreateTeamOption && !this.showJoinTeamOption;
    }
}
</script>