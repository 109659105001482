<template>
    <delete-confirmation-dialog v-model="showDialog" @confirm="confirm" @cancel="cancel" :checkbox="true" :disabled="isLoading">
        <span v-html="$t('CHALLENGE_SUBMISSION_UNSOLVE_DIALOG_MESSAGE', { challengeName, submittedBy: submittingPlayerName })" />
    </delete-confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ISubmission } from '@cyber-range/cyber-range-api-ctf-submission-client';
import { Prop, Watch } from 'vue-property-decorator';
import { IPlayerApiClient } from '@cyber-range/cyber-range-api-ctf-player-client';
import { IChallengeApiClient } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { encode } from 'html-entities';
import { useApiClientStore } from '@stores/apiClientStore';

@Component
export default class UnsolveSubmissionDialog extends Vue 
{
    @Prop(Boolean) value:boolean;
    @Prop(Object) submission:ISubmission;

    submittingPlayerName:string = '';

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get playerApiClient(): IPlayerApiClient
    {
        return useApiClientStore().backgroundPlayerApiClient;
    }
    get challengeApiClient(): IChallengeApiClient
    {
        return useApiClientStore().challengeApiClient;
    }
    // END TODO

    get showDialog()
    {
        return this.value;
    }
    set showDialog(value)
    {
        this.$emit('input', value);
    }

    get challengeName()
    {
        return encode(this.submission.challengeName);
    }

    @Watch('submission', { immediate: true })
    async onSubmissionChanged(submission: ISubmission, oldSubmission: ISubmission)
    {
        if (submission?.playerId !== oldSubmission?.playerId)
        {
            this.submittingPlayerName = '';
            const submittingPlayer = await this.playerApiClient.getOne(submission.playerId);
            this.submittingPlayerName = encode(submittingPlayer?.name || '');
        }
    }

    async confirm()
    {
        await this.challengeApiClient.unsolveChallengeForTeam(this.submission.challengeId, this.submission.teamId);

        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.$emit('input', false);
    }
}
</script>
