import { defineStore } from 'pinia';
import { useAuthenticationStore } from '@stores/authenticationStore';

export const useErrorStore = defineStore('errorStore', {
    state: () =>
    ({
        errorCode: 0,
        errorMessage: undefined as string | undefined,
        errorDetails: undefined as string | undefined
    }),
    actions:
    {
        setError(e): void
        {
            if(e)
            {
                let errorMessage = e.message;

                // Suppress Bad Gateway error until Sysops fix the root cause.
                if(e.statusCode === 502) return;

                if(e.statusCode === 403 || e.statusCode === 422 || (e.statusCode === 400 && (e.message === 'jwt expired') || e.message === 'Authorization header value is malformed.'))
                {
                    useAuthenticationStore().logout();
                    if(e.message === 'Forbidden' || e.message === 'jwt expired' || e.message === 'Authorization header value is malformed.') errorMessage = 'Your session has expired.'
                }
                
                //When a user does not have an account, multiple errors will be generated.
                //Ignore all errors if the current error indicates the user does not have an account.
                if(this.errorMessage?.indexOf('Your account could not be found') === 0)
                {
                    return;
                }
                
                this.errorCode = e.statusCode;
                this.errorMessage = errorMessage;
                this.errorDetails = e.details;
            }
            else
            {
                this.errorCode = 0;
                this.errorMessage = undefined;
                this.errorDetails = undefined;
            }
        },
        resetError(): void
        {
            this.setError(undefined);
        }
    }
});