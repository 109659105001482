<template>
    <confirmation-dialog v-model="showConfirmationDialog" :title="$t('PLAYERS_HISTORY_DIALOG_TITLE', {name: this.name})" :confirm="$t('PLAYERS_HISTORY_DIALOG_CONFIRM')" :cancel="false" @confirm="confirm" :loading="isLoading" id="playerHistoryDialog">
        <v-list flat style="max-height: 50%" class="overflow-y-auto" id="playerHistoryDialogList">
            <v-list-item two-line v-for="history in histories" :key="history.id" class="mb-3">
                <v-list-item-content>
                    <v-list-item-title>{{ history.competitionName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ history.timeframe }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-list-item-action-text class='title'>{{ history.score }} {{$t('PLAYERS_HISTORY_POINTS')}}</v-list-item-action-text>
                </v-list-item-action>
            </v-list-item>
      </v-list>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IPlayerApiClient, PlayerFilter, IPlayerPage, IPlayer } from '@cyber-range/cyber-range-api-ctf-player-client';
import { ICompetition, ICompetitionApiClient, CompetitionFilter, ICompetitionPage } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { Prop, Watch } from 'vue-property-decorator';
import PlayerHistoryView from './PlayerHistoryView';
import Config from '@/config';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';

@Component({components:{}})
export default class PlayerHistoryDialog extends Vue 
{
    @Prop(Boolean) value:boolean;
    @Prop(Object) player:IPlayer;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get playerApiClient(): IPlayerApiClient
    {
        return useApiClientStore().playerApiClient;
    }
    get competitionApiClient(): ICompetitionApiClient
    {
        return useApiClientStore().competitionApiClient;
    }
    // END TODO

    showConfirmationDialog:boolean = false;
    name:string = '';
    histories = [];

    async mounted() 
    {
        if(this.player) await this.load()
    }

    @Watch('value')
    async onValueChanged(value:boolean)
    {
        if(value) await this.load()
    }

    async load()
    {
        try
        {
            this.histories = [];

            let [players, competitions] = await Promise.all([this.loadPlayers(), this.loadAllCompetitions()]);
            
            for(let player of players)
            {
                this.histories.push(new PlayerHistoryView(player, competitions));
            }

            this.name = this.player.name;

            this.showConfirmationDialog = this.value;
        }
        catch(e)
        {
            this.close();
        }
    }

    async loadPlayers(): Promise<IPlayer[]>
    {
        let players = [];

        let filter = new PlayerFilter({userId: this.player.userId, limit: Config.DEFAULT_FETCH_SIZE});
        let page:IPlayerPage;
        if(this.player?.userId)
        {
            do
            {
                page = await this.playerApiClient.get(filter);
                filter.token = page.nextPageToken;
                players = players.concat(page.items);
            }
            while(page.nextPageToken);
        }

        return players;
    }

    async loadAllCompetitions(): Promise<ICompetition[]>
    {
        let competitions = [];

        let filter = new CompetitionFilter(<any>{limit:Config.DEFAULT_FETCH_SIZE});
        let page:ICompetitionPage;
        
        do
        {
            page = await this.competitionApiClient.get(filter);
            filter.token = page.nextPageToken;
            competitions = competitions.concat(page.items);
        }
        while(page.nextPageToken);

        return competitions;
    }

    async confirm()
    {
        this.$emit('confirm', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.$emit('input', false);
    }
}
</script>