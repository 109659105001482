<template>
    <confirmation-dialog v-model="showDialog" :title="dialogTitle" @confirm="confirm" @cancel="cancel" :loading="isLoading" :confirm="dialogConfirmText" :cancel="dialogCancelText" class="hintDialog">
        <loading v-if="loading" />
        <span v-else id="hintDialogMessage">
                {{hint.message || $t('CHALLENGES_HINT_DIALOG_MESSAGE', {cost: hint.cost})}}
        </span>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { IHint, IChallengeApiClient } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { useApiClientStore } from '@stores/apiClientStore';

@Component({components:{}})
export default class HintDialog extends Vue 
{  
    @Prop(String) challengeId:string;
    @Prop(Object) hint:IHint;
    @Prop(Number) points:number;
    @Prop(Boolean) value:boolean;

    loading:boolean = false;
    showDialog:boolean = false;
    title:string = '';

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get challengeApiClient(): IChallengeApiClient
    {
        return useApiClientStore().challengeApiClient;
    }
    get backgroundChallengeApiClient(): IChallengeApiClient
    {
        return useApiClientStore().backgroundChallengeApiClient;
    }
    // END TODO
    
    get dialogTitle()
    {
        if(this.loading) return ' ';

        return this.hint.message ? this.$t('CHALLENGES_HINT_DIALOG_UNLOCKED_TITLE') : this.$t('CHALLENGES_HINT_DIALOG_QUESTION_TITLE');
    }

    get dialogConfirmText()
    {
        if(this.loading) return false;

        return this.hint.message ? this.$t('CHALLENGES_HINT_DIALOG_UNLOCKED_CONFIRM') : this.$t('CHALLENGES_HINT_DIALOG_CONFIRM')
    }

    get dialogCancelText()
    {
        if(this.loading) return false;
        
        return this.hint.message ? false : this.$t('CHALLENGES_HINT_DIALOG_CANCEL');
    }

    @Watch('value')
    async onValueChanged(value:boolean)
    {
        if(this.value) await this.load();
    }

    async mounted() 
    {
        if(this.value) await this.load();
    }
    
    async load()
    {
        this.showDialog = true;

        if(!this.hint.message)
        {
            this.loading = true;
            try
            {
                this.hint.message = (await this.backgroundChallengeApiClient.getChallengeHint(this.challengeId, this.hint.id)).message;
            }
            finally
            {
                this.loading = false;
            }
        }
    }
    async confirm()
    {
        if(this.hint.message)
        {
            this.$emit('confirm', true);
            this.close();
        }
        else
        {
            this.hint.message = (await this.challengeApiClient.putChallengeHint(this.challengeId, this.hint.id))?.message;
        }
    }

    async cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showDialog = false;
        this.$emit('input', false);
    }
}
</script>