import Config from '@/config';
import { defineStore } from 'pinia';

export const useFeatureStore = defineStore('featureStore', 
{
    getters:
    {
        invitationEnabled: ():boolean => Config.FEATURE_INVITATION_ENABLED !== false
    }
});
