export default {
    en: {
        LIBRARY_ENTRIES_DIALOG_TITLE: "Create a Challenge",
        LIBRARY_ENTRIES_DIALOG_CONFIRM: "Next",
        LIBRARY_ENTRIES_DIALOG_SPLASH_SCREEN_TEXT: "Please select a challenge from the list.",
        LIBRARY_ENTRIES_DIALOG_PREVIEW_CATEGORY: "Category",
        LIBRARY_ENTRIES_DIALOG_PREVIEW_POINTS: "Points",
        LIBRARY_ENTRIES_DIALOG_PREVIEW_DIFFICULTY: "Difficulty",
        LIBRARY_ENTRIES_DIALOG_PREVIEW_DIFFICULTY_UNKNOWN: "Unknown"
    }
};
