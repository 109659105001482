<script lang="ts">
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import BaseScoreChart from './scoreChart.vue';

export default class ScoreChart extends mixins(BaseScoreChart) 
{
    gridlineColor ='darkgrey';
    textColor = 'dimgrey';
}
</script>

<style>
.scoreChartLight
{
  background-color: white;
}
</style>