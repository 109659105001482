<template>
  <v-app :style="themeStyle" :class="themeClass">
    <splash-screen v-if="showSplashScreen" />
    <router-view></router-view>
  </v-app>
</template>

<style>
a{
  text-decoration: none;
}
/* Vue1.1 */
.v-menu__activator {
	height: auto !important;
}
#inspire > div > main > div.v-content__wrap {display:flex;}
/* Vue2.0 */
.v-expansion-panels.v-item-group{
  position:inherit;
}
.v-expansion-panel-content__wrap{
  padding-bottom: 0px;
}
.v-card__text{
  color:inherit !important;
}
.v-counter,
.v-messages__wrapper,
.v-label,
.theme--light.v-input:not(.v-input--is-disabled) input,
.v-select .v-select__selection--comma,
.theme--light.v-application,
.theme--dark.v-application,
.theme--light.v-select .v-select__selections,
.v-list-item.theme--light.primary--text
{
  background: unset;
  color: inherit !important;
  font-family: unset;
}
.v-application .caption,
.v-application .overline,
.v-application .body-1,
.v-application .body-2,
.v-application .subtitle-1,
.v-application .subtitle-2,
.v-application .title,
.v-application .headline,
.v-application .display-1,
.v-application .display-2,
.v-application .display-3,
.v-application .display-4{
  font-family: unset !important;
}
/*v-switch and v-checkbox label*/
.v-input__control .v-label
{
  color: var(--v-text-base) !important;
}

.theme--dark .alphaBackground
{
  background-color: rgba(0,0,0, 0.6); /*--v-application-base*/
}
.theme--light .alphaBackground
{
  background-color: inherit;
}

.usCyberGamesSeasonII .alphaBackground
{
  background-color: rgba(0,0,0, 0.7); /*--v-application-base*/
}

.comicbook .v-application--wrap
{
  border: 10px solid white;
}
.comicbook .v-application--wrap::before
{
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 2px solid black;
}
.comicbook .v-data-table
{
  font-family: 'ComicNeue-Bold';
  letter-spacing: normal;
}

.theme--light .obviousWhenSelected:focus
{
    outline: 6px solid black;
}
.theme--dark .obviousWhenSelected:focus
{
    outline: 6px solid white;
}

input::placeholder 
{
  color: lightgray!important;
  opacity: 1;
}
.v-input,
.v-input .v-label
{
    font-size: unset !important;
}

.visually-hidden {
    position: absolute !important;
    height: 1px; 
    width: 1px; 
    overflow: hidden;
    padding: 0 !important;
    border: 0! important;
    white-space: nowrap !important;
    clip: rect(1px 1px 1px 1px) !important; /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%) !important;
}
</style>


<style lang="less">
@font-face { 
    font-family: "vcr_osd_mono";
    src: url("../assets/themes/default/VCR_OSD_MONO_1.001.ttf"); 
}
@font-face { 
    font-family: "Blinker-Bold";
    src: url("../assets/themes/dark/Blinker-Bold.ttf"); 
}
@font-face { 
    font-family: "Bangers-Regular";
    src: url("../assets/themes/comicBook/Bangers-Regular.ttf");
}
@font-face { 
    font-family: "ComicNeue-Bold";
    src: url("../assets/themes/comicBook/ComicNeue-Bold.ttf");
}
@font-face {
    font-family: "ShareTech";
    src: url("../assets/themes/cyberIcons/ShareTech-Regular.ttf");
}
@font-face {
    font-family: "ShareTechMono";
    src: url("../assets/themes/cyberIcons/ShareTechMono-Regular.ttf");
}
</style>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import SplashScreen from '@components/SplashScreen.vue';
import { computed } from "vue";
import { useSplashScreenStore } from "@/stores/splashScreenStore";
import { useThemeStore } from '@/stores/themeStore';

const { currentTheme } = storeToRefs(useThemeStore());
const { showSplashScreen } = storeToRefs(useSplashScreenStore());

const themeClass = computed(() =>
{
    return {[currentTheme.value.id.toLowerCase()]:true}
})

const themeStyle = computed(() =>
{
    let style = {};

    if(currentTheme.value.colors.application)
    {
        style['background'] = currentTheme.value.colors.application;
    }

    if(currentTheme.value.colors.text)
    {
        style['color'] = `${currentTheme.value.colors.text} !important`;
    }

    if(currentTheme.value.backgroundImage)
    {
        style['background-image'] = `url(${currentTheme.value.backgroundImage})`;
        style['background-size'] = 'cover';
        style['background-repeat'] = 'no-repeat';
        style['background-position'] = 'top left';
        style['background-attachment'] = 'fixed';
    }

    if(currentTheme.value.fontFamily)
    {
        style['font-family'] = `${currentTheme.value.fontFamily} !important`; 
    }

    if(currentTheme.value.letterSpacing)
    {
        style['letter-spacing'] = `${currentTheme.value.letterSpacing} !important`
    }

    return style;
});
</script>
