export default {
    en: {
        'TABLE_HEADER_ALL_ITEMS_SELECTED': 'All {count} {itemName} are selected.',
        'TABLE_HEADER_ALL_ITEMS_ON_PAGE_SELECTED': 'All {count} {itemName} on this page are selected.',
        'TABLE_HEADER_CLEAR_SELECTION': 'Clear selection',
        'TABLE_HEADER_SELECT_ALL_PROMPT': 'Would you like to select all {count} {itemName}?',
        'TABLE_HEADER_DELETE_LABEL': 'Delete selected {item}',
        'TABLE_HEADER_ADD_LABEL': 'Add selected {item}',
        'TABLE_HEADER_EXPORT_LABEL': 'Export selected {item}',
    }
}