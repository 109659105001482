<template>
    <v-row class="tableHeader mx-2 py-1" v-show="selected > 0">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn v-if="deleteButton" v-on="on" class="tableHeaderDeleteButton" icon :aria-label="$t('TABLE_HEADER_DELETE_LABEL', {item})" @click="onDeleteClicked">
                    <v-icon>delete</v-icon>
                </v-btn>    
            </template>
            <span>{{$t('TABLE_HEADER_DELETE_LABEL', {item})}}</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn v-if="addButton" v-on="on" class="tableHeaderAddButton" :aria-label="$t('TABLE_HEADER_ADD_LABEL', {item: addItem || item})" icon @click="onAddClicked">
                    <v-icon>queue</v-icon>
                </v-btn>
            </template>
            <span>{{$t('TABLE_HEADER_ADD_LABEL', {item: addItem || item})}}</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn v-if="exportButton" v-on="on" :aria-label="$t('TABLE_HEADER_EXPORT_LABEL', {item})" class="tableHeaderExportButton" icon @click="onExportClicked">
                    <v-icon >download</v-icon>
                </v-btn>
            </template>
            <span>{{$t('TABLE_HEADER_EXPORT_LABEL', {item})}}</span>
        </v-tooltip>
        <select-all-table-items v-show="showSelectAllTableItems" :item="item" :selected="selected" :total="total" @select-all="onSelectAll" @clear="onClear"/>
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class TableHeader extends Vue {
    @Prop(Number) total: number;
    @Prop(Number) page: number;
    @Prop(Number) selected: number;
    @Prop(String) item: string;
    @Prop(String) addItem: string;
    @Prop(Boolean) deleteButton:boolean;
    @Prop(Boolean) addButton:boolean;
    @Prop(Boolean) exportButton:boolean;

    get showSelectAllTableItems(): boolean
    {
        return this.selected === this.page || this.selected === this.total
    }

    onDeleteClicked()
    {
        this.$emit('delete');
    }

    onAddClicked()
    {
        this.$emit('add');
    }

    onExportClicked()
    {
        this.$emit('export');
    }

    onSelectAll()
    {
        this.$emit('select-all');
    }

    onClear()
    {
        this.$emit('clear');
    }
}
</script>

<style scoped>
.tableHeader {
    margin-left: 10px !important; 
}
</style>
