export default {
    en: {
        TEAMS_TITLE: 'Teams',
        TEAMS_ADD_TOOLTIP: 'Add a New Team',
        TEAMS_LOADING: 'Loading...',
        TEAMS_NO_DATA: 'This CTF has no teams.',
        TEAMS_HEADER_TEAM_NAME: 'Team',
        TEAMS_HEADER_LEADER_NAME: 'Leader',
        TEAMS_HEADER_CORRECT_FLAG_SUBMISSION: 'Correct Flag Submissions',
        TEAMS_HEADER_SCORE: 'Score',
        TEAMS_DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete <b>{name}</b> from this competition?',
        TEAMS_DELETE_TEAMS_DIALOG_MESSAGE: 'Are you sure you want to delete {count} teams?',
        TEAMS_DELETE_JOB_PROGESS_DIALOG_TITLE: 'Deleting Teams',
        TEAMS_DELETE_DIALOG_CONFIRM: 'Delete',
        TEAMS_DELETE: 'Delete',
        TEAMS_EDIT_DIALOG_TITLE: 'Edit a Team',
        TEAMS_EDIT_DIALOG_NAME: 'Name',
        TEAMS_EDIT_DIALOG_HIDDEN: 'Hide from the scoreboard',
        TEAMS_HIDDEN_TOOLTIP: 'This team is hidden from the scoreboard.',
        TEAMS_EDIT: 'Edit',
        TEAMS_KICK_DIALOG_MESSAGE: 'Are you sure you want to remove <b>{name}</b> from this team?',
        TEAMS_KICK_TEAM_MEMBER_DIALOG_MESSAGE: 'I understand that removing the last player from a team will cause the team to be deleted.',
        TEAMS_APPROVE_DIALOG_TITLE: 'Team Joining Request',
        TEAMS_APPROVE_DIALOG_APPROVE: 'Approve',
        TEAMS_APPROVE_DIALOG_REJECT: 'Reject',
        TEAMS_APPROVE_DIALOG_CANCEL: 'Cancel',
        TEAMS_APPROVE_DIALOG_MESSAGE: 'Approve <b>{name}</b> to join your team?',
        TEAMS_MEMBERS: 'Members',
        TEAMS_PENDING_MEMBERS: 'Pending Members',
        TEAMS_MEMBERS_NO_MEMBERS: 'This team has no members.',
        TEAMS_MEMBERS_LEADER: 'Team Leader',
        TEAMS_VIEW_DIALOG_TITLE: 'Team Score',
        TEAMS_VIEW_DIALOG_ADD_SOLVED_SUBMISSION: 'Add a Submission',
        TEAMS_VIEW_DIALOG_CONFIRM: 'Close',
        TEAMS_VIEW: 'View Scores',
        TEAMS_ACTIONS_LABEL: '{name} actions',
        TEAMS_SUBMISSIONS_ACTIONS_LABEL: '{name} team submission actions',
        TEAMS_SUBMISSIONS_TABLE_ACTIONS: 'Actions',
        TEAMS_SUBMISSIONS_TABLE_TIME: 'Time',
        TEAMS_SUBMISSIONS_TABLE_CHALLENGE: 'Challenge',
        TEAMS_SUBMISSIONS_TABLE_SOLVER: 'Solver',
        TEAMS_SUBMISSIONS_TABLE_POINTS_EARNED: 'Points Earned',
        TEAMS_SUBMISSIONS_TABLE_HINT: '(Hint)',
        TEAMS_SUBMISSIONS_TABLE_CORRECTION: '(Correction)',
        TEAMS_SUBMISSIONS_TABLE_EMPTY: 'This team has no submissions.',
        TEAMS_ADD_DIALOG_TITLE: 'Add a New Team',
        TEAMS_ADD_DIALOG_NAME: 'Team Name',
        TEAMS_UNSOLVE: 'Unsolve',
        TEAMS_TOGGLE_SELECT_ALL_LABEL: 'All teams',
        TEAMS_SELECT_LABEL: 'Team: {name}',
        TEAMS_EXPORT_LABEL: 'Export Table as .csv',
        TEAMS_FULL_TEAM_WARNING : "The team is full. You must remove players before approving another player."
    }
};
