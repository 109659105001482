<template>
    <confirmation-dialog id="errorDialog"
        v-model="show" 
        :title="$t('ERROR')"
        max-width="50%"
        :cancel="false"
        :confirm="$t('OK')"
        @confirm="confirm"
        persistent>
        <template slot="titleIcon">
            <v-icon color="error" size="1.5em">error</v-icon>
        </template>
        {{errorMessage}}
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { useErrorStore } from '@stores/errorStore';
import { storeToRefs } from "pinia";
@Component({
    components: {}
})
export default class ErrorDialog extends Vue {

    // TODO: Change this to composition api
    errorCode = storeToRefs(useErrorStore()).errorCode;
    errorMessage = storeToRefs(useErrorStore()).errorMessage;
    // END TODO

    get show(){
        return !!this.errorMessage;
    }
    
    get isAuthError(): boolean
    {
        return this.errorCode === 403 || this.errorCode === 422 || this.errorCode === 400 && (this.errorMessage.includes('jwt expired') || this.errorMessage.includes('Authorization header value is malformed.'));
    }

    confirm()
    {
        let reloadRequired = this.isAuthError;
        useErrorStore().resetError();
        if(reloadRequired) {
            window.location.replace('/');
        }
    }
}
</script>