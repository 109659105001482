import ITheme from "@/interfaces/iTheme";
import backgroundImageDarkTheme from "@assets/themes/dark/backgroundImageDarkTheme.svg";

export class DarkTheme implements ITheme
{
    static Id = 'dark';
    id = DarkTheme.Id;

    name = 'Dark';

    colors = {
        application: '#041335',
        text: '#E0E0E0',
        confirm: '#041335',
        cancel: '#000000',
        secondary: '#62B4E4',
        primary: '#FFFFFF',
        falseswitch: '#808080',
        solved: '#808080',
        unsolved: '#0071BC',
        noMoreAttempts: '#572b34',
        disabled: '#FF4B1F',
        tag: '#62B4E4',
        activeTab: '#62B4E4',
        dialogText: '#303030',
        dialogSuccessText: '#4CAF50',
        dialogFailureText: '#F44336',
        dialogIcon: '#553A97',
        success: '#4CAF50',
        warning: '#FF9800',
        link: '#62B4E4'
    };

    fontFamily = "Blinker-Bold";
    backgroundImage = backgroundImageDarkTheme;
    isDark = true;
    isDialogDark = false;
}