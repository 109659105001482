<template>
     <confirmation-dialog v-model="showJobProgressDialog" :title="dialogTitle || $t('JOB_PROGRESS_DIALOG_DEFAULT_TITLE')" @confirm="confirm" @cancel="cancel" :disabled="disabled" :loading="isLoading" :cancel="enabledCancelText" :cancelDisabled="failed" id="jobProgressDialog">
        <template v-if="!loaded">
            <Loading class="loading" color="dialogText"/>
        </template>
        <template v-else-if="succeeded">
            <p class="text-center pt-3"><v-icon class="jobProgressDialogCheckIcon" color="success" x-large>check</v-icon></p>
            <p class="text-center pb-1">{{$t('JOB_PROGRESS_DIALOG_SUCCESS_MESSAGE')}}</p>
        </template>
        <template v-else-if="failed">
            <p class="text-center pt-3"><v-icon class="jobProgressDialogFailedIcon"  color="warning" x-large>report_problem</v-icon></p>
            <p class="text-center pb-1" style="white-space: pre-wrap;">{{$t('JOB_PROGRESS_DIALOG_FAILURE_MESSAGE', { count: job.statistics.failed })}}</p>
        </template>
        <template v-else>
            <p class="pt-3">{{$t('JOB_PROGRESS_DIALOG_PENDING_MESSAGE', { count: job.statistics.completed, total: job.statistics.total })}}</p>
            <div class="pb-4">
                <v-progress-linear
                    class="jobProgressDialogLinearProgressBar"
                    color="confirm"
                    height="5"
                    :value="(job.statistics.completed/job.statistics.total) * 100"
                    stream
                />
            </div>
        </template>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { IJob, IJobApiClient, Job, JobStatus } from '@cyber-range/cyber-range-api-job-client';
import { interval, Subscription } from 'rxjs';
import { useApiClientStore } from '@stores/apiClientStore';

const FETCH_JOB_DELAYED_IN_MS = 3000;
@Component({})
export default class JobProgressDialog extends Vue {
    @Prop(String) dialogTitle: string;
    @Prop(String) jobId: string;
    @Prop(Boolean) value: boolean;
    @Prop(Number) pollingInterval: number;
    @Prop(String) cancelText: string;

    showJobProgressDialog:boolean = false;
    job: IJob = Job.fromJson({});
    loaded:boolean = false;

    jobSubscription:Subscription;

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get jobApiClient(): IJobApiClient
    {
        return useApiClientStore().jobApiClient;
    }
    // END TODO

    get succeeded(): boolean
    {
        return this.job?.status === JobStatus.Completed && this.job.statistics.failed === 0;
    }

    get disabled(): boolean
    {
        return this.job?.status === JobStatus.Pending;
    }

    get failed(): boolean
    {
        return this.job?.status === JobStatus.Completed && this.job.statistics.failed !== 0;
    }

    get enabledCancelText():string|boolean
    {
        return this.cancelText || false;
    }

    @Watch('value', { immediate: true })
    onValueChanged()
    {
        this.showJobProgressDialog = this.value;
    }

    @Watch('jobId', { immediate: true })
    onJobIdChanged(jobId)
    {
        this.loaded = false;

        if (jobId)
        {
            this.stopPolling();
            this.startPolling();
        }
    }

    async load(): Promise<void>
    {
        if ( this.jobId )
        {
            try
            {
                this.job = await this.jobApiClient.getOne(this.jobId);
            }
            catch
            {
                this.close();
            }
            finally
            {
                if ( this.job.status !== JobStatus.Pending )
                {
                    this.stopPolling();
                }
            }

        }
    }

    async startPolling()
    {
        this.showJobProgressDialog = this.value;
        try
        {
            await this.load();
            this.loaded = !!this.job?.id;
            if ( this.job.status === JobStatus.Pending )
            {
                this.jobSubscription = interval(this.pollingInterval || FETCH_JOB_DELAYED_IN_MS).subscribe({next:this.load});
            }
        }
        catch
        {
            this.close();
        }

    }

    stopPolling()
    {
        if ( this.jobSubscription && !this.jobSubscription.closed )
        {
            this.jobSubscription.unsubscribe();
        }
    }

    beforeDestroy()
    {
        this.stopPolling();
    }

    async confirm()
    {
        this.$emit('confirm', true);
        this.close();
    }

    async cancel()
    {
        this.$emit('cancel');
        this.close();
    }

    close()
    {
        this.loaded = false;
        this.showJobProgressDialog = false;
        this.stopPolling();
        this.$emit('input', false);
    }
}
</script>