import { IPlayer } from '@cyber-range/cyber-range-api-ctf-player-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { getTimeframe } from '../../../../utils/timeframe';

export default class PlayerHistoryView
{
    get id():string
    {
        return this.player.id;
    }

    private _competition:ICompetition;
    private get competition():ICompetition
    {
        return this._competition || (this._competition = this.competitions.find(c => c.id === this.player.competitionId));
    }

    get competitionName():string
    {
        return this.competition?.name || this.player.competitionId;
    }

    get timeframe():string
    {
        return getTimeframe(this.competition?.settings?.startTime, this.competition?.settings?.endTime);
    }

    get score():number
    {
        return this.player.statistics?.score || 0;
    }

    constructor(public player:IPlayer, private competitions:ICompetition[])
    {
    }
};