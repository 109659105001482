<template>
    <v-row row wrap align="center">
        <v-col cols="12" align="center" class="mt-6">
            <v-icon :dark="isDark" :light="isLight" size="100">
                cloud_queue
            </v-icon>
        </v-col>
        <v-col cols="12" align="center">
            <p class='libraryEntryDialogSplashScreenText'>
                {{$t('LIBRARY_ENTRIES_DIALOG_SPLASH_SCREEN_TEXT')}}
            </p>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { useThemeStore } from '@/stores/themeStore';

@Component
export default class LibraryEntriesDialogSplashScreen extends Vue 
{  
    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    // END TODO
}
</script>