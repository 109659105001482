export default {
    en: {
        TITLE: "Are you sure?",
        CLOSE: "Close",
        CANCEL: "Cancel",
        CONFIRM: "Confirm",
        DONE: "Done",
        OK: "Ok"
    }
};
