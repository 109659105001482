<template>
    <confirmation-dialog v-model="showDialog" :title="dialogTitle" @confirm="confirm" @cancel="cancel" :loading="isLoading" class="addEditHintDialog">
        <loading v-if="loading" />
        <v-form v-else ref="form" :lazy-validation="true">
            <v-text-field v-model="message" :label="$t('HINT_ADD_EDIT_DIALOG_MESSAGE')" :rules="messageRules" :counter="maxMessageLength" :disabled="isLoading" class="addEditHintDialogMessage" id="addEditHintDialogMessage"/>
            <v-text-field v-model="cost" :label="$t('HINT_ADD_EDIT_DIALOG_COST')" :rules="costRules"   :disabled="isLoading" class="addEditHintDialogCost" id="addEditHintDialogCost"/>
       </v-form>
       <template #bottomLeft>
           <v-btn v-if="isEditing" :dark="isDialogDark" :light="isDialogLight" text @click="onDeleteClicked" class='deleteHintButton'>
               {{$t('HINT_ADD_EDIT_DIALOG_DELETE')}}
            </v-btn>
       </template>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { IHint, Hint, IChallengeApiClient } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import Rule from '@/validations/Rule';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';

@Component({components:{}})
export default class AddEditHintDialog extends Vue 
{  
    @Prop(String) challengeId:string;
    @Prop(Object) hint:IHint;
    @Prop(Boolean) value:boolean;

    // TODO: Change this to composition api
    get isDialogDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isDialogLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get challengeApiClient(): IChallengeApiClient
    {
        return useApiClientStore().challengeApiClient;
    }
    // END TODO

    loading:boolean = false;
    showDialog:boolean = false;
    message:string = '';
    cost:string = '';
    maxMessageLength:number = 500;
    maxCostValue:number = 100000;
    minCostValue:number = 0;

    messageRules = [Rule.require, (v)=>Rule.maxLength(v, this.maxMessageLength)];
    costRules = [Rule.require, Rule.isNumber, (v)=>Rule.maxValue(v, this.maxCostValue), (v)=>Rule.minValue(v, this.minCostValue)];

    get isEditing():boolean
    {
        return !!this.hint?.id || !!this.hint?.message || !!this.hint?.cost;
    }

    get dialogTitle()
    {
        return this.isEditing ? this.$t('HINTS_EDIT_DIALOG_TITLE') : this.$t('HINTS_ADD_DIALOG_TITLE');
    }

    @Watch('value')
    async onValueChanged(value:boolean)
    {
        this.showDialog = value;
        if(this.showDialog) await this.load();
    }

    onDeleteClicked()
    {
        this.$emit('deleted', this.hint);
        this.close();
    }

    async mounted() 
    {
        this.showDialog = this.value;
        if(this.showDialog) await this.load();
    }

    async load() 
    {
        await (<any>this.$refs.form)?.reset();

        this.message = this.hint?.message || '';
        this.cost = this.hint?.cost?.toString() || '';

        if(!this.message && this.hint?.id)
        {
            this.loading = true;
            try
            {
                let hint = await this.challengeApiClient.getChallengeHint(this.challengeId, this.hint.id);
                this.message = hint.message;
                this.cost = `${hint.cost}`;
            }
            finally
            {
                this.loading = false;
            }
        }
    }

    async confirm()
    {
        if((<any>this.$refs.form).validate() === false) return;

        this.$emit('confirm', new Hint({id: this.hint?.id, index: this.hint?.index, message: this.message, cost: Number(this.cost)}));
        this.close();
    }

    async cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showDialog = false;
        this.$emit('input', false);
    }
}
</script>