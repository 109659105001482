<script lang="ts">
import BaseLanding from './Landing.vue';
import { mixins } from 'vue-class-component';

export default class Landing extends mixins(BaseLanding) 
{   
    
}
</script>

<style lang="less">
@font-face { 
    font-family: "space_frigate";
    src: url("../../assets/themes/default/spacefr.ttf"); 
}
</style>

<style scoped>
#competitionName
{
    font-family: space_frigate !important;
}
</style>