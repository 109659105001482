<template>
    <a :href="href" @click="onClicked">
        <v-card tag="article" @click="onClicked">
            <v-card-title class="cardTitle">
                <header class="cardText name">
                    {{name}}
                </header>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="1" class="pb-1 cardIcon"><v-icon color="secondary">language</v-icon></v-col>
                    <v-col cols="11" class="pb-1 cardText subdomain">{{subdomain}}</v-col>
                </v-row>
                <v-row>
                    <v-col cols="1" class="pt-0 pb-1 cardIcon"><v-icon color="secondary">group</v-icon></v-col>
                    <v-col cols="11" class="pt-0 pb-1 cardText numberOfPlayers">{{numberOfPlayers}}</v-col>
                </v-row>
                <v-row>
                    <v-col cols="1" class="pt-0 pb-1 cardIcon"><v-icon :color="hasPassed(startTime) ? 'error' : 'secondary'">alarm_on</v-icon></v-col>
                    <v-col cols="11" class="pt-0 pb-1 cardText startTime">{{startTime | humanized}}</v-col>
                </v-row>
                <v-row>
                    <v-col cols="1" class="pt-0 pb-1 cardIcon"><v-icon :color="hasPassed(endTime) ? 'error' : 'secondary'">alarm_off</v-icon></v-col>
                    <v-col cols="11" class="pt-0 pb-1 cardText endTime">{{endTime | humanized}}</v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </a>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { Prop } from 'vue-property-decorator';
import moment from 'moment';
import { useAuthenticationStore } from '@stores/authenticationStore';

@Component
export default class Competition extends Vue 
{  
    @Prop(Object) competition:ICompetition;

    get href(): string
    {
        let url = window.location;
        let port = url.port ? `:${url.port}` : '';
        let baseDomain = location.hostname.split('.').slice(1).join('.');

        return `${url.protocol}//${this.competition?.subdomain}.${baseDomain}${port}`;
    }

    get name(): string
    {
        return this.competition?.name;
    }

    get subdomain(): string
    {
        return this.competition?.subdomain;
    }

    get numberOfPlayers(): number
    {
        return this.competition?.statistics?.numberOfPlayers || 0;
    }

    get startTime(): string
    {
        return this.competition?.settings?.startTime;
    }

    get endTime(): string
    {
        return this.competition?.settings?.endTime;
    }

    hasPassed(time:string): boolean
    {
        return time ? moment().isAfter(time) : false;
    }

    async onClicked(e)
    {
        e.preventDefault();
        e.stopPropagation()
        window.location.assign(this.href + `?s=${await useAuthenticationStore().getSsoToken()}`);
    }
}
</script>

<style scoped>
header {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
}
.cardText {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.cardIcon {
    min-width:24px;
}
</style>
