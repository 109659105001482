enum StoreAction {
    // CompetitionStore
    JoinCompetition = "joinCompetition",
    // ChallengeStore
    FetchChallenges = "fetchChallenges",
    // TeamStore
    FetchTeams = "fetchTeams",
    FetchMyTeam = "fetchMyTeam",
    // SubmissionStore
    FetchSubmissions = "fetchSubmissions",
    GetTeamChallengeSubmissionStatistics = "getTeamChallengeSubmissionStatistics",
    ExportSubmissions = "exportSubmissions",
    FetchMyTeamsChallengesStatistics = "FetchMyTeamsChallengesAttempts",
    // PlayerStore
    FetchPlayers = "fetchPlayers",
    FetchCurrentPlayer = "fetchCurrentPlayer",
    FetchMyTeamPlayers = "fetchMyTeamPlayers",
    // AlertStore
    FetchAlerts = "fetchAlerts",
}

export default StoreAction;
