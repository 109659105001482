<script lang="ts">
import BaseLanding from './Landing.vue';
import { mixins } from 'vue-class-component';

export default class Landing extends mixins(BaseLanding) {}
</script>

<style scoped>
* {
    font-family: ShareTechMono !important;
}
</style>
