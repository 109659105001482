<template>
    <delete-confirmation-dialog v-model="showConfirmationDialog" @confirm="confirm" @cancel="cancel" :disabled="isLoading" :checkbox="confirmText" id="teamKickDialog">
        <span v-html="$t('TEAMS_KICK_DIALOG_MESSAGE', {name:this.name})" />
    </delete-confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { IPlayer } from '@cyber-range/cyber-range-api-ctf-player-client';
import { encode } from 'html-entities';
import { useApiClientStore } from '@stores/apiClientStore';

@Component({components:{}})
export default class KickTeamMemberDialog extends Vue 
{
    @Prop(Boolean) lastPlayer:boolean;
    @Prop(Boolean) value: boolean;
    @Prop(Object) player:IPlayer;

    showConfirmationDialog:boolean = false;

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    // END TODO
    
    get confirmText()
    {
        return this.lastPlayer ? this.$t('TEAMS_KICK_TEAM_MEMBER_DIALOG_MESSAGE') : '';
    }

    @Watch('value')
    onValueChanged()
    {
        this.showConfirmationDialog = this.value;
    }

    mounted() 
    {
        this.showConfirmationDialog = this.value;
    }

    get name():string
    {
        return encode(this.player?.name || '');
    }
    
    async confirm()
    {
        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.$emit('input', false);
    }
}
</script>