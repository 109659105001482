import ITeamScoreHistory from '@interfaces/iTeamScoreHistory';

export default class TeamScoreHistory implements ITeamScoreHistory
{
    teamId: string;
    score: number;
    timestamp: string;

    constructor(history?:Partial<ITeamScoreHistory>)
    {
        this.teamId = history?.teamId;
        this.score = history?.score;
        this.timestamp = history?.timestamp;
    }
}