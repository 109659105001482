<template>
  <div class="countdownText">
    <span v-if="days > 0" class="digit days">{{ days }} {{ daysUnit }}</span>
    <span v-if="days > 0 || hours > 0" class="digit hours"> {{ hours }} {{ hoursUnit }}</span>
    <span v-if="days > 0 || hours > 0 || minutes > 0" class="digit minutes">{{ minutes }} {{ minutesUnit}}</span>
    <span v-if="days > 0 || hours > 0 || minutes > 0 || seconds > 0" class="digit seconds"> {{ seconds }} {{ secondsUnit }}</span>
  </div>
</template>


<script lang="ts">
import Vue from "vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import { mixins } from 'vue-class-component';
import Countdown from './Countdown.vue';

@Component({})
export default class CountDownClock extends mixins(Countdown) {
}
</script>