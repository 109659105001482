import { IClaim } from "@cyber-range/cyber-range-api-entitlement-client";
import { useApiClientStore } from "./apiClientStore";
import { defineStore } from 'pinia';

function getClaimId(type, value, scope)
{
    return `${scope || ''}::${type || ''}::${value || ''}`;
}

export const useEntitlementStore = defineStore('entitlementStore', {
    state: () =>
    ({
        claims: {},
        claimFetched: false
    }),
    getters:
    {
        claimsFetched: (state): boolean => state.claimFetched,
        
    },
    actions:
    {
        hasClaim(type:string, valueOrValues:string|string[], scopeOrScopes:string|string[]):boolean
        {
            let values = Array.isArray(valueOrValues) ? [...new Set(valueOrValues)] : [valueOrValues];
            let scopes = Array.isArray(scopeOrScopes) ? [...new Set(scopeOrScopes)] : [scopeOrScopes];

            for(let value of values)
            {
                for(let scope of scopes)
                {
                    let key = getClaimId(type, value, scope);
                    if(key in this.claims)
                    {
                        return true;
                    }
                }
            }

            return false;
        },
        getClaims(type:string, valueOrValues:string|string[], scopeOrScopes:string|string[]):IClaim[]
        {
            let result = [];
            let table = {};
            let values = valueOrValues === undefined ? Object.values(this.claims).map((claim:IClaim) => claim.value):
                                            (Array.isArray(valueOrValues)? 
                                            [...new Set(valueOrValues)] : [valueOrValues]);
            let scopes = Array.isArray(scopeOrScopes) ? [...new Set(scopeOrScopes)] : [scopeOrScopes];
            
                for(let value of values)
                {
                    for(let scope of scopes)
                    {
                        let key = getClaimId(type, value, scope);
                        if(key in this.claims && !(key in table))
                        {
                            result.push(this.claims[key]);
                            table[key] = true;
                        }
                    }
                }

            return result;
        },
        getClaim(type:string):IClaim
        {
            for(const key of Object.keys(this.claims)){
                if(this.claims[key].type === type){
                    return this.claims[key];
                }
            }
            return undefined;
        },
        setClaims(claims:IClaim[]) 
        {
            this.claims = {};

            for(let claim of claims)
            {
                for(let scope of claim.scopes)
                {
                    this.claims[getClaimId(claim.type, claim.value, scope)] = claim;
                }
            }
            this.claimFetched = true;
        },
        async fetchClaims()
        {
            let claims:IClaim[] = await useApiClientStore().entitlementApiClient.listClaims();
            await this.setClaims(claims);
        },
        clearClaims()
        {
            this.setClaims([]);
        }
    }
});