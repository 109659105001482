import { IPlayer, Player } from '@cyber-range/cyber-range-api-ctf-player-client';
import { IInvitation } from '@cyber-range/cyber-range-api-invitation-client';
import { UserRole } from '@cyber-range/cyber-range-api-user-client';

export default class PlayerView
{
    get name():string
    {
        return this.player?.name;
    }

    get email():string
    {
        return this.player?.email;
    }

    get affiliation():string
    {
        return this.player?.affiliation;
    }

    get isInvitation():boolean
    {
        return !!this.invitation;
    }

    get isPlayer():boolean
    {
        return !this.invitation;
    }

    get hasPlayerRecord(): boolean
    {
        return !!this.player?.id;
    }

    invitation:IInvitation;
    isAdmin:boolean;
    teamName:string;
    tableKey: string;

    get team():string
    {
        return this.player?.teamId;
    }

    get stars():number
    {
        return (this.player?.statistics?.successAttempts > 0 || this.player?.statistics?.failedAttempts > 0) 
                ? 
                (this.player?.statistics.successAttempts / (this.player?.statistics.successAttempts + this.player.statistics.failedAttempts)) * 5  //5 stars
                :
                0;
    }
    
    get score():number
    {
        return this.player?.statistics?.score || 0;
    }

    constructor(public player:IPlayer)
    {
        this.tableKey = player?.id;
    }

    static fromInvitation(invitation:IInvitation): PlayerView
    {
        let player = new Player({name: invitation.name, email: invitation.email});

        let view = new PlayerView(player);
        view.teamName = '';
        view.isAdmin = invitation.roles?.includes(UserRole.CtfAdmin) || false;
        view.tableKey = invitation.id;
        view.invitation = invitation;
        return view;
    }
};
