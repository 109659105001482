<template>
    <confirmation-dialog v-model="showDialog" :title="$t('CHALLENGE_TEAM_REQUIRED_DIALOG_TITLE')" :confirm="$t('CHALLENGE_TEAM_REQUIRED_DIALOG_CONFIRM')" @confirm="confirm" :cancel="false" :loading="isLoading" class="teamRequiredDialog">
        {{$t('CHALLENGE_TEAM_REQUIRED_DIALOG_MESSAGE', {role: roles})}}
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { IClaim } from '@cyber-range/cyber-range-api-entitlement-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import * as Claims from '@cyber-range/cyber-range-shared-claims';
import { toDisplayRoles } from '@/entities/filters/definitions/toDisplayRoles';
import { useApiClientStore } from '@stores/apiClientStore';
import { useEntitlementStore } from '@stores/entitlementStore';
import { useCompetitionStore } from '@stores/competitionStore';

@Component
export default class TeamRequiredDialog extends Vue 
{  
    @Prop(Boolean) value:boolean;
    
    showDialog:boolean = false;

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO
    
    get roles() {
        let roleClaims: IClaim[] = useEntitlementStore().getClaims("role", undefined, [
            this.competition.id,
            this.competition.organizationId,
            Claims.Scope.UNDEFINED,
            Claims.Scope.ANY
        ]);

        return toDisplayRoles(roleClaims
            .filter(
                claim =>
                    claim.value !== "user" &&
                    (claim.scopes.includes(this.competition.id) ||
                        claim.scopes.includes(this.competition.organizationId) ||
                        claim.scopes.includes(Claims.Scope.ANY))
            )
            .map(claim => claim.value))
    }

    @Watch('value')
    async onValueChanged(value:boolean)
    {
        if(value) await this.load();
    }

    async mounted() 
    {
        this.showDialog = this.value; 

        if(this.showDialog) await this.load();
    }

    async load() 
    {
        this.showDialog = true;
    }
    
    confirm()
    {
        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showDialog = false;
        this.$emit('input', false);
    }
}
</script>