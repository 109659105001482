<template>
    <confirmation-dialog v-model="showConfirmationDialog" :title="$t('TEAMS_ADD_DIALOG_TITLE')" @confirm="confirm" @cancel="cancel" :loading="isLoading" id="teamAddDialog">
        <v-form v-model="valid" ref="form">
            <v-text-field v-model="name" :label="$t('TEAMS_ADD_DIALOG_NAME')" :rules="nameRules" :counter="maxNameLength" :disabled="isLoading" class="teamAddDialogName" id="teamAddDialogName"/>
        </v-form>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { Prop, Watch } from 'vue-property-decorator';
import Rule from '@/validations/Rule';
import { ITeamApiClient, ITeam } from '@cyber-range/cyber-range-api-ctf-team-client';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';

@Component({components:{}})
export default class AddTeamDialog extends Vue 
{  
    @Prop(String) defaultTeamName;

    @Prop(Boolean) value: boolean;

    valid = false;
    showConfirmationDialog:boolean = false;
    maxNameLength:number = 100;
    nameRules = [Rule.require, (v)=>Rule.maxLength(v, this.maxNameLength)];

    name:string = '';

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get teamApiClient(): ITeamApiClient
    {
        return useApiClientStore().teamApiClient;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO

    mounted() 
    {
        this.load()
    }

    @Watch('value')
    onValueChanged(value:boolean)
    {
        this.load()
    }

    load()
    {
        this.name = this.defaultTeamName || '';
        this.showConfirmationDialog = this.value;
    }

    async confirm()
    {
        (<any>this.$refs.form).validate();

        if(!this.valid) return;
        
        await this.teamApiClient.add({competitionId: this.competition.id, name: this.name});

        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.$emit('input', false);
    }
}
</script>