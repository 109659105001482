<template>
    <confirmation-dialog v-model="showDialog" :title="dialogTitle" @confirm="confirm" @cancel="cancel" :loading="isLoading" class="addEditArtifactDialog">
        <v-form ref="form" :lazy-validation="true">
            <sorted-select :dark="false" :light="true" v-model="artifactType" :items="artifactTypes" :label="$t('ARTIFACTS_ADD_EDIT_DIALOG_TYPE')" :rules="artifactTypeRules" :disabled="isLoading" class="addEditArtifactDialogType" id="addEditArtifactDialogType"/>
            <template v-if="artifactType === 'file'">
                <v-text-field v-model="artifactName" :label="$t('ARTIFACTS_ADD_EDIT_DIALOG_NAME')" :rules="artifactNameRules" :counter="maxArtifactNameLength" :disabled="isLoading" class="addEditArtifactDialogName" id="addEditArtifactDialogName"/>
                <v-file-input :label="fileLabel" @change="onFileSelected" :rules="fileRules" show-size :dark="isDialogDark" :light="isDialogLight" class="addEditArtifactDialogFile" />
            </template>
            <template v-else-if="artifactType === ArtifactType.Environment">
                <artifact-environment-selector v-model="artifactValue" />
                <v-text-field v-model="artifactName" :label="$t('ARTIFACTS_ADD_EDIT_DIALOG_NAME')" :rules="artifactNameRules" :counter="maxArtifactNameLength" :disabled="isLoading" class="addEditArtifactDialogName" id="addEditArtifactDialogName"/>
            </template>
            <template v-else>
                <v-text-field v-model="artifactName" :label="$t('ARTIFACTS_ADD_EDIT_DIALOG_NAME')" :rules="artifactNameRules" :counter="maxArtifactNameLength" :disabled="isLoading" class="addEditArtifactDialogName" id="addEditArtifactDialogName"/>
                <v-text-field v-model="artifactValue" :label="$t('ARTIFACTS_ADD_EDIT_DIALOG_LINK_VALUE')" :rules="artifactValueRules" :counter="maxArtifactValueLength" :disabled="isLoading" class="addEditArtifactDialogValue" id="addEditArtifactDialogValue"/>
            </template>
       </v-form>
       <template #bottomLeft>
           <v-btn v-if="artifact" :light="isDialogLight" text @click="onDeleteClicked" class='deleteArtifactButton'>{{$t('ARTIFACTS_ADD_EDIT_DIALOG_DELETE')}}</v-btn>
       </template>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Rule from '@/validations/Rule';
import { IChallengeArtifact, ChallengeArtifact, ChallengeArtifactType } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@/stores/authorizationStore';
import { useSubscriptionStore } from '@/stores/subscriptionStore';
import ArtifactEnvironmentSelector from './ArtifactEnvironmentSelector.vue';
import { ArtifactType } from '@cyber-range/cyber-range-api-ctf-library-client';

@Component({components:{ ArtifactEnvironmentSelector }})
export default class AddEditArtifactDialog extends Vue 
{  
    @Prop(Object) artifact:IChallengeArtifact;
    @Prop(Boolean) value:boolean;

    // TODO: Change this to composition api
    get isDialogDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isDialogLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO

    file:any = undefined;
    showDialog:boolean = false;
    artifactType:string = '';
    artifactName:string = '';
    artifactValue:string = '';
    maxArtifactNameLength:number = 256;
    maxArtifactValueLength:number = 256;

    artifactTypeRules = [Rule.selectRequire];
    artifactNameRules = [Rule.require, (v)=>Rule.maxLength(v, this.maxArtifactNameLength)];
    artifactValueRules = [Rule.require, (v)=>Rule.maxLength(v, this.maxArtifactValueLength)];
    
    get fileRules() { 
        return [
            (v) => v?.size ? 
            Rule.maxSizeInMB(v, this.competition?.limits?.maxFileStorageInMb || 0) : 
            true
        ];
    }

    get isEditing(): boolean
    {
        return this.artifact?.name !== undefined;
    }

    get fileLabel()
    {
        return this.isEditing ? this.$t('ARTIFACTS_EDIT_DIALOG_FILE_EDIT') : this.$t('ARTIFACTS_EDIT_DIALOG_FILE_ADD');
    }

    get dialogTitle()
    {
        return this.isEditing ? this.$t('ARTIFACTS_EDIT_DIALOG_TITLE') : this.$t('ARTIFACTS_ADD_DIALOG_TITLE');
    }
    

    ArtifactType = ArtifactType;
    get artifactTypes(): {text:string, value:ArtifactType}[]
    {
        const types = [ { text: "Link", value: ArtifactType.Link}, {text: "File", value: ArtifactType.File}];
        if (this.canCreateEnvironmentArtifact)
        {
            types.push({ text: 'Environment', value: ArtifactType.Environment });
        }
        return types;
    }

    get canCreateEnvironmentArtifact(): boolean
    {
        const { id: competitionId, organizationId } = this.competition;
        const hasClaim = useAuthorizationStore().canCreateEnvironmentArtifact(competitionId, organizationId );
        const hasSubscription = useSubscriptionStore().canAllowIntegratedEnvironment;
        return hasClaim && hasSubscription;
    }

    @Watch('value')
    async onValueChanged(value:boolean)
    {
        this.showDialog = value;
        if(this.showDialog) await this.load();
    }

    onDeleteClicked()
    {
        this.$emit('deleted', this.artifact);
        this.close();
    }

    onFileSelected(file)
    {
        this.file = file;
    }

    async mounted() 
    {
        this.showDialog = this.value;
        if(this.showDialog) await this.load();
    }

    async load() 
    {
        await (<any>this.$refs.form)?.reset();

        this.artifactType = this.artifact?.type || ChallengeArtifactType.Link;
        this.artifactName = this.artifact?.name || '';
        this.artifactValue = this.artifact?.value || '';
        this.file = {};

        await useSubscriptionStore().fetchSubscribedProducts();
    }

    async confirm()
    {
        if((<any>this.$refs.form).validate() === false) return;

        if(this.artifactType === ChallengeArtifactType.Link || this.artifactType === ChallengeArtifactType.Environment)
        {
            this.$emit('confirm', new ChallengeArtifact({name: this.artifactName, value: this.artifactValue, type: <ChallengeArtifactType>this.artifactType}));
        }
        else
        {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.$emit('confirm', {name: this.artifactName, file: this.file, blob: this.file, type: <ChallengeArtifactType>this.artifactType});
            }
            reader.readAsArrayBuffer(this.file);
        }
        this.close();
    }

    async cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showDialog = false;
        this.$emit('input', false);
    }
}
</script>
<style>
.addEditArtifactDialogFile .v-icon.primary--text
{
    color: unset !important;
}
</style>