<template>
  <span v-if="show">
    <v-icon v-if="showIcon !== false" color="error" class="pr-1">error</v-icon>
    <span class="error--text text--darken-1">{{message}}</span>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class ErrorText extends Vue {
	@Prop(Boolean) show: boolean;
	@Prop(Boolean) showIcon: boolean;
	@Prop(String) message: string;
}
</script>


<style scoped>
.v-icon {
	vertical-align: middle;
}
</style>