import ITheme from '@/interfaces/iTheme';

export class SimpleTheme implements ITheme
{
    static Id = 'simple';
    id = SimpleTheme.Id;

    name = 'Simple';

    colors = {
                application: '#FFFFFF',
                text: '#000000',
                confirm: '#1976D2',
                accent: '#1976D2',
                falseswitch: '#F5F5F5',
                solved: '#EEEEEE',
                unsolved: '#B3E5FC',
                noMoreAttempts: '#FBA6A6',
                disabled: '#C62828',
                activeTab: '#1976D2',
                dialogText: '#303030',
                dialogSuccessText: '#4CAF50',
                dialogFailureText: '#F44336',
                dialogIcon: '#1976D2',
                success: '#4CAF50',
                warning: '#FF9800',
                link: '#1976D2'
             };

    fontFamily = "Roboto";
    isDark = false;
    isDialogDark = false;
    backgroundImage = undefined;
}