<template>
    <confirmation-dialog v-model="showDialog" :title="title" :confirm="$t('CHALLENGE_DIALOG_SUBMISSION_RESULT_CONFIRM')" @confirm="confirm" :cancel="false" :loading="isLoading" class="submissionResultDialog">
        <p v-if="submissionResult.correct" v-html="$t('CHALLENGE_DIALOG_SUBMISSION_RESULT_CORRECT_MESSAGE', {points: submissionResult.points})" />
        <p v-else>
            {{$t('CHALLENGE_DIALOG_SUBMISSION_RESULT_INCORRECT_MESSAGE')}}
        </p>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ISubmissionResult } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { useApiClientStore } from '@stores/apiClientStore';

@Component({components:{}})
export default class SubmissionResultDialog extends Vue 
{  
    @Prop(Object) submissionResult:ISubmissionResult;
    @Prop(Boolean) value:boolean;
    
    showDialog = false;

    get title()
    {
        return this.submissionResult.correct ? this.$t('CHALLENGE_DIALOG_SUBMISSION_RESULT_CORRECT_TITLE') : this.$t('CHALLENGE_DIALOG_SUBMISSION_RESULT_INCORRECT_TITLE')
    }

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    // END TODO
    
    @Watch('value')
    async onValueChanged(value:boolean)
    {
        if(value) this.showDialog = value;
    }

    async mounted() 
    {
        this.showDialog = this.value; 
    }
    
    async confirm()
    {
        this.$emit('confirm', true);
        this.close();
    }

    close()
    {
        this.showDialog = false;
        this.$emit('input', false);
    }
}
</script>