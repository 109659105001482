
<script lang="ts">
import { mixins } from "vue-class-component";
import BaseConfirmationDialog from './ConfirmationDialog.vue';

export default class ConfirmationDialog extends mixins(BaseConfirmationDialog)
{
}
</script>

<style scoped>
.default .confirmationDialog{
    border: 1px solid var(--v-text-base);
    background-color: white;
    color: rgba(0, 0, 0, 0.87);
}
.default .confirmationDialog .headline{
    background-color: var(--v-application-base);
    color:white;
}
.default .confirmationDialog .body{
    margin-top: 16px;
}
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined)
{
    background-color: rgba(0, 0, 0, 0.5)  !important;
    color: rgba(256, 256, 256, 0.3) !important;
}
.theme--dark.v-btn.v-btn--disabled
{
    color: rgba(0, 0, 0, 0.6)  !important;
}
</style>


<style>
.theme--dark .confirmationDialog .v-input,
.theme--dark .confirmationDialog .v-input input, 
.theme--dark .confirmationDialog .v-input textarea,
.confirmationDialog input,
.confirmationDialog .v-label:not(.error--text)
{
    color: rgba(0, 0, 0, 0.7) !important;
    caret-color: rgba(0, 0, 0, 0.7) !important;
}
.confirmationDialog .v-input__control .v-label,
.confirmationDialog .v-text-field > .v-input__control > .v-input__slot:before 
{
    border-color: rgba(0, 0, 0, 0.4) !important;
}
.theme--dark .confirmationDialog a
{
    color: var(--v-application-base);
}
</style>