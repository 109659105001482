<template>
    <v-radio-group v-model="flag" :rules="rules">
        <v-radio
            v-for="choice in challenge.choice.values"
            :key="choice.id"
            :label="choice.text"
            :value="choice.id"
            color="blue"
        />
    </v-radio-group>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, VModel } from 'vue-property-decorator';
import { IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import Rule from '@/validations/Rule';

@Component
export default class MultipleChoiceChallengeFlagInput extends Vue 
{
    @VModel({ type: String, required: true }) flag: string;
    @Prop(Object) challenge: IChallenge
    @Prop(Boolean) disabled: boolean;

    rules = [Rule.require]
}
</script>
