export default {
    en: {
        PLAYERS_TITLE: 'Players',
        PLAYERS_ACTIONS_LABEL: '{name} Player Actions',
        PLAYERS_TOGGLE_SELECT_ALL_LABEL: 'All players',
        PLAYERS_SELECT_LABEL: 'Player: {name}',
        PLAYERS_EXPORT_LABEL: 'Export Table as .csv',
        PLAYERS_VIEW_HISTORY: 'View History',
        PLAYERS_EDIT: 'Edit',
        PLAYERS_REINVITE: 'Reinvite',
        PLAYERS_DELETE: 'Delete',
        PLAYERS_LOADING: 'Loading...',
        PLAYERS_NO_DATA: 'This CTF has no users.',
        PLAYERS_HEADER_USER: 'User',
        PLAYERS_INVITATION: 'Waiting for the user to accept an invitation to join this competition.',
        PLAYERS_IS_ADMIN_TEXT: 'yes',
        PLAYERS_HEADER_EMAIL: 'Email',
        PLAYERS_HEADER_AFFILIATION: 'Affiliation',
        PLAYERS_HEADER_ADMIN: 'CTF Admin',
        PLAYERS_HEADER_TEAM: 'Team',
        PLAYERS_HEADER_CORRECT_FLAG_SUBMISSION: 'Correct Flag Submissions',
        PLAYERS_HEADER_SCORE: 'Score',
        PLAYERS_DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete <b>{name}</b> from this competition?',
        PLAYERS_DELETE_PLAYERS_DIALOG_MESSAGE: 'Are you sure you want to delete {count} players?',
        PLAYERS_DELETE_DIALOG_CONFIRM: 'Delete',
        PLAYERS_DELETE_JOB_PROGESS_DIALOG_TITLE: 'Deleting Players',
        PLAYERS_EDIT_DIALOG_TITLE: 'Edit a Player',
        PLAYERS_EDIT_DIALOG_NAME: 'Name',
        PLAYERS_EDIT_DIALOG_EMAIL: 'Email',
        PLAYERS_EDIT_DIALOG_AFFLIATION: 'Affiliation',
        PLAYERS_EDIT_DIALOG_TEAM: 'Team',
        PLAYERS_EDIT_DIALOG_TEAM_IS_FULL: "{teamName} (full)",
        PLAYERS_HISTORY_DIALOG_TITLE: '{name} History',
        PLAYERS_HISTORY_DIALOG_CONFIRM: 'OK',
        PLAYERS_HISTORY_POINTS: 'Pts',
        PLAYERS_PROMOTE: 'Promote',
        PLAYERS_PROMOTE_DIALOG_TITLE: 'Promote a Player',
        PLAYERS_PROMOTE_DIALOG_MESSAGE: 'Are you sure you want to promote <b>{name}</b> to an admin?',
        PLAYERS_DEMOTE: 'Demote',
        PLAYERS_DEMOTE_DIALOG_TITLE: 'Demote an Admin',
        PLAYERS_DEMOTE_DIALOG_MESSAGE: 'Are you sure you want to demote <b>{name}</b> to a player?',
        PLAYERS_ADD_TOOLTIP: 'Invite a Player',
        PLAYERS_INVITATION_EDIT_DIALOG_TITLE_ADD: 'Invite a Player',
        PLAYERS_INVITATION_EDIT_DIALOG_TITLE_EDIT: 'Edit a Player',
        PLAYERS_INVITATION_EDIT_DIALOG_NAME: 'Name',
        PLAYERS_INVITATION_EDIT_DIALOG_EMAIL: 'Email',
        PLAYERS_INVITATION_EDIT_DIALOG_ROLE: 'Role',
    }
};
