export default {
    en: {
        FLAG_TEXT_FLAG: 'Text flag',
        FLAGS_ADD_FLAG: 'Add a Flag',
        FLAG_MULTIPLE_CHOICE_FLAG: 'Multiple choice flags',
        FLAGS_ADD_CHOICE: 'Add a Choice',
        FLAGS_TYPE: 'Type',
        FLAGS_VALUE: 'Flag',
        FLAGS_MAX_CHOICES_TOOLTIP: 'Max number of choices ({count}) reached.',
        FLAGS_MIN_CHOICES_ERROR: 'Error: At least {count} choices are required',
        FLAGS_CHOICE_REQUIRED_ERROR: 'Error: A correct choice is required.'
    }
};
