import ITheme from '@/interfaces/iTheme';
// import backgroundImageComicBookTheme from '@assets/themes/comicBook/comicBook.svg';
import backgroundImageComicBookTheme from '@assets/themes/comicBook/comicBookNoBorder.svg';

export class ComicBookTheme implements ITheme
{
    static Id = 'comicbook';
    id = ComicBookTheme.Id;

    name = 'Comic Book';

    colors = {
        application: '#553A97', //#051c29
        text: '#FFFFFF',
        confirm: '#ffcc00',
        cancel: '#000000',
        secondary: '#111111',
        primary: '#FFFFFF',
        falseswitch: '#808080',
        solved: '#595959',
        unsolved: '#ffcc00',
        unsolvedText: '#000000',
        disabled: '#8B0000',
        tag: '#62B4E4',
        activeTab: '#62B4E4',
        dialogText: '#303030',
        dialogSuccessText: '#4CAF50',
        dialogFailureText: '#F44336',
        dialogIcon: '#553A97',
        success: '#4CAF50',
        warning: '#FF9800',
        link: '#62B4E4'
     };

    fontFamily = "Bangers-Regular";
    letterSpacing = "0.09em";
    isDark = true;
    isDialogDark = false;
    backgroundImage = backgroundImageComicBookTheme;
}