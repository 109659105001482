<template>
    <confirmation-dialog v-model="showConfirmationDialog" :title="title" @confirm="confirm">
        <template v-for="data,key in localFilters">
            <sorted-select
                v-if="data.type === 'select'"
                :key="key"
                :label="data.label"
                v-model="data.value"
                :items="data.items.map(i => ({text: i, value: i}))"
                multiple
                clearable
                />
            <v-text-field
                v-else-if="data.type === 'text'"
                :key="key"
                :label="data.label"
                v-model="data.value"
                />
            <v-checkbox
                color="secondary"
                v-else-if="data.type === 'checkbox'"
                :key="key"
                :label="data.label"
                v-model="data.value"
                />
        </template>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop,VModel, Watch } from 'vue-property-decorator';
import { IFilterRecord } from './iFilterRecord';

@Component({components:{}})
export default class FilterDialog extends Vue 
{
    @VModel({ type: Boolean }) showConfirmationDialog;
    @Prop(String) readonly title: string;
    @Prop(Object) readonly filters: Readonly<IFilterRecord>;

    localFilters: IFilterRecord = {};

    @Watch('filters', { immediate: true, deep: true })
    onFiltersChanged()
    {
        this.localFilters = JSON.parse(JSON.stringify(this.filters));
    }

    confirm()
    {
        this.$emit('update:filters', this.localFilters)
        this.showConfirmationDialog = false;
    }
}
</script>
