export default {
    en: {
        TITLE_CHALLENGES: 'Challenges',
        TITLE_COMPETITIONS: 'Competitions',
        TITLE_SCOREBOARD: 'Scoreboard',
        TITLE_MANAGE_CHALLENGES: 'Challenges Admin',
        TITLE_MANAGE_LIBRARY: 'Challenge Library',
        TITLE_MANAGE_PLAYERS: 'Players Admin',
        TITLE_MANAGE_SETTINGS: 'Competition Settings',
        TITLE_MANAGE_TEAMS: 'Teams Admin',
        TITLE_TEAM: 'Team',
        TITLE_WELCOME: 'Welcome'
    }
};
