<template>
    <confirmation-dialog v-model="showDialog" :title="$t('TEAM_SUBMISSION_CORRECTION_DIALOG_TITLE')" @confirm="confirm" @cancel="cancel" :loading="isLoading">
        <v-col cols="12" v-if="fetchingValues">
            <loading />
        </v-col>
        <v-form v-else ref="form">
            <p>{{ $t('TEAM_SUBMISSION_CORRECTION_DIALOG_MESSAEG') }}</p>
            <sorted-select v-model="challengeId" :items="filteredChallenges" item-text="name" item-value="id" label="Challenge" :rules="challengeRules" :no-data-text="$t('TEAM_SUBMISSION_CORRECTION_DIALOG_NO_CHALLENGES_TEXT')"/>
            <sorted-select v-model="selectedPlayerId" :items="players" item-text="name" item-value="id" label="Solver" :rules="solverRules" :no-data-text="$t('TEAM_SUBMISSION_CORRECTION_DIALOG_NO_PLAYERS_TEXT')"/>
        </v-form>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ITeam } from '@cyber-range/cyber-range-api-ctf-team-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { IChallengeApiClient, IChallenge, ChallengeFilter } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { ISubmission, ISubmissionApiClient, SubmissionFilter, SubmissionStatus } from '@cyber-range/cyber-range-api-ctf-submission-client';
import { IPlayer, IPlayerApiClient, PlayerFilter } from '@cyber-range/cyber-range-api-ctf-player-client';
import Rule from '@/validations/Rule';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';

@Component({components:{}})
export default class SubmissionCorrectionDialog extends Vue 
{  
    @Prop(Boolean) value:boolean;
    @Prop(Object) team:ITeam;

    fetchingValues:boolean = true;
    challenges:IChallenge[] = [];
    solvedChallengeIds:string[] = [];
    players:IPlayer[] = [];

    challengeRules = [Rule.require];
    solverRules = [Rule.require];

    challengeId:string = '';

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get challengeApiClient(): IChallengeApiClient
    {
        return useApiClientStore().challengeApiClient;
    }
    get submissionApiClient(): ISubmissionApiClient
    {
        return useApiClientStore().submissionApiClient;
    }
    get playerApiClient(): IPlayerApiClient
    {
        return useApiClientStore().playerApiClient;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO
    

    get sortedPlayers()
    {
        return this.players?.sort((playerA, playerB) => playerA.name.localeCompare(playerB.name, undefined, { })) || [];
    }

    _selectedPlayerId:string = '';
    get selectedPlayerId()
    {
        return this._selectedPlayerId || this.players.find(player => player.userId === this.team.leaderUserId)?.id;
    }
    set selectedPlayerId(id:string)
    {
        this._selectedPlayerId = id;
    }

    get filteredChallenges()
    {
        return this.challenges?.filter(challenge => !this.solvedChallengeIds.includes(challenge.id)) || [];
    }

    get showDialog(): boolean
    {
        return this.value;
    }
    set showDialog(value)
    {
        this.$emit('input', value);
    }

    @Watch('value', { immediate: true })
    async onValueChanged(value:boolean)
    {
        if(value) await this.load();
    }

    async load() 
    {
        this.fetchingValues = true;
        try
        {
            await Promise.all([this.fetchSolvedChallengeIds(), this.fetchChallenges(), this.fetchPlayers()]);
        }
        catch
        {
            this.close();
        }
        finally
        {
            this.fetchingValues = false;
        }
    }

    async fetchChallenges()
    {
        const filter = new ChallengeFilter({ competitionId: this.competition.id });
        const items:IChallenge[] = [];
        do
        {
            const response = await this.challengeApiClient.get(filter);
            items.push(...response.items);
            filter.token = response.nextPageToken;
        }
        while (filter.token);

        this.challenges = items;
    }

    async fetchSolvedChallengeIds()
    {
        const filter = new SubmissionFilter({ competitionId: this.competition.id, teamId: this.team.id, scoreChange: true });
        const items:ISubmission[] = [];
        do
        {
            const response = await this.submissionApiClient.get(filter);
            items.push(...response.items);
            filter.token = response.nextPageToken;
        }
        while (filter.token);

        this.solvedChallengeIds = items.reduce((challengeIds, submission) => {
            if (submission.status === SubmissionStatus.Correct)
            {
                challengeIds.push(submission.challengeId);
            }
            return challengeIds;
        }, <string[]>[]);
    }


    async fetchPlayers()
    {
        const filter = new PlayerFilter({ competitionId: this.competition.id, teamId: this.team.id });
        const items:IPlayer[] = [];
        do
        {
            const response = await this.playerApiClient.get(filter);
            items.push(...response.items);
            filter.token = response.nextPageToken;
        }
        while (filter.token);

        this.players = items;
    }
    
    async confirm()
    {
        if((<any>this.$refs.form).validate() === false) return;

        await this.challengeApiClient.solveChallengeForTeamPlayer(this.challengeId, this.team.id, this.selectedPlayerId);

        this.$emit('confirm', true);
        this.close();
    }

    async cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showDialog = false;
        this.$emit('input', false);
    }
}
</script>
