import { defineStore } from 'pinia';

export const useSplashScreenStore = defineStore('splashScreenStore', 
{
    state: () =>
    ({
        //On initial loading, always show the splash screen.
        showSplashScreen: true
    }),
    actions:
    {
        hideSplashScreen(): void
        {
            //We want to show the splash screen once on the initial load.
            //So, once the splash screen is hidden, it is always hidden.
            this.showSplashScreen = false;
        }
    }
});