enum StoreGetter {
    //Player
    GetMyTeamPlayers = "getMyTeamPlayers",
    IsMyTeamPlayersFetched = "isMyTeamPlayersFetched",
    //Challenge
    GetChallenges = "getChallenges",
    IsChallengesFetched = 'isChallengesFetched',
    GetChallengeCategories = 'getChallengeCategories',
    //TeamStore
    GetTeams = "getTeams",
    GetTeam = "getTeam",
    GetMyTeam = "getMyTeam",
    IsTeamsFetched = "isTeamsFetched",
    IsTeamsFetching = "isTeamsFetching",
    //SubmissionStore
    GetSubmissions = "getSubmissions",
    IsSubmissionFetching = "isSubmissionFetching",
    IsFetchedSubmission = "isFetchedSubmission",
    IsSolved = "isSolved",
    IsSubmissionFetched = "isSubmissionFetched",
    GetTeamSubmissions = "getTeamSubmissions",
    GetLatestSubmissionTimestamp = "getLatestSubmissionTimestamp",
    GetLatestCorrectionTimestamp = 'getLatestCorrectionTimestamp',
    MyTeamsChallengesStatistics = "MyTeamsChallengesStatistics",
    //Player
    GetCurrentPlayer = "getCurrentPlayer",
    //Alert
    GetAlerts = "getAlerts",
}

export default StoreGetter;
