import Vue from 'vue';
import { basename, extname } from 'path';

export function initializeAllGlobalFilters() 
{
    // Dynamically search for filter files
    let filterFiles = import.meta.glob('./definitions/**/*.ts', {eager: true});

    for (const filename in filterFiles) 
    {
        let filter = filterFiles[filename].default;		
        Vue.filter(basename(filename, extname(filename)), filter);
    }
}
