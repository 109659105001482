<template>
    <div>
        <h1>{{$t('COMPETITIONS')}}</h1>
   
        <v-row class="mt-1">
            <v-col cols="12" class="loadingRow">
                <loading  v-show="isLoading"/>
            </v-col>
            <v-col cols="12" md="6" lg="4" v-for="competition in competitions"  :key="competition.id">
                <competition :competition="competition" />
            </v-col>
        </v-row>

    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ICompetitionApiClient, ICompetitionPage, ICompetition, CompetitionFilter } from '@cyber-range/cyber-range-api-ctf-competition-client';
import Config from '@/config';
import TitleStrings from '@/entities/strings/definitions/titleStrings';
import { useApiClientStore } from '@stores/apiClientStore';

@Component({ metaInfo: { title: TitleStrings.en.TITLE_COMPETITIONS }})
export default class Competitions extends Vue 
{
    competitions:ICompetition[] = [];
    isLoading:boolean = true;

    // TODO: Change this to composition api
    get client(): ICompetitionApiClient
    {
        return useApiClientStore().competitionApiClient;
    }
    // END TODO

    async mounted()
    {
        await this.fetchCompetitions();
    }

    async fetchCompetitions(): Promise<void>
    {
        try
        {
            this.isLoading = true;

            let page:ICompetitionPage;
            let token:string;

            do
            {
                page = await this.client.get(new CompetitionFilter(<any>{token,limit:Config.DEFAULT_FETCH_SIZE}));

                if(page.items.length > 0)
                {
                    for(let item of page.items) this.competitions.push(item);
                }
            }
            while(token = page.nextPageToken);
        }
        finally
        {
            this.isLoading = false;
        }
    }
}
</script>

<style scoped>
.loadingRow{
    min-height: 48px;
}
</style>