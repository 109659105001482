<template>
    <confirmation-dialog v-model="showConfirmationDialog" :title="$t('TEAMS_EDIT_DIALOG_TITLE')" @confirm="confirm" @cancel="cancel" :loading="isLoading" id="teamEditDialog">
        <v-form v-model="valid" ref="form" @submit.prevent>
            <v-text-field v-model="name" :label="$t('TEAMS_EDIT_DIALOG_NAME')" :rules="nameRules" :counter="maxNameLength" :disabled="isLoading || !allowTeamNameChange" class="teamEditDialogName" id="teamEditDialogName"/>
            <v-checkbox v-if="canHideTeam(competition.id, competition.organizationId)" v-model="hidden" :label="$t('TEAMS_EDIT_DIALOG_HIDDEN')" :dark="isDark" :light="isLight" color="unset" :disabled="isLoading" class="teamEditDialogHidden" id="teamEditDialogHidden"/>
            <team-members :team="team" :players="players" :editable="true" @kicked="onPlayerKicked" @approved="onPlayerApproved" @rejected="onPlayerRejected" id="teamMember" />
        </v-form>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter, Mutation } from 'vuex-class';
import StoreAction from "@/interfaces/storeAction";
import StoreMutation from "@/interfaces/storeMutation";
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { Prop, Watch } from 'vue-property-decorator';
import Rule from '@/validations/Rule';
import { ITeamApiClient, ITeam, Team } from '@cyber-range/cyber-range-api-ctf-team-client';
import { IPlayer, PlayerFilter, IPlayerPage, IPlayerApiClient, PlayerStatus } from '@cyber-range/cyber-range-api-ctf-player-client';
import Config from '@/config';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';

@Component({components:{}})
export default class EditTeamDialog extends Vue 
{  

    @Action(StoreAction.FetchAlerts) fetchAlerts: ()=>Promise<void>;
    @Mutation(StoreMutation.ResetAlerts) resetAlerts: ()=>void;

    @Prop(Boolean) value: boolean;
    @Prop(Object) team:ITeam;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get teamApiClient(): ITeamApiClient
    {
        return useApiClientStore().teamApiClient;
    }
    get playerApiClient(): IPlayerApiClient
    {
        return useApiClientStore().playerApiClient;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    canHideTeam(competitionId:string, organizationId:string): boolean
    {
        return useAuthorizationStore().canHideTeam(competitionId, organizationId);
    }
    // END TODO

    valid = false;
    showConfirmationDialog:boolean = false;
    maxNameLength:number = 100;

    name:string = '';
    hidden:boolean = false;
    players:IPlayer[] = [];
    kickedPlayers:Set<IPlayer> = new Set<IPlayer>();
    approvedPlayers:Set<IPlayer> = new Set<IPlayer>();
    rejectedPlayers:Set<IPlayer> = new Set<IPlayer>();

    get allowTeamNameChange(): boolean
    {
        return this.competition.settings?.allowTeamNameChange || false;
    }

    get nameRules()
    {
        return this.allowTeamNameChange ? [Rule.require, (v)=>Rule.maxLength(v, this.maxNameLength)] : [];
    }

    onPlayerKicked(player:IPlayer)
    {
        if(player.status === PlayerStatus.Ready)
        {
            this.kickedPlayers.add(player);
        }
        else if(player.status === PlayerStatus.Joining)
        {
            //This is for a pending player who was approved and then kicked.
            this.approvedPlayers.delete(player);
            this.rejectedPlayers.add(player);
        }
    }

    onPlayerApproved(player:IPlayer)
    {
        this.approvedPlayers.add(player);
    }

    onPlayerRejected(player:IPlayer)
    {
        this.rejectedPlayers.add(player);
    }

    async mounted() 
    {
        if(this.team) await this.load()
    }

    @Watch('value')
    async onValueChanged(value:boolean)
    {
        if(value) await this.load()
    }

    async load()
    {
        this.name = this.team.name;
        this.hidden = this.team.hidden;
        this.players = await this.getPlayers();
        this.kickedPlayers = new Set<IPlayer>();
        this.approvedPlayers = new Set<IPlayer>();
        this.rejectedPlayers = new Set<IPlayer>();
        
        this.showConfirmationDialog = this.value
    }

    async getPlayers(): Promise<IPlayer[]>
    {
        let players:IPlayer[] = [];

        let filter = new PlayerFilter({competitionId: this.competition.id, teamId: this.team.id, limit: Config.DEFAULT_FETCH_SIZE});
        let page:IPlayerPage;

        do
        {
            page = await this.playerApiClient.get(filter);
            filter.token = page.nextPageToken;
            players = players.concat(page.items);
        }
        while(filter.token);

        return players;
    }

    async confirm()
    {
        (<any>this.$refs.form).validate();

        if(!this.valid) return;
        
        let toUpdateTeam = new Team();
        
        if(this.allowTeamNameChange && this.name !== this.team.name)
        {
            toUpdateTeam.name = this.name;
        }

        if(this.hidden !== this.team.hidden)
        {
            toUpdateTeam.hidden = this.hidden;
        }

        if(toUpdateTeam.name || toUpdateTeam.hidden !== undefined)
        {
            await this.teamApiClient.update(this.team.id, toUpdateTeam);
        }
    
        let removalPromises = [];
        for(let player of this.kickedPlayers)
        {
            removalPromises.push(this.teamApiClient.kick(this.team.id, player.id));
        }
        for(let player of this.rejectedPlayers)
        {
            removalPromises.push(this.teamApiClient.reject(this.team.id, player.id));
        }
        await Promise.all(removalPromises); // these promises must happen first to allow appropriate team size enforcement

        let promises = [];
        for(let player of this.approvedPlayers)
        {
            promises.push(this.teamApiClient.approve(this.team.id, player.id));
        }
        await Promise.all(promises);

        // removes approval alerts after approving from this dialog
        this.resetAlerts();
        this.fetchAlerts();
        
        //Emit events after all players have been kicked, approved, or rejected.
        for(let player of this.kickedPlayers)
        {
            this.$emit('kicked', player);
        }
        for(let player of this.approvedPlayers)
        {
            this.$emit('approved', player);
        }
        for(let player of this.rejectedPlayers)
        {
            this.$emit('rejected', player);
        }

        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.$emit('input', false);
    }
}
</script>