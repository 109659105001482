<template>
    <div>
        <v-text-field v-model="keyword" dense :disabled="isLoading" :dark="isDark" :light="isLight" color="unset" prepend-icon="search" class="libraryEntriesListSearch" id="libraryEntriesListSearch" />
        <sorted-select v-model="category" :items="categories" clearable dense :disabled="isLoading" :dark="isDark" :light="isLight" color="unset" prepend-icon="filter_list" class="libraryEntriesListFilter" id="libraryEntriesListFilter"/>
        <v-card flat outlined>
            <v-list dense :disabled="isLoading" :dark="isDark" :light="isLight" class="libraryEntriesListList" id="libraryEntriesListList">
                <v-list-item-group v-model="highlightedEntryId" color="dialogText">
                    <v-list-item v-for="entry in filteredLibraryEntries" :key="entry.id" :dark="isDark" :light="isLight" class="libraryEntry" :value="entry.id" :input-value="highlightedEntryId === entry.id">
                        <v-list-item-action>
                            <v-simple-checkbox color="blue" :value="isASelectedEntry(entry)" @input="toggleIsEntrySelected(entry)"/>
                        </v-list-item-action>
                        <v-list-item-content >
                            <v-list-item-title :aria-label="$t('LIBRARY_ENTRY_LABEL',getEntryLabelData(entry))">{{entry.name}}</v-list-item-title>
                            <v-list-item-subtitle>
                                <v-icon v-if="entry.userId || entry.organizationId" small>
                                    {{getEntryIcon(entry)}}
                                </v-icon> 
                                {{entry.category}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import StoreGetter from '@interfaces/storeGetter';
import { VModel, Watch } from 'vue-property-decorator';
import { ILibraryEntry } from '@cyber-range/cyber-range-api-ctf-library-client';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import StoreAction from '@/interfaces/storeAction';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useLibraryEntryStore } from '@stores/libraryEntryStore';

const SEARCH_DELAYED_IN_MS = 250;
const KEYWORD = '_keyword';
const FILTER = '_filter';

@Component
export default class LibraryEntriesList extends Vue 
{
    @VModel({ type: Array, default: () => [] }) selectedEntries: ReadonlyArray<ILibraryEntry>;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get categories(): string[]
    {
        return useLibraryEntryStore().libraryCategories;
    }
    // END TODO

    searchSubject:Subject<void>;
    searchSubscription:Subscription;
    
    keyword:string = '';
    category:string = '';
    filteredLibraryEntries:ILibraryEntry[] = [];
    highlightedEntryId:string = '';

    get libraryEntries(): ILibraryEntry[]
    {
        const entries: ILibraryEntry[] = useLibraryEntryStore().sortedLibraryEntries;
        return entries.filter(({ enabled }) => enabled).map(entry => Object.assign(entry, {
            [FILTER]: entry.category?.toLowerCase(),
            [KEYWORD]: `${entry.name}/${entry.description}/${(entry.tags || []).join('/')}`.toLowerCase()
        }));
    }

    getEntryIcon(entry:ILibraryEntry): string
    {
        if(entry.userId) { return 'person'; }
        if(entry.organizationId) { return 'account_balance'; }
        
        return 'public';
    }

    getEntryVisibility(entry:ILibraryEntry): string
    {
        if(entry.userId) { return this.$t('LIBRARY_LABEL_VISIBLIBILTY_PERSONAL').toString(); }
        if(entry.organizationId) { return this.$t('LIBRARY_LABEL_VISIBLIBILTY_ORGANIZATION').toString(); }
        
        return this.$t('LIBRARY_LABEL_VISIBLIBILTY_GLOBAL').toString();
    }

    getEntryLabelData(entry:ILibraryEntry): object
    {
        return {
            name: entry.name,
            category: entry.category || this.$t('LIBRARY_ENTRY_LABEL_UNCATEGORIZED').toString(),
            visibility: this.getEntryVisibility(entry)
        };
    }

    isASelectedEntry(entry: ILibraryEntry): boolean
    {
        return !!this.selectedEntries.find(e => e.id === entry.id);
    }

    toggleIsEntrySelected(entry: ILibraryEntry): void
    {
        this.highlightedEntryId = entry.id;
        const i = this.selectedEntries.findIndex(e => e.id === entry.id);
        if (i === -1)
        {
            this.selectedEntries = [...this.selectedEntries, entry];
        }
        else
        {
            this.selectedEntries = this.selectedEntries.filter(e => e.id !== entry.id);
        }
    }

    @Watch('highlightedEntryId')
    onHighlightedEntryIdChanged(): void
    {
        if (!this.highlightedEntryId) return;
        const entry = this.libraryEntries.find(entry => entry.id === this.highlightedEntryId);
        this.$emit('value', entry);
    }

    @Watch('keyword')
    onKeywordChanged()
    {
        this.searchSubject.next();
    }

    @Watch('category')
    onCategoryChanged()
    {
        this.searchSubject.next();
    }

    onSearch()
    {
        let conditions:Function[] = [];

        if(this.category)
        {
            conditions.push((e:ILibraryEntry) => e[FILTER].includes(this.category.toLowerCase()));
        }
        if(this.keyword)
        {
            conditions.push((e:ILibraryEntry) =>  e[KEYWORD].includes(this.keyword.toLowerCase()));
        }

        this.filteredLibraryEntries = conditions.length === 0 ?  this.libraryEntries : this.libraryEntries.filter(e => conditions.every(condition => condition(e)));
    }

    async mounted()  
    {
        this.searchSubject = new Subject<void>();
        this.searchSubscription = this.searchSubject
                                    .pipe(debounceTime(SEARCH_DELAYED_IN_MS))
                                    .subscribe({next:this.onSearch})

       await this.load();
    }

    beforeDestroy() 
    {
        this.searchSubject.unsubscribe();
    }

    async load()
    {
        await useLibraryEntryStore().fetchLibraryEntries();
        this.filteredLibraryEntries = this.libraryEntries
    }
}
</script>
<style scoped>
.v-list
{
    min-height: 300px;
    max-height: 300px;
    overflow-y: auto
}
</style>