import {UserRole as OrganizationRole} from '@cyber-range/cyber-range-api-organization-client';
import {UserRole as CourseRole} from '@cyber-range/cyber-range-api-user-client';

export function toDisplayRoles(value)
{
    let rolesArray = Array.isArray(value) ? value : value.split(",");
    let roles = new Set();

    for(let role of rolesArray)
    {
        switch(role)
        {
            case(OrganizationRole.BILLING_ADMIN):
                roles.add("billing admin");
                break;
            case(OrganizationRole.COURSE_ADMIN):
                roles.add("course admin");
                break;
            case(OrganizationRole.ORGANIZATION_ADMIN):
                roles.add("organization admin");
                break;
            case(OrganizationRole.ORGANIZATION_INSTRUCTOR):
            case(CourseRole.Instructor):
                roles.add("instructor");
                break;
            case(OrganizationRole.ORGANIZATION_TA):
            case(CourseRole.TA):
                roles.add("TA");
                break;
            case(OrganizationRole.ORGANIZATION_STUDENT):
            case(CourseRole.Student):
                roles.add("student");
                break;
            case(CourseRole.CourseRequester):
                roles.add("course requester");
                break;
            case(CourseRole.CtfAdmin):
                roles.add("CTF admin");
                break;
            case(CourseRole.CtfPlayer):
                roles.add("CTF player");
                break;
            default:
                roles.add(role);
                break;        
        }
    }

    let tokens = Array.from(roles);

    if(tokens.length <= 1)
    {
        return tokens.join('');
    }
    if(tokens.length == 2)
    {
        return tokens.join(" and ");
    }
    
    let lastToken = tokens.pop();
    tokens.push('and');

    return tokens.join(", ") + ` ${lastToken}`;
}