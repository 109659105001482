import ITheme from '@/interfaces/iTheme';
import backgroundImageVaCyberEduConf from '@assets/themes/vaCyberEduConf/backgroundImageVaCyberEduConf.svg';

export class VaCyberEduConfTheme implements ITheme
{
    static Id = 'vacrcybereduconf';
    id = VaCyberEduConfTheme.Id;

    name = 'Virginia Cybersecurity Education Conference';

    colors = {
        application: '#553A97', //#051c29
        text: '#E0E0E0',
        confirm: '#553A97',
        cancel: '#000000',
        secondary: '#62B4E4',
        primary: '#FFFFFF',
        falseswitch: '#808080',
        solved: '#455A64',
        unsolved: '#2E9CDC',
        noMoreAttempts: '#644545',
        disabled: '#FF4B1F',
        tag: '#62B4E4',
        activeTab: '#62B4E4',
        dialogText: '#303030',
        dialogSuccessText: '#4CAF50',
        dialogFailureText: '#F44336',
        dialogIcon: '#553A97',
        success: '#4CAF50',
        warning: '#FF9800',
        link: '#62B4E4'
     };

    fontFamily = "Courier New Bold";
    isDark = true;
    isDialogDark = false;
    backgroundImage = backgroundImageVaCyberEduConf;
}