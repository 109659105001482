import Vue from 'vue';
import NotificationEvent from '@/interfaces/NotificationEvent';
import { ISubmissionApiClient, SubmissionFilter, ISubmissionFilter, ITeamSubmissionStatistics, ITeamSubmissionStatisticsPage, IPlayerSubmissionStatisticsPage, IPlayerSubmissionStatistics, ISubmission } from '@cyber-range/cyber-range-api-ctf-submission-client';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import ITeamScoreHistory from '@/interfaces/iTeamScoreHistory';
import TeamScoreHistory from '@/entities/teamScoreHistory';
import Config from '@/config';
import { useNotificationStore } from '@stores/notificationStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useAuthenticationStore } from '@stores/authenticationStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';
import { defineStore } from 'pinia';

export const useScoreStore = defineStore('scoreStore', 
{
    state: () =>
    ({
        teamScores: [] as ITeamSubmissionStatistics[],
        teamScoresById: {} as ITeamSubmissionStatistics,
        isTeamScoresFetched: false,
        playerScores: [] as IPlayerSubmissionStatistics[],
        playersScoresById: {} as IPlayerSubmissionStatistics,
        teamScoreHistories: {} as ITeamScoreHistory,
        latestTeamScoreModifiedTimestamp: ''
    }),
    getters:
    {
        getTeamsScores(): ITeamSubmissionStatistics[]
        {
            return this.teamScores || [];
        },
        getPlayersScores(): IPlayerSubmissionStatistics[]
        {
            return this.playerScores || [];
        },
    },
    actions:
    {
        getTeamScore(teamId:string): ITeamSubmissionStatistics
        {
            return this.teamScoresById[teamId];
        },
        getPlayerScore(playerId:string): IPlayerSubmissionStatistics
        {
            return this.playerScoresById[playerId];
        },
        getTeamScoreHistory(teamId:string): ITeamScoreHistory[]
        {
            return this.teamScoreHistories[teamId] || [];
        },
        setTeamsScores(statistics:ITeamSubmissionStatistics[]): void
        {
            let table = {};

            for(let s of statistics)
            {
                table[s.teamId] = s;
            }

            this.teamScoresById = table;
            // Sort by score, with date score was last modified as the tie-breaker
            this.teamScores = statistics.sort((a,b)=> (a.score === b.score) ? new Date(a.scoreModifiedTimestamp).getTime() - new Date(b.scoreModifiedTimestamp).getTime() : b.score - a.score) || [];
            this.isTeamScoresFetched = true;
            
            let latestTeamScoreModifiedTimestamp = '';

            for(let score of this.teamScores)
            {
                if(latestTeamScoreModifiedTimestamp < score.scoreModifiedTimestamp)
                {
                    latestTeamScoreModifiedTimestamp = score.scoreModifiedTimestamp;
                }
            }
            this.latestTeamScoreModifiedTimestamp = latestTeamScoreModifiedTimestamp
        },
        setPlayersScores(statistics:IPlayerSubmissionStatistics[]): void
        {
            let table = {};

            for(let s of statistics)
            {
                table[s.playerId] = s;
            }

            this.playerScoresById = table;
            // Sort by score, with date score was last modified as the tie-breaker
            this.playerScores = statistics.sort((a,b)=> (a.score === b.score) ? new Date(a.scoreModifiedTimestamp).getTime() - new Date(b.scoreModifiedTimestamp).getTime() : b.score - a.score) || [];
        },
        addTeamScoreHistory(submission:ISubmission): void
        {
            //Update the team's score history
            if(this.teamScoreHistories[submission.teamId] === undefined)
            {
                Vue.set(this.teamScoreHistories, submission.teamId, []);
            }

            let history = this.teamScoreHistories[submission.teamId];
            let latestScore = history.length === 0 ? 0 : history[history.length - 1].score;

            this.teamScoreHistories[submission.teamId].push(new TeamScoreHistory({teamId: submission.teamId,
                                                                                   score: latestScore + submission.scoreAdjustment, 
                                                                                   timestamp: submission.createdTimestamp}))
        },
        resetScoreHistories(): void
        {
            for(let key of Object.keys(this.teamScoreHistories))
            {
                delete this.teamScoreHistories[key];
            }
        },
        async fetchScores(options?:{background:boolean}): Promise<void> 
        {
            let competition:ICompetition = useCompetitionStore().currentCompetition;

            let promises = [this.fetchTeamsScores(options)];
            
            if(useAuthorizationStore().canManageSubmission(competition.id, competition.organizationId))
            {
                promises.push(this.fetchPlayersScores(options))
            }

            await Promise.all(promises);

            if(!useNotificationStore().isSubscribed(NotificationEvent.ScoreUpdated))
            {
                useNotificationStore().subscribe({
                    event: NotificationEvent.ScoreUpdated, 
                    callback: ()=>this.fetchScores({background:true})
                }); 
            };
        },
        async fetchTeamsScores(options?:{background:boolean}): Promise<void> 
        {
            let client:ISubmissionApiClient = options?.background ? useApiClientStore().backgroundSubmissionApiClient : useApiClientStore().submissionApiClient;
            let page:ITeamSubmissionStatisticsPage;
            let statistics:ITeamSubmissionStatistics[] = [];
            let filter:ISubmissionFilter = new SubmissionFilter({limit:Config.DEFAULT_FETCH_SIZE, scoreChange:true});
            let competition:ICompetition = useCompetitionStore().currentCompetition;
            do
            {
                page = await client.getCompetitionTeamStatistics(competition.id, filter);
                filter.token = page.nextPageToken;
                statistics = statistics.concat(page.items);
            }
            while(filter.token)

            this.setTeamsScores(statistics);
        },
        async fetchPlayersScores(options?:{background:boolean}): Promise<void> 
        {
            if(!useAuthenticationStore().isLogin) return undefined;
            
            let client:ISubmissionApiClient = options?.background ? useApiClientStore().backgroundSubmissionApiClient : useApiClientStore().submissionApiClient;
            let page:IPlayerSubmissionStatisticsPage;
            let statistics:IPlayerSubmissionStatistics[] = [];
            let filter:ISubmissionFilter = new SubmissionFilter({limit:Config.DEFAULT_FETCH_SIZE, scoreChange:true});
            let competition:ICompetition = useCompetitionStore().currentCompetition;
            do
            {
                page = await client.getCompetitionPlayerStatistics(competition.id, filter);
                filter.token = page.nextPageToken;
                statistics = statistics.concat(page.items);
            }
            while(filter.token)

            this.setPlayersScores(statistics);
        }
    }
});