<template>
    <v-row wrap>
        <v-col cols="12">
            <v-text-field type="number" v-model.number="settings.maxNumberOfAttempts" :label="$t('CHALLENGE_SETTINGS_MAX_NUMBER_OF_ATTEMPTS')" :dark="isDark" @update:error="onError"
                        :rules="maxNumberOfAttemptsRule" :counter="maxMaxNumberOfAttempts" :disabled="isLoading" class="maxNumberOfAttempts" id="maxNumberOfAttempts" />
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IChallengeSettings } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { Inject, Prop, Watch } from 'vue-property-decorator';
import Rule from '@/validations/Rule';
import Config from '@/config';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';

@Component
export default class ChallengeSettings extends Vue 
{  
    @Inject('form') from;
    @Prop(Object) settings:IChallengeSettings;
    @Prop(Boolean) choice:boolean;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    // END TODO

    maxMaxNumberOfAttempts:number = 256;

    maxNumberOfAttemptsRule = [Rule.require, 
                               (v) => Rule.minValue(v, 1), 
                               (v) => Rule.maxValue(v, this.maxMaxNumberOfAttempts)]

    @Watch('choice')
    onFlagTypeChanged(value: boolean) {
        if (this.settings.maxNumberOfAttempts === Config.DEFAULT_MAX_NUMBER_OF_ATTEMPTS ||
            this.settings.maxNumberOfAttempts === Config.DEFAULT_MULTIPLE_CHOICE_MAX_NUMBER_OF_ATTEMPTS)
        {
            this.settings.maxNumberOfAttempts = value ?
                Config.DEFAULT_MULTIPLE_CHOICE_MAX_NUMBER_OF_ATTEMPTS :
                Config.DEFAULT_MAX_NUMBER_OF_ATTEMPTS;
        }
    }

    created()
    {
        this.from?.register(this);
    }
    reset() {}
    validate()
    {
        for (const rule of this.maxNumberOfAttemptsRule)
        {
            const ruleResult = rule(this.settings.maxNumberOfAttempts)
            if (ruleResult !== true)
            {
                this.$emit('error', ruleResult);
                return false;
            }
        }
        return true;
    }

    onError(error)
    {
        this.$emit('error', error);
    }
}
</script>