import ITheme from "@/interfaces/iTheme";
import backgroundImageLightTheme from "@assets/themes/light/backgroundImageLightTheme.svg";

export class LightTheme implements ITheme
{
    static Id = 'light';
    id = LightTheme.Id;

    name = 'Light';

    colors = {
        application: '#FFFFFF',
        text: '#001A53',
        confirm: '#1976D2',
        accent: '#1976D2',
        falseswitch: '#F5F5F5',
        solved: '#808080',
        unsolved: '#B3E5FC',
        noMoreAttempts: '#FBA6A6',
        disabled: '#C62828',
        activeTab: '#1976D2',
        dialogText: '#303030',
        dialogSuccessText: '#4CAF50',
        dialogFailureText: '#F44336',
        dialogIcon: '#1976D2',
        success: '#4CAF50',
        warning: '#FF9800',
        link: '#1976D2'
    };

    fontFamily = "Blinker-Bold";
    backgroundImage = backgroundImageLightTheme;
    isDark = false;
    isDialogDark = false;
}