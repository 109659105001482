<template>
    <v-card flat :dark="isDark" :light="isLight" class="pt-0">
        <v-card-title class="pt-0">
            <p class='libraryEntryPreviewName'>{{entry.name}}</p>
        </v-card-title>
        <v-card-text>
            <v-row row wrap>
                <v-col cols="12" class='pt-1'>
                    <p class='libraryEntryPreviewDescription' v-html="entryDescription" />
                </v-col>
                <v-col cols="12" class='pt-1'>
                    <artifacts :artifacts="entry.artifacts" class='libraryEntryPreviewArtifacts' />
                </v-col>
                <v-col cols="12" class='pt-2 pb-1'>
                    <v-subheader>{{$t('LIBRARY_ENTRIES_DIALOG_PREVIEW_CATEGORY')}}</v-subheader>
                    <p class='libraryEntryPreviewCategory'>{{entry.category}}</p>
                </v-col>
                <v-col cols="12" class='pt-2 pb-1'>
                    <v-subheader>{{$t('LIBRARY_ENTRIES_DIALOG_PREVIEW_POINTS')}}</v-subheader>
                    <p class='libraryEntryPreviewPoints'>{{entry.points}}</p>
                </v-col>
                <v-col cols="12" class='pt-2 pb-1'>
                    <v-subheader>{{$t('LIBRARY_ENTRIES_DIALOG_PREVIEW_DIFFICULTY')}}</v-subheader>
                    <v-rating v-if="entry.statistics && getStar(entry) !== -1" small dense :value="getStar(entry)" color="accent" half-increments :readonly="true" class="pl-0 libraryEntryPreviewDifficulty"/>
                    <p v-else class="libraryEntryPreviewDifficultyUnknown">{{$t('LIBRARY_ENTRIES_DIALOG_PREVIEW_DIFFICULTY_UNKNOWN')}}</p>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ILibraryEntry } from '@cyber-range/cyber-range-api-ctf-library-client';
import { Prop } from 'vue-property-decorator';
import { useThemeStore } from '@/stores/themeStore';

@Component
export default class LibraryEntriesDialogSplashScreen extends Vue 
{  
    @Prop(Object) entry:ILibraryEntry;
    
    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    // END TODO

    get entryDescription():string
    {
        return this.entry?.description?.replace(/\n/g, '<br/>')
    }

    getStar(entry:ILibraryEntry): number
    {
        let successAttempts = this.entry.statistics?.successAttempts || 0;
        let failedAttempts = this.entry.statistics?.failedAttempts || 0;

        if(successAttempts > 0 && failedAttempts > 0)
        {
            return (failedAttempts / (successAttempts + failedAttempts)) * 5  //5 stars
        }
        else if(failedAttempts > 0)
        {
            return 5;
        }
        else if(successAttempts > 0)
        {
            return 0;
        }
        return -1;
    }
}
</script>
<style scoped>
.v-subheader
{
    padding-left: 0px;
}
</style>