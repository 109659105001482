<template>
    <div>
        <delete-confirmation-dialog v-model="showConfirmationDialog" :checkbox="true" :confirm="$t('PLAYERS_DELETE_DIALOG_CONFIRM')" @confirm="confirm" @cancel="cancel" :loading="isLoading" id="playersDeleteDialog">
            <span class="deletePlayersDialogBodyMessage">{{ $t('PLAYERS_DELETE_PLAYERS_DIALOG_MESSAGE', {count}) }}</span>
        </delete-confirmation-dialog>
        <job-progress-dialog :jobId="jobId" v-model="showJobProgressDialog" @confirm="onJobDone" :dialogTitle="$t('PLAYERS_DELETE_JOB_PROGESS_DIALOG_TITLE')"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IInvitationApiClient } from '@cyber-range/cyber-range-api-invitation-client';
import { IJobApiClient } from '@cyber-range/cyber-range-api-job-client';
import PlayerView from './PlayerView';
import { Prop, Watch } from 'vue-property-decorator';
import { IUserApiClient } from '@cyber-range/cyber-range-api-user-client';
import { useApiClientStore } from '@stores/apiClientStore';

@Component({})
export default class DeletePlayersDialog extends Vue 
{
    @Prop(Boolean) value: boolean;
    @Prop(Array) players:PlayerView[];

    showConfirmationDialog:boolean = false;
    showJobProgressDialog:boolean = false;
    jobId:string = null;

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get bulkUserApiClient(): IUserApiClient
    {
        return useApiClientStore().bulkUserApiClient;
    }
    get bulkInvitationApiClient(): IInvitationApiClient
    {
        return useApiClientStore().bulkInvitationApiClient;
    }
    get jobApiClient(): IJobApiClient
    {
        return useApiClientStore().jobApiClient;
    }
    // END TODO
    
    @Watch('value')
    onValueChanged()
    {
        this.showConfirmationDialog = this.value;
    }

    mounted() 
    {
        this.showConfirmationDialog = this.value;
    }

    get count(): number
    {
        return this.players?.length || 0;
    }
    
    async confirm()
    {
        for (const playerView of this.players)
        {
            if(playerView.isPlayer)
            {
                await this.bulkUserApiClient.removeUserFromCompetition(
                    playerView.player.userId, 
                    playerView.player.competitionId
                );
            }
            else
            {
                await this.bulkInvitationApiClient.delete(playerView.invitation.id);
            }
        }
        this.jobId = await this.jobApiClient.submit();
        this.showConfirmationDialog = false;
        this.showJobProgressDialog = true;
    }

    onJobDone()
    {
        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.showJobProgressDialog = false;
        this.$emit('input', false);
    }
}
</script>
