<template>
    <div class="timeframe">
        {{timeframe}}
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";
import { getTimeframe } from '../../utils/timeframe';

@Component
export default class Timeframe extends Vue 
{
    @Prop(String) from;
    @Prop(String) to;

    get timeframe(): string
    {
        return getTimeframe(this.from, this.to);
    }
}
</script>