import NotificationEvent from '@interfaces/NotificationEvent';
import { ILibraryApiClient, ILibraryEntry, ILibraryEntryFilter, LibraryEntryFilter, LibraryEntryView } from '@cyber-range/cyber-range-api-ctf-library-client';
import Config from '@/config';
import { useNotificationStore } from '@stores/notificationStore';
import { useApiClientStore } from './apiClientStore';
import { defineStore } from 'pinia';

export const useLibraryEntryStore = defineStore('libraryEntryStore', 
{
    state: () =>
    ({
        isLibraryEntriesFetched: false,
        _libraryEntries: [] as ILibraryEntry[],
    }),
    getters:
    {
        libraryEntries(): ILibraryEntry[]
        {
            return this._libraryEntries;
        },
        sortedLibraryEntries(): ILibraryEntry[]
        {
            return [...this._libraryEntries].sort((a,b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
        },
        libraryCategories(): string[]
        {
            return [...new Set(<string[]>this._libraryEntries.map(({ category }) => <string>category))];
        },
        libraryAuthors(): {name: string, email: string}[]
        {
            const authors: Record<string, {name: string, email: string}> = {};

            for (const { protectedInformation } of this._libraryEntries)
            {
                if (protectedInformation?.authorName && protectedInformation?.authorEmail)
                {
                    const key = protectedInformation.authorName + protectedInformation.authorEmail;
                    authors[key] ??= {
                        name: protectedInformation.authorName,
                        email: protectedInformation.authorEmail
                    };
                }
            }

            return Object.values(authors);
        },
    },
    actions:
    {
        setLibraryEntries(libraryEntries:ILibraryEntry[]): void
        {
            this._libraryEntries = Object.freeze(libraryEntries) as ILibraryEntry[];
            this.isLibraryEntriesFetched = true;
        },
        clearLibraryEntries(): void
        {
            this._libraryEntries = [];
            this.isLibraryEntriesFetched = false;
        },
        async fetchLibraryEntries(payload?: {background: boolean}): Promise<void> 
        {
            const client: ILibraryApiClient = payload?.background ? useApiClientStore().backgroundLibraryApiClient : useApiClientStore().libraryApiClient;
            const libraryEntries:ILibraryEntry[] = [];

            let filter: ILibraryEntryFilter = new LibraryEntryFilter({ limit: Config.LIBRARY_FETCH_SIZE, view: LibraryEntryView.Full });
            do
            {
                const result = await client.get(filter);
                filter.token = result.nextPageToken;
                libraryEntries.push(...result.items);
            }
            while(filter.token)

            this.setLibraryEntries(libraryEntries);

            if(!useNotificationStore().isSubscribed(NotificationEvent.libraryEntriesUpdated))
            {
                useNotificationStore().subscribe({
                    event: NotificationEvent.libraryEntriesUpdated, 
                    callback: () => this.fetchLibraryEntries({ background: true })
                }); 
            };
        }
    }
});
