<template>
    <confirmation-dialog v-model="showDialog" :title="$t('CHALLENGE_SAVE_CHALLENGE_DIALOG_TITLE')" @confirm="confirm" @cancel="cancel" :loading="isLoading" class="saveChallengeDialog">
        {{$t('CHALLENGE_SAVE_CHALLENGE_DIALOG_TEXT', {visibility: visibility})}}
        <v-checkbox v-if="showPersistProtectedInformationDateCheckbox" v-model="persistProtectedInformationDate" :label='$t("CHALLENGE_SAVE_CHALLENGE_DIALOG_PERSIST_PROTECTED_INFORMATION_DATE_CHECKBOX_LABEL", {date:challengeCreationDate})' :dark="isDark" :light="isLight" color="unset" :disabled="isLoading" id="checkboxPersistProtectedInformationDate"/>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { IChallenge, Challenge, IChallengeApiClient } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { ILibraryApiClient, LibraryVisibility, ProtectedInformation } from '@cyber-range/cyber-range-api-ctf-library-client';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useAuthorizationStore } from '@stores/authorizationStore';

@Component
export default class SaveChallengeDialog extends Vue
{
    @Prop(Boolean) value: boolean;
    @Prop(String) challengeId: string;
    @Prop(LibraryVisibility) visibility: LibraryVisibility;

    challenge:IChallenge = Challenge.fromJson({});
    challengeCreationDate:string = '';
    showDialog:boolean = false;
    showPersistProtectedInformationDateCheckbox:boolean = false;
    persistProtectedInformationDate:boolean = true;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get challengeClient(): IChallengeApiClient
    {
        return useApiClientStore().challengeApiClient;
    }
    get libraryApiClient(): ILibraryApiClient
    {
        return useApiClientStore().libraryApiClient;
    }
    // END TODO

    @Watch('value', { immediate: true })
    onValueChanged()
    {
        this.showDialog = this.value;
    }

    async mounted()
    {
        this.showDialog = this.value;

        if(this.showDialog) await this.load();

        if(useAuthorizationStore().canUpdateChallengeProtectedInformation())
        {
            this.challenge = await this.challengeClient.getOne(this.challengeId);
            this.challengeCreationDate = this.challenge?.protectedInformation?.creationDate || ' '
            this.showPersistProtectedInformationDateCheckbox = true;
        }
    }

    async load()
    {
        this.showDialog = true;
    }

    async confirm()
    {
        let libraryId = await this.challengeClient.save(this.challengeId, this.visibility);

        if (useAuthorizationStore().canUpdateChallengeProtectedInformation() && !this.persistProtectedInformationDate)
        {
            let savedEntry = await this.libraryApiClient.getOne(libraryId);
            let protectedInformation = ProtectedInformation.fromJson({
                authorName: savedEntry?.protectedInformation?.authorName,
                authorEmail: savedEntry?.protectedInformation?.authorEmail,
                creationDate: new Date().toISOString().substring(0, 10)
            })
            await this.libraryApiClient.update(libraryId, { protectedInformation });
        }
        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showDialog = false;
        this.$emit('input', false);
    }
}
</script>