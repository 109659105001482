<template>
    <v-select v-bind="$attrs" v-on="$listeners" :items="sortedItems"/>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({inheritAttrs: false})
export default class SortedSelect extends Vue {
    @Prop() items: Array<{text:string, value:string}|string>;

    get sortedItems(): Array<{text:string, value:string}|string> {
        return [...this.items].sort((a, b) => 
        {
            a = typeof a === 'string' ? a : a.text
            b = typeof b === 'string' ? b : b.text
            return a?.localeCompare(b)
        });
    }
}
</script>