<template>
    <v-card outlined :dark="isDark" :light="isLight" class="elevation-0 scoreWindow window alphaBackground">
        <score-table :trackId="myTeamId" :showNoScoreMessage="true" />
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import StoreGetter from '@interfaces/storeGetter';
import { IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { Prop } from 'vue-property-decorator';
import StoreAction from '@interfaces/storeAction';
import Window from './Window.default.vue';
import { useThemeStore } from '@/stores/themeStore';
import { useScoreStore } from '@/stores/scoreStore';

@Component
export default class ScoreWindow extends mixins(Window) 
{   
    @Prop(Object) challenge:IChallenge;
    
    @Getter(StoreGetter.GetMyTeam) myTeamId:string;
    @Getter(StoreGetter.IsTeamsFetched) isTeamsFetched:boolean;
    @Getter(StoreGetter.IsTeamsFetching) isTeamsFetching:boolean;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isLight;
    }
    // END TODO
    
    @Action(StoreAction.FetchTeams) fetchTeams: () => Promise<void>;

    async created()
    {
        let promises:Promise<any>[] = [];

        if(!this.isTeamsFetching && !this.isTeamsFetched) promises.push(this.fetchTeams());
        if(!useScoreStore().isTeamScoresFetched) promises.push(useScoreStore().fetchScores())
        
        await Promise.all(promises);
    }
}
</script>

<style>
.theme--dark .scoreWindow .v-data-table 
{
    background-color: unset;
}
</style>