<template>
        <v-container align="top" fluid class="fill-height ml-5 mr-5">
            <v-row row wrap>
                <v-col id="competitionName" cols="12" align="center" :class="{'display-4': $vuetify.breakpoint.mdAndUp, 'display-1': $vuetify.breakpoint.smAndDown}" tag="h1">
                    {{ competition.name }}
                </v-col>
                <v-col cols="12" align="center" :class="{'headline': $vuetify.breakpoint.mdAndUp, 'subtitle-1': $vuetify.breakpoint.smAndDown, 'mt-5': true}" >
                    <timeframe id="competitionTimeframe" :from="startTime" :to="endTime" />
                </v-col>
                <v-col cols="12" align="center">
                    <landing-buttons id="landingButtons" :competition="competition" class="landingButtons" />
                </v-col>
                <v-col cols="12" align="center">
                    <v-row align="center" row wrap  :class="{'subtitle-1': $vuetify.breakpoint.mdAndUp, 'subtitle-2': $vuetify.breakpoint.smAndDown, 'mt-5': true, countdown: true}">
                        <template v-if="showStartCountdown">
                            <v-col cols="12" class="pt-0 pb-0">
                                {{$t('LANDING_STARTS_IN')}}
                            </v-col>
                            <v-col cols="12" class="pt-0">
                                <countdown-clock id="competitionStartCountdown" :end="startTime" @timed='startTimed = true'/>
                            </v-col>
                        </template>
                        <template v-if="showEndCountdown">
                            <v-col cols="12" class="pt-0 pb-0">
                                {{$t('LANDING_ENDS_IN')}}
                            </v-col>
                            <v-col cols="12" class="pt-0">
                                <countdown-clock id="competitionEndCountdown" :end="endTime" @timed='endTimed = true'/>
                            </v-col>
                        </template>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import { useCompetitionStore } from '@stores/competitionStore';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';

@Component({})
export default class Landing extends Vue 
{
    startTimed = false;
    endTimed = false;

    // TODO: Change this to composition api
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO

    get startTime(): string
    {
        return this.competition?.settings?.startTime;
    }

    get endTime(): string
    {
        return this.competition?.settings?.endTime;
    }

    get showStartCountdown(): boolean
    {
        return !this.startTimed && moment().isBefore(this.startTime);
    }

    get showEndCountdown(): boolean
    {
        return !this.showStartCountdown && !this.endTimed && moment().isAfter(this.startTime) && moment().isBefore(this.endTime);
    }
}
</script>


<style>
.landingButtons {
    margin-top: 80px;
    margin-bottom: 80px;
}
.countdown {
    margin-top: 80px;
    margin-bottom: 80px;
}
</style>