import IActivity from '@interfaces/iActivity';
import { defineStore } from 'pinia';

export const useActivityStore = defineStore('activityStore', {
    state: () =>
    ({
        _activities: [] as IActivity[]
    }),
    getters:
    {
        getActivities: (state): IActivity[] => [...state._activities].reverse(),
    },
    actions:
    {
        addActivity(activity: IActivity): void
        {
            if (activity)
            {
                this._activities.push(activity);
            }
        },
        resetActivities(): void
        {
            this._activities = []
        },
    }
});
