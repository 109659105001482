<template>
    <v-row wrap>
        <v-col cols="12" class="pt-0 pb-0 mb-0 mt-1" v-for="(artifact, index) in artifacts" :key="JSON.stringify(artifact)" :class="`artifact artifact_${artifact.type}`">
            <v-icon aria-hidden="false" role="img" :aria-label="getArtifactIconLabel(artifact.type)" class="mr-2" :dark="isDark" :light="isLight">{{getArtifactIcon(artifact.type)}}</v-icon>
            <v-btn text class="pa-0 ma-0 text-none" v-if="editable" @click="onEditClicked(index)" :disabled="isEditDisabled(artifact)" data-testing="artifacts-edit-artifact-button">{{artifact.name}}</v-btn>
            <button v-else-if="artifact.type === 'environment'" class="artifact-environment-button" :disabled="!canLaunchEnvironmentArtifact" @click="onEnvironmentClick(artifact.environmentId)">{{artifact.name}}</button>
            <a v-else-if="artifact.type === 'file'" @click.prevent="onFileClick(artifact)" target="_blank">{{artifact.name}}</a>
            <a v-else :href="artifact.value" target="_blank">{{artifact.name}}</a>
        </v-col>
        <v-col cols="12" v-if="editable" class="pt-0 pb-0 mb-0 mt-1 addArtifact">
            <v-icon class="mr-2" :dark="isDark" :light="isLight">add</v-icon> 
            <v-btn text class="pa-0 ma-0 text-none" @click="onAddClicked()" :disabled="disabled">{{$t('ARTIFACTS_ADD')}}</v-btn>
        </v-col>
        <add-edit-artifact-dialog v-model="showAddEditArtifactDialog" @confirm="onArtifactChanged" @deleted="onArtifactDeleted" :artifact="selectedArtifact" class="addEditArtifactDialog"/>
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { IChallengeArtifact, ChallengeArtifactType } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { Prop } from 'vue-property-decorator';
import { IFileApiClient } from '@cyber-range/cyber-range-api-file-client';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import Route from '@/interfaces/route';
import { useAuthorizationStore } from '@/stores/authorizationStore';
import { useCompetitionStore } from '@/stores/competitionStore';
import { useSubscriptionStore } from '@/stores/subscriptionStore';

@Component
export default class Artifacts extends Vue 
{  
    @Prop(Array) artifacts:IChallengeArtifact[];
    @Prop(Boolean) editable:boolean;
    @Prop(Boolean) disabled:boolean;

    environmentWindow?: Window;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get fileApiClient(): IFileApiClient
    {
        return useApiClientStore().fileApiClient;
    }
    // END TODO
    
    selectedArtifactIndex:number = -1;
    showAddEditArtifactDialog:boolean = false;

    get selectedArtifact():IChallengeArtifact
    {
        return this.selectedArtifactIndex === -1 ? undefined : this.artifacts[this.selectedArtifactIndex];
    }

    isEditDisabled(artifact: IChallengeArtifact): boolean
    {
        return this.disabled || (artifact.type === ChallengeArtifactType.Environment && !this.canCreateEnvironmentArtifact)
    }

    get canCreateEnvironmentArtifact()
    {
        const { id: competitionId, organizationId } = useCompetitionStore().currentCompetition
        const hasClaim = useAuthorizationStore().canCreateEnvironmentArtifact(competitionId, organizationId );
        const hasSubscription = useSubscriptionStore().canAllowIntegratedEnvironment;
        return hasClaim && hasSubscription;
    }

    get canLaunchEnvironmentArtifact()
    {
        const competitionId = useCompetitionStore().currentCompetition.id
        return useAuthorizationStore().canLaunchEnvironment(competitionId);
    }

    getArtifactIcon(type:ChallengeArtifactType):string
    {
        switch(type)
        {
            case ChallengeArtifactType.File: return 'attachment';
            case ChallengeArtifactType.Link: return 'public';
            case ChallengeArtifactType.Environment: return 'desktop_windows';
            default: return 'web_asset'
        }
    }

    getArtifactIconLabel(type:ChallengeArtifactType):string
    {
        switch(type)
        {
            case ChallengeArtifactType.File: return this.$t('ARTIFACTS_FILE_DOWNLOAD').toString();
            case ChallengeArtifactType.Link: return this.$t('ARTIFACTS_LINK_TO_CHALLENGE_ARTIFACT').toString();
            case ChallengeArtifactType.Environment: return this.$t('ARTIFACTS_CHALLENGE_ENVIRONMENT_OPEN').toString();
            default: return this.$t('ARTIFACTS_ARTIFACT').toString()
        }
    }

    onEnvironmentClick(challengeEnvironmentId: string)
    {
        const { href } = this.$router.resolve({ name: Route.ChallengeEnvironment.name, params: { challengeEnvironmentId }});
        this.environmentWindow = window.open(href);
    }

    async onFileClick(artifact:IChallengeArtifact)
    {
        let anchor = document.createElement('a');
        let file = await this.fileApiClient.getOneDownloadUrl(artifact.value);
        anchor.href = file.publicUrl;
        anchor.download = file.name;
        anchor.click();
    }

    onEditClicked(index:number)
    {
        this.selectedArtifactIndex = index;
        this.showAddEditArtifactDialog = true;
    }

    onAddClicked()
    {
        this.selectedArtifactIndex = -1;
        this.showAddEditArtifactDialog = true;
    }

    onArtifactDeleted()
    {
        this.artifacts.splice(this.selectedArtifactIndex, 1);
        this.$emit('value', this.artifacts);
    }

    onArtifactChanged(artifact:IChallengeArtifact)
    {
        if(this.selectedArtifactIndex === -1)
        {
            //new artifact
            this.artifacts.push(artifact);
        }
        else
        {
            //existing artifact
            this.artifacts[this.selectedArtifactIndex] = artifact;
            this.selectedArtifactIndex = -1;
        }

        this.$emit('value', this.artifacts);
    }

    async mounted() 
    {
        if(this.editable)
        {
            await useSubscriptionStore().fetchSubscribedProducts();
        }
    }

    beforeDestroy()
    {
        this.environmentWindow?.close();
    }
}
</script>

<style scoped>
.artifact-environment-button:not(:disabled)
{
    color: var(--v-application-base);
}
</style>