export default {
    en: {
        LIBRARY_TITLE: 'Challenge Library',
        LIBRARY_ADD_TOOLTIP: 'Add a New Challenge Entry to Your Library.',
        LIBRARY_LOADING: 'Loading...',
        LIBRARY_NO_DATA: 'You have no entries in the library.',
        LIBRARY_HEADER_NAME: 'Challenge',
        LIBRARY_HEADER_AUTHOR: 'Author',
        LIBRARY_HEADER_CREATION_DATE: 'Creation Date',
        LIBRARY_HEADER_CATEGORY: 'Category',
        LIBRARY_HEADER_STATUS: 'Status',
        LIBRARY_HEADER_STATUS_DISABLED_TOOLTIP: 'This entry is disabled from being used to create a new challenge.',
        LIBRARY_HEADER_STATUS_DEFAULT_TOOLTIP: 'This entry is a default challenge to be populated in a new competition.',
        LIBRARY_HEADER_STATUS_ORGANIZATIONAL_TOOLTIP: 'This entry is an organizational entry.',
        LIBRARY_HEADER_STATUS_PERSONAL_TOOLTIP: 'This entry is a personal entry.',
        LIBRARY_HEADER_RATING: 'Correct Flag Submissions',
        LIBRARY_HEADER_APPEARANCE: 'Appearance',
        LIBRARY_TOGGLE_SELECT_ALL_LABEL: 'All library entries',
        LIBRARY_SELECT_LABEL: 'Library entry: {name}',
        LIBRARY_HEADER_EXPORT: 'Export Table as .csv',
        LIBRARY_HEADER_ITEM_STRING: 'library entries',
        LIBRARY_HEADER_ADD_ITEM_STRING: 'challenges',
        LIBRARY_DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete <b>{name}</b> from your library?',
        LIBRARY_DELETE_ENTRIES_DIALOG_MESSAGE: 'Are you sure you want to delete {count} library entries?',
        LIBRARY_DELETE_JOB_PROGESS_DIALOG_TITLE: 'Deleting Library Entries',
        LIBRARY_DELETE_DIALOG_CONFIRM: 'Delete',
        LIBRARY_ACTIONS_LABEL: '{name} library item actions',
        LIBRARY_DELETE: 'Delete',
        LIBRARY_EDIT: 'Edit',
        LIBRARY_ADD_EDIT_DIALOG_ADD_TITLE: "Add a Library Entry",
        LIBRARY_ADD_EDIT_DIALOG_EDIT_TITLE: "Edit a Library Entry",
        LIBRARY_ADD_EDIT_DIALOG_NAME: 'Name',
        LIBRARY_ADD_EDIT_DIALOG_DESCRIPTION: 'Description',
        LIBRARY_ADD_EDIT_DIALOG_POINTS: 'Points',
        LIBRARY_ADD_EDIT_DIALOG_CATEGORY: 'Category',
        LIBRARY_ADD_EDIT_DIALOG_ENABLED: 'Enabled',
        LIBRARY_ADD_EDIT_DIALOG_FLAGS: 'Flags',
        LIBRARY_ADD_EDIT_DIALOG_ARTIFACTS: 'Artifacts',
        LIBRARY_ADD_EDIT_DIALOG_HINTS: 'Hints',
        LIBRARY_ADD_EDIT_DIALOG_SETTINGS: 'Settings',
        LIBRARY_ADD_EDIT_DIALOG_TAGS: 'Tags',
        LIBRARY_ADD_EDIT_DIALOG_DELETE: 'Delete',
        LIBRARY_ADD_EDIT_DIALOG_DELETE_CONFIRMATION: 'Are you sure you want to delete <b>{name}</b>?',
        LIBRARY_ADD_EDIT_DIALOG_UPLOAD_PROGRESS: 'Uploading {percent}%',
        LIBRARY_ADD_EDIT_DIALOG_GLOBAL: 'Global visibility',
        LIBRARY_ADD_EDIT_DIALOG_ORGANIZATIONAL: 'Organizational visibility',
        LIBRARY_ADD_EDIT_DIALOG_ORGANIZATION: 'Organization',
        LIBRARY_ENTRY_LABEL: '{name}, {category} challenge from {visibility} library',
        LIBRARY_ENTRY_LABEL_UNCATEGORIZED: 'Uncategorized',
        LIBRARY_LABEL_VISIBLIBILTY_ORGANIZATION: 'your organization',
        LIBRARY_LABEL_VISIBLIBILTY_GLOBAL: 'the global',
        LIBRARY_LABEL_VISIBLIBILTY_PERSONAL: 'your personal',
        LIBRARY_IMPORT_TOOLTIP: 'Import Challenges',
        LIBRARY_IMPORT_DIALOG_TITLE: 'Import Challenges',
        LIBRARY_IMPORT_DIALOG_MESSAGE: 'Please select a file to import.',
        LIBRARY_IMPORT_DIALOG_FILE_ADD: 'File input',
        LIBRARY_IMPORT_DIALOG_IMPORT: 'Import',
        LIBRARY_IMPORT_DIALOG_CONFIRM: 'Confirm',
        LIBRARY_IMPORT_DIALOG_CANCEL: 'Cancel',
        LIBRARY_IMPORT_DIALOG_SUCCESSFUL_IMPORTS: 'None of the challenges could be successfully imported. | 1 challenge has been successfully imported. | {n} challenges have been successfully imported.',
        LIBRARY_IMPORT_DIALOG_FAILED_IMPORTS_CONJUNCTIVE: 'However, ',
        LIBRARY_IMPORT_DIALOG_FAILED_IMPORTS: '{conjunctive}1 challenge could not be imported: | {conjunctive}{n} challenges could not be imported:',
        LIBRARY_EXPORT_TITLE: 'Exporting...',
        LIBRARY_EXPORT_CREATING_BUNDLE: 'creating bundle...',
        LIBRARY_EXPORT_AUTOMATIC_DOWNLOAD: 'Your download should start automatically. If it doesn\'t, ',
        LIBRARY_EXPORT_MANUAL_DOWNLOAD: 'click here to download your bundle.',
        LIBRARY_EXPORT_CONFIRM_BUTTON: 'Done',
        LIBRARY_EXPORT_DEFAULT_FILE_NAME: 'CloudCTF-Library-Export-{date}.ctfz',
        LIBRARY_ADD_EDIT_DIALOG_NOTES: 'Notes',
        LIBRARY_ADD_EDIT_DIALOG_SOLUTION: 'Solution',
        LIBRARY_ADD_EDIT_DIALOG_AUTHOR_NAME: 'Author Name',
        LIBRARY_ADD_EDIT_DIALOG_AUTHOR_EMAIL: 'Author Email',
        LIBRARY_ADD_EDIT_DIALOG_CREATION_DATE: 'Creation Date',
        LIBRARY_ADD_EDIT_DIALOG_CREATION_DATE_PLACEHOLDER: 'YYYY-mm-dd',
        LIBRARY_ADD_EDIT_DIALOG_PROTECTED_INFORMATION_NOTICE: 'The following information is not visible to regular CTF users.',
        LIBRARY_ADD_EDIT_DIALOG_TAB_CHALLENGE_INFORMATION: 'Challenge Information',
        LIBRARY_ADD_EDIT_DIALOG_TAB_ARTIFACTS_AND_HINTS: 'Artifacts and Hints',
        LIBRARY_ADD_EDIT_DIALOG_TAB_ADVANCED_SETTINGS: 'Advanced Settings',
        LIBRARY_ADD_EDIT_DIALOG_TAB_TAGS: 'Tags',
        LIBRARY_ADD_EDIT_DIALOG_TAB_NOTES_AND_SOLUTIONS: 'Notes and Solutions',
        LIBRARY_ADD_EDIT_DIALOG_TAB_PROTECTED_INFORMATION: 'Protected Information',
        LIBRARY_FILTER_TOOLTIP: 'Filter Library Entries',
        LIBRARY_FILTER_DIALOG_TITLE: 'Filter Challenge Library',
        LIBRARY_FILTER_CHIP_CATEGORY_PREFIX: 'Category: ',
        LIBRARY_FILTER_CHIP_AUTHOR_PREFIX: 'Author: ',
        LIBRARY_FILTER_CHIP_DEFAULT_CHALLENGE: 'Default Challenge',
        LIBRARY_CORRECT_SUBMISSIONS_LABEL: '{entryName} correct flag submissions: {percentage}%'
    }
};
