<template>
    <confirmation-dialog v-model="showConfirmationDialog" :title="$t('PLAYERS_INVITATION_SENT_DIALOG_TITLE')" :confirm="$t('PLAYERS_INVITATION_SENT_DIALOG_CONFIRM')" @confirm="confirm" :cancel="false" :loading="isLoading" id="playerInvitationSentDialog">
        <span v-html="$t('PLAYERS_INVITATION_SENT_DIALOG_MESSAGE')" />
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import PlayerView from './PlayerView';
import { Prop, Watch } from 'vue-property-decorator';
import { IInvitationApiClient } from '@cyber-range/cyber-range-api-invitation-client';
import { useApiClientStore } from '@stores/apiClientStore';

@Component
export default class InvitationSentDialog extends Vue 
{
    @Prop(Boolean) value: boolean;
    @Prop(Object) player:PlayerView;

    showConfirmationDialog:boolean = false;

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    // END TODO
    
    @Watch('value')
    onValueChanged()
    {
        this.showConfirmationDialog = this.value;
    }

    mounted() 
    {
        this.showConfirmationDialog = this.value;
    }
    
    async confirm()
    {
        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showConfirmationDialog = false;
        this.$emit('input', false);
    }
}
</script>