//Credit: https://github.com/getanwar/vuejs-countdown

<template>
    <span/>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import moment from 'moment';

@Component({})
export default class CountDownClock extends Vue {
    @Prop(String) end;
    @Prop(Boolean) stop;

    now = Math.trunc((new Date()).getTime() / 1000);
    date = undefined;
    diff = 0;
    interval = undefined;

    get seconds() {
        return Math.trunc(this.diff) % 60
    }
    get minutes() {
        return Math.trunc(this.diff / 60) % 60
    }
    get hours() {
        return Math.trunc(this.diff / 60 / 60) % 24
    }
    get days() {
        return Math.trunc(this.diff / 60 / 60 / 24)
    }

    get daysUnit() {
        return this.days > 1 ? this.$t('DAYS') : this.$t('DAY');
    }
    get hoursUnit() {
        return this.hours > 1 ? this.$t('HOURS') : this.$t('HOUR');
    }
    get minutesUnit() {
        return this.minutes > 1 ? this.$t('MINUTES') : this.$t('MINUTE');
    }
    get secondsUnit() {
        return this.seconds > 1 ? this.$t('SECONDS') : this.$t('SECOND');
    }

    @Watch('now')
    onNowChanged(value) {
        this.diff = this.date - this.now;
        if(this.diff <= 0 || this.stop){
            this.diff = 0;
            clearInterval(this.interval);
            this.$emit('timed', true);
        }
    }

    @Watch('end')
    onEndChanged()
    {
        this.refresh();
    }
    
    twoDigits(value) {
        if ( value.toString().length <= 1 ) {
            return '0'+value.toString()
        }
        return value.toString()
    }
    
    created() {
        this.refresh();
    }

    refresh() {
        clearInterval(this.interval);
        let endTime = this.end || new Date();
        this.date = Math.trunc(moment(endTime).unix());
        if (!this.date) {
            throw new Error(`Invalid date '${endTime}'`);
        }
        this.onNowChanged(Math.trunc((new Date()).getTime() / 1000));
        let interval = setInterval(() => {
            this.now = Math.trunc((new Date()).getTime() / 1000);
        }, 1000);
    }
    
    destroyed() {
        clearInterval(this.interval);
    }
}
</script>