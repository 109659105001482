export default {
    en: {
        TEAM_TITLE: 'My Team',
        TEAM_EDIT: 'Edit',
        TEAM_ERROR: 'Could not show the information at the moment.',
        TEAM_PENDING_APPROVAL_TITLE: 'You are requesting to join this team.',
        TEAM_PENDING_APPROVAL_SUBTITLE: 'You can start playing this competition once the team leader has approved your request.',
        TEAM_PENDING_APPROVAL_IS_FULL_SUBTITLE: 'You can start playing this competition once the team leader has approved your request; however, the team is full.',
        TEAM_PENDING_APPROVAL_IS_FULL_CONSIDER_CHANGING_TEAMS: 'The team leader will need to remove players before your request can be approved. You may want to consider joining another team.',
        TEAM_LEAVE: 'Leave this team',
        TEAM_LEAVE_DIALOG_MESSAGE: 'Are you sure you want to leave this team?',
        TEAM_UPDATING: 'Updating',

        TEAM_SUBMISSION_CORRECTION_DIALOG_TITLE: 'Add a Submission',
        TEAM_SUBMISSION_CORRECTION_DIALOG_MESSAEG: 'You can add a submission to solve a challenge on behalf of this team',
        TEAM_SUBMISSION_CORRECTION_DIALOG_NO_CHALLENGES_TEXT: 'No challenges in this competition',
        TEAM_SUBMISSION_CORRECTION_DIALOG_NO_PLAYERS_TEXT: 'No players in this team'
    }
};
