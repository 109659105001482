import { CheckboxFilter } from '../../utils/filterDialog/checkboxFilter';
import { ICheckboxFilter } from '../../utils/filterDialog/iCheckboxFilter';
import { ISelectFilter } from '../../utils/filterDialog/iSelectFilter';
import { ITextFilter } from '../../utils/filterDialog/iTextFilter';
import { SelectFilter } from '../../utils/filterDialog/selectFilter';
import { TextFilter } from '../../utils/filterDialog/textFilter';


export class LibraryFilterRecord
{
    category: ISelectFilter = new SelectFilter('Category')
    author: ITextFilter = new TextFilter('Author')
    defaultChallenge: ICheckboxFilter = new CheckboxFilter('Default Challenge')
}
