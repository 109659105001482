<template>
    <confirmation-dialog v-model="showDialog" :title="$t('CHALLENGE_TEST_DIALOG_TITLE', { challengeName })" :dense="true" confirm="done" :cancel="false" :loading="isLoading" @confirm="confirm">
        <loading v-if="dataFetching" class="mt-5 ml-5 mb-5 loading" color="dialogText" />
        <v-col v-else>
            <component class="px-3" :is="flagInputComponent" v-model="testFlag" :challenge="challenge" :disabled="isLoading"/>
            <v-row v-for="flag in flags" :key="`${flag.type}-${flag.value}`" class="mx-2 flagRow">
                <v-icon v-if="isMatch(flag)" class="align-start" color="dialogSuccessText" aria-label="pass">check</v-icon>
                <v-icon v-else class="align-start" color="dialogFailureText" aria-label="fail">clear</v-icon>
                <span>{{ flag.displayText }}: 
                    <template v-if="!Array.isArray(flag.value) || !challenge.choice">{{flag.displayValue}}</template>
                    <ul v-else-if="flag.displayValue.length > 1">
                        <li v-for="value in flag.displayValue" :key="value">{{ value }}</li>
                    </ul>
                    <template v-else>{{ flag.displayValue[0] }}</template>
                </span>
            </v-row>
        </v-col>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { IChallenge, IChallengeApiClient, IFlag, FlagType, Challenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { ILibraryApiClient, ILibraryEntry } from '@cyber-range/cyber-range-api-ctf-library-client';
import { selectChallengeFlagInput } from '@/components/challenges/dialogs/challengeDialog/challengeFlagInput/selectChallengeFlagInput';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useApiClientStore } from '@stores/apiClientStore';

@Component
export default class TestChallengeDialog extends Vue 
{ 
    @Prop(Boolean) value:boolean;
    @Prop(String) challengeId:string;
    @Prop(String) libraryEntryId:string;
    @Prop([String,Array]) flag:string|string[];

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get challengeApiClient(): IChallengeApiClient
    {
        return useApiClientStore().challengeApiClient;
    }
    get libraryApiClient(): ILibraryApiClient
    {
        return useApiClientStore().libraryApiClient;
    }
    // END TODO

    testFlag:string|string[] = '';
    dataFetching:boolean = false;
    challenge: IChallenge|ILibraryEntry = new Challenge();

    get challengeName()
    {
        return this.challenge?.name || ''
    }
    get flags()
    {
        const flags: (IFlag & { displayText?: string, displayValue?: string|string[] })[] = this.challenge?.flags || [];
        for (const flag of flags)
        {
            if (flag.type === FlagType.MultipleChoice)
            {
                const flagValues = Array.isArray(flag.value) ? flag.value : [flag.value];

                flag.displayText = (flagValues.length > 1)
                    ? this.$t('CHALLENGE_TEST_DIALOG_FLAG_TYPE_MULTIPLE_SELECT').toString()
                    : this.$t('CHALLENGE_TEST_DIALOG_FLAG_TYPE_MULTIPLE_CHOICE').toString();

                const choices = this.challenge?.choice?.values?.reduce((map, choice) => map.set(choice.id, choice.text), new Map<string, string>());
                flag.displayValue = flagValues.map(key => choices.get(key));
            }
            else
            {
                flag.displayText = flag.type;
                flag.displayValue = flag.value;
            }
        }
        return flags;

    }

    get flagInputComponent()
    {
        return selectChallengeFlagInput(this.challenge);
    }

    get showDialog()
    {
        return this.value;
    }
    set showDialog(value)
    {
        this.$emit('input', value);
    }

    async load() 
    {
        try
        {
            this.dataFetching = true;

            if(this.challengeId)
            {
                this.challenge = await this.challengeApiClient.getOne(this.challengeId);
            }
            else if(this.libraryEntryId)
            {
                this.challenge = await this.libraryApiClient.getOne(this.libraryEntryId);
            }
        }
        catch
        {
            this.close();
        }
        finally
        {
            this.dataFetching = false;
        }
    }

    isMatch(flag:IFlag)
    {
        if (flag.type === FlagType.Regex)
        {
            const flagValue = flag.value as string; // flag value should be a string for regexp
            const testFlag = this.testFlag as string;

            const patternEndIndex = flagValue.lastIndexOf('/');
            const pattern = flagValue.substring(1,patternEndIndex);
            const flags = flagValue.substring(patternEndIndex+1);
            return new RegExp(pattern, flags).test(testFlag);
        }
        else if (flag.type === FlagType.MultipleChoice)
        {
            const flagValues = Array.isArray(flag.value) ? flag.value : [flag.value]
            const testFlags = Array.isArray(this.testFlag) ? this.testFlag : [this.testFlag];

            return testFlags?.length === flagValues?.length && testFlags.every(testFlags => flagValues.includes(testFlags));
        }
        else
        {
            return flag.value === this.testFlag;
        }
    }

    confirm()
    {
        this.close();
    }

    close()
    {
        this.showDialog = false;
    }

    @Watch('showDialog', { immediate: true })
    onShowDialogChanged(value)
    {
        if (value)
        {
            this.load();
            this.testFlag = this.flag || '';
        }
    }
}
</script>
