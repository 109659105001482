export default class Route
{
    static Landing = {name: "landing", path: "/"};
    static Challenges = {name: "challenges", path: "/challenges"};
    static CategorizedChallenges = {name: "categorizedChallenges", path: "/challenges/:category"};
    static Competitions = {name: "competitions", path: "/competitions"};
    static Manage = {name: "manage", path: "/manage"};
    static Settings = {name: "settings", path: "/manage/settings"};
    static Players = {name: "players", path: "/manage/players"};
    static Teams = {name: "teams", path: "/manage/teams"};
    static ManageChallenges = { name: "manageChallenges", path: "/manage/challenges" };
    static Library = {name: "library", path: "/manage/library"};
    static Welcome = {name: "welcome", path: "/welcome"};
    static Scoreboard = {name: "scoreboard", path: "/scoreboard"};
    static Team = {name: "team", path: "/team"};
    static ChallengeEnvironment = { name: "challengeEnvironment", path: "/challenge-environments/:challengeEnvironmentId" };
};