<template>
    <v-card outlined :dark="isDark" :light="isLight" class="elevation-0 countdownWindow window alphaBackground">
        <template v-if="timed">
            <v-card-title class='title'>&nbsp;</v-card-title>
            <v-card-text align="center" class="body pt-4">
                {{$t('COUNTDOWN_WINDOW_TIMED')}}
            </v-card-text>
        </template>
        <template v-else>
            <v-card-title class='title'>{{$t('COUNTDOWN_WINDOW_TITLE')}}</v-card-title>
            <v-card-text class='body' align="center">
                <countdown-clock id="countdownWindowCountdownClock" :end="end" @timed="onTimed" />
            </v-card-text>
        </template>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Window from './Window.default.vue';
import { useThemeStore } from '@/stores/themeStore';

@Component
export default class CountdownWindow extends mixins(Window) 
{   
    @Prop(String) end:string;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isLight;
    }
    // END TODO

    timed:boolean = false;

    onTimed()
    {
        this.timed = true;
        this.$emit('timed', true);
    }
}
</script>