<script lang="ts">
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import BaseScoreTable from './scoreTable.vue';

export default class ScoreTable extends mixins(BaseScoreTable) 
{  
}
</script>
<style>
.v-data-table.theme--dark.scoreTable
{
    
}
</style>