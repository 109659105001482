<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import BaseSettings from './Settings.vue';

@Component({components:{}})
export default class Settings extends mixins(BaseSettings) 
{   
}
</script>

<style>
.comicbook #settingsCard
{
    font-family: 'ComicNeue-Bold';
    letter-spacing: normal;
}
.comicbook .floatingIconTooltip{
    position: absolute;
    margin-top: 4px;
    margin-left: 210px;
}
.comicbook .show-scoreboard-tooltip{
    position: absolute;
    margin-top: 4px;
    margin-left: 235px;
    z-index: 1;
}
.comicbook #settingsConfirmButton:not(.v-btn--disabled) .v-btn__content
{
  color: black;
}
</style>