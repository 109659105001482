export default function createMessages(mode:string /* 'VACR' or 'USCR */)
{
    let messages = {};
    
    // Dynamically search for strings files
    let stringFiles = import.meta.glob('./definitions/**/*Strings.ts', {eager: true});
    
    for (const filename in stringFiles)
    {

        let string = stringFiles[filename].default;

        for (const key of Object.keys(string)) 
        {
            if (!messages[key])
            {
                messages[key] = {};
            }
            
            for ( const stringKey of Object.keys(string[key]) )
            {
                if( string[key][stringKey] instanceof Function)
                {
                    messages[key][stringKey] = string[key][stringKey];
                }
                else if( string[key][stringKey] instanceof Object )
                {
                    for( const objectKey of Object.keys(string[key][stringKey]) )
                    {
                        if(objectKey === mode)
                        {
                            messages[key][stringKey] = string[key][stringKey][objectKey];
                        }
                    }
                }
                else
                {
                    messages[key][stringKey] = string[key][stringKey];
                }
            }
        }
    }

    return messages;
}