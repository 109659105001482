<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Window extends Vue 
{   
}
</script>
<style>
.window
{
    min-height: 191px;
    max-height: 191px;
    overflow: hidden;
}
.theme--dark .window
{
    
}
.window.theme--dark.v-sheet,
.window .theme--dark.v-data-table
{
    
}
</style>