<template>
    <confirmation-dialog v-model="showDialog" :title="$t('SAVE_CHALLENGE_TO_LIBRARY_TITLE')" :confirm="$t('SAVE_CHALLENGE_TO_LIBRARY_CONFIRM_TEXT')" :cancel="$t('SAVE_CHALLENGE_TO_LIBRARY_CANCEL_TEXT')" @confirm="confirm" @cancel="cancel" :loading="isLoading" class="saveChallengeToExistingLibraryDialog">
        {{$t('SAVE_CHALLENGE_TO_LIBRARY_TEXT')}}
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';

@Component
export default class SaveChallengeToExistingLibraryDialog extends Vue
{
    @Prop(Boolean) value: boolean;

    showDialog:boolean = false;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    // END TODO

    @Watch('value', { immediate: true })
    async onValueChanged(value: boolean)
    {
        if (value)
        {
            await this.load();
        }
    }

    async mounted()
    {
        this.showDialog = this.value;

        if(this.showDialog) await this.load();     
    }

    async load()
    {
        this.showDialog = true;
    }

    async confirm()
    {
        this.$emit('confirm');
        this.close();
    }

    cancel()
    {
        this.$emit('cancel');
        this.close();
    }

    close()
    {
        this.showDialog = false;
        this.$emit('input', false);
    }
}
</script>