import { ITeam } from '@cyber-range/cyber-range-api-ctf-team-client';

export default class TeamView
{
    get name():string
    {
        return this.team.name;
    }

    leaderName:string;

    get leaderUserId():string
    {
        return this.team.leaderUserId;
    }

    get stars():number
    {
        return (this.team.statistics?.successAttempts > 0 || this.team.statistics?.failedAttempts > 0) 
                ? 
                (this.team.statistics.successAttempts / (this.team.statistics.successAttempts + this.team.statistics.failedAttempts)) * 5  //5 stars
                :
                0;
    }
    
    get score():number
    {
        return this.team.statistics?.score || 0;
    }

    get hidden():boolean
    {
        return this.team.hidden || false;
    }

    constructor(public team:ITeam)
    {
    }
};