<script lang="ts">
import BaseChallenges from './Challenges.default.vue';
import { mixins } from 'vue-class-component';

export default class Header extends mixins(BaseChallenges) {}
</script>

<style scoped>
@import './challenges.css';
.categoryButton, #competitionEndingCountdown {
    font-family: ShareTechMono !important
}
</style>
