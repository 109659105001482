export default {
    en: {
        CHALLENGES_TITLE: "Challenges",
        NO_CHALLENGES_ADMINS: "This competition has no challenges.",
        NO_CHALLENGES_PLAYERS: "This competition has no challenges.",
        FETCHING_CHALLENGES: "Please wait while challenges are being loaded.",
        CHALLENGES_COUNTDOWN: "Challenges will appear in:",
        COMPETITION_HAS_ENDED: "This competition has ended.",

        CHALLENGE_ADD_MENU_LABEL: 'Add a challenge menu',
        CHALLENGE_ADD_TOOLTIP: 'Add a Challenge',
        CHALLENGE_ADD_FROM_LIBRARY: 'Add a Challenge from Library',
        CHALLENGE_ADD_FROM_SCRATCH: 'Add a Challenge from Scratch',
        CHALLENGE_DIALOG_CONFIRM: 'Submit',
        CHALLENGE_DIALOG_TEST: 'Test',
        CHALLENGE_DIALOG_CANCEL: 'Cancel',
        CHALLENGE_DIALOG_POINTS: '{points} pts',
        CHALLENGE_DIALOG_EDIT: 'Edit',
        CHALLENGE_DIALOG_SAVE: 'Save this challenge to the challenge library',
        CHALLENGE_DIALOG_SAVE_VISIBILITY_GLOBAL: 'Global',
        CHALLENGE_DIALOG_SAVE_VISIBILITY_ORGANIZATIONAL: 'Organizational',
        CHALLENGE_DIALOG_SAVE_VISIBILITY_PERSONAL: 'Personal',
        CHALLENGE_DIALOG_SAVE_DISABLED_UNAUTHORIZED_ENVIRONMENT_ARTIFACTS: 'You are not authorized to save this challenge because it contains an environment artifact.',
        CHALLENGE_DIALOG_FLAG: 'Flag',
        CHALLENGE_DIALOG_SOLVED_BY_NONE: '',
        CHALLENGE_DIALOG_SOLVED_BY_SINGULAR: 'Solved by one other',
        CHALLENGE_DIALOG_SOLVED_BY_PLURAL: 'Solved by {number} others',
        CHALLENGE_DIALOG_MAX_NUMBER_OF_ATTEMPTS: '0 attempts | {n} attempt  | {n} attempts',
        CHALLENGE_DIALOG_REMAINING_NUMBER_OF_ATTEMPTS: '0 attempts left | {n} attempt left | {n} attempts left',

        CHALLENGES_HINT_DIALOG_CONFIRM: 'Confirm',
        CHALLENGES_HINT_DIALOG_UNLOCKED_CONFIRM: 'OK',
        CHALLENGES_HINT_DIALOG_CANCEL: 'Cancel',
        CHALLENGES_HINT_DIALOG_QUESTION_TITLE: 'Are you sure?',
        CHALLENGES_HINT_DIALOG_UNLOCKED_TITLE: 'Hint',
        CHALLENGES_HINT_DIALOG_MESSAGE: 'Unlock this hint for {cost} points? If you unlock this hint, {cost} points will be deducted from your team score.',

        CHALLENGE_DIALOG_SUBMISSION_RESULT_CORRECT_TITLE: "That's correct!",
        CHALLENGE_DIALOG_SUBMISSION_RESULT_CORRECT_MESSAGE: "You have earned <b>{points}</b> points.",
        CHALLENGE_DIALOG_SUBMISSION_RESULT_INCORRECT_TITLE: "Nice try",
        CHALLENGE_DIALOG_SUBMISSION_RESULT_INCORRECT_MESSAGE: "The given flag is not correct.",
        CHALLENGE_DIALOG_SUBMISSION_RESULT_CONFIRM: 'OK',
        
        PLAYERS_REINVITE_DIALOG_MESSAGE: 'Are you sure you want to resend an invitation to <b>{name}</b>?', 
        PLAYERS_REINVITE_DIALOG_CONFIRM: 'Confirm',
        
        PLAYERS_INVITATION_SENT_DIALOG_TITLE: 'Confirmation',
        PLAYERS_INVITATION_SENT_DIALOG_MESSAGE: 'The invitation has been sent.', 
        PLAYERS_INVITATION_SENT_DIALOG_CONFIRM: 'OK',
        
        CHALLENGE_TEAM_REQUIRED_DIALOG_TITLE: "Warning",
        CHALLENGE_TEAM_REQUIRED_DIALOG_MESSAGE: 'Only a player in this competition can play. You are logging in as {role}.',
        CHALLENGE_TEAM_REQUIRED_DIALOG_CONFIRM: 'OK',

        CHALLENGES_LOADING: 'Loading...',
        CHALLENGES_NO_DATA: 'This competition has no challenges',
        CHALLENGES_HEADER_NAME: 'Challenge',
        CHALLENGES_HEADER_CATEGORY: 'Category',
        CHALLENGES_HEADER_POINTS: 'Points',
        CHALLENGES_HEADER_SUCCESSFUL_ATTEMPTS: 'Successful Attempts',
        CHALLENGES_HEADER_FAILED_ATTEMPTS: 'Failed Attempts',
        CHALLENGES_HEADER_CREATION_DATE: 'Creation Date',

        CHALLENGE_ACTIONS_LABEL: '{name} Challenge Actions',
        CHALLENGE_EDIT: 'Edit',
        CHALLENGE_DELETE: 'Delete',

        CHALLENGES_DELETE_DIALOG_CONFIRM: 'Confirm',
        CHALLENGES_DELETE_CHALLENGES_DIALOG_MESSAGE: 'Are you sure you want to delete {count} challenges?',
        CHALLENGES_DELETE_JOB_PROGESS_DIALOG_TITLE: 'Deleting Challenges',

        CHALLENGES_ADD_CHALLENGES_FROM_LIBRARY_DIALOG_TITLE: 'Creating Challenges',
        CHALLENGES_ADD_CHALLENGES_FROM_LIBRARY_DIALOG_MESSAGE: 'Are you sure you want to create {challengeCount} challenges from {categoryCount} categories into this competition?',
        CHALLENGES_ADD_CHALLENGES_FROM_LIBRARY_COMPETITION_FULL_TITLE: 'The competition is full',
        CHALLENGES_ADD_CHALLENGES_FROM_LIBRARY_COMPETITION_FULL_CONFIRM: 'adjust selection',
        CHALLENGES_ADD_CHALLENGES_FROM_LIBRARY_CHALLENGES_FULL_MESSAGE: 'Your selection includes more challenges than the competition allows. Please select fewer challenges or contact an admin to raise the limit.',
        CHALLENGES_ADD_CHALLENGES_FROM_LIBRARY_STORAGE_FULL_MESSAGE: 'Your selection includes more artifact storage than is provided for the competition. Please select fewer challenges or contact an admin to raise the limit.',

        CHALLENGE_TOGGLE_SELECT_ALL_LABEL: 'All challenges',
        CHALLENGE_SELECT_LABEL: 'Challenge: {name}',
        CHALLENGES_ADD_CHALLENGES_FROM_LIBRARY_REDIRECT_TEXT: 'Go to challenges',
        CHALLENGE_DIALOG_EDIT_THIS_CHALLENGE:'edit this challenge',

        // Export
        CHALLENGE_EXPORT_TITLE: 'Exporting...',
        CHALLENGE_EXPORT_CREATING_BUNDLE: 'Creating bundle...',
        CHALLENGE_EXPORT_AUTOMATIC_DOWNLOAD: 'Your download should start automatically. If it doesn\'t, ',
        CHALLENGE_EXPORT_MANUAL_DOWNLOAD: 'click here to download your bundle.',
        CHALLENGE_EXPORT_CONFIRM_BUTTON: 'Done',
        CHALLENGE_EXPORT_DEFAULT_FILE_NAME: 'CloudCTF-Challenge-Export-{date}.ctfz',
        CHALLENGE_EXPORT_TOO_MANY_SELECTED_TITLE: 'Invalid Selection',
        CHALLENGE_EXPORT_TOO_MANY_SELECTED_TEXT: 'Your selection includes more challenges than is allowed for export ({limit}). Please select fewer challenges and try again.',
        CHALLENGE_EXPORT_TOO_MANY_SELECTED_CONFIRM: 'Close',
    }
};
