<script lang="ts">
import { mixins } from 'vue-class-component';
import BaseTeams from './Teams.vue';

export default class Teams extends mixins(BaseTeams) 
{  
}
</script>

<style scoped>
.theme--dark .v-data-table
{
    
}
</style>
<style>
.v-data-table td
{
    color: var(--v-text-darken1) !important;
}
</style>