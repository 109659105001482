<template>
    <delete-confirmation-dialog v-model="showDialog" :checkbox="true" @confirm="confirm" @cancel="cancel" :disabled="isLoading">
        <p v-html="$t('CHALLENGE_DELETE_DIALOG_DELETE_CONFIRMATION',{ name })" class="deleteChallengeDialogBodyMessage"/>
    </delete-confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { IChallenge, IChallengeApiClient } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { encode } from 'html-entities';
import { useApiClientStore } from '@stores/apiClientStore';

@Component
export default class DeleteChallengeDialog extends Vue {
    @Prop(Boolean) value:boolean;
    @Prop(Object) challenge: IChallenge;

    // TODO: Change this to composition api
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get challengeApiClient(): IChallengeApiClient
    {
        return useApiClientStore().challengeApiClient;
    }
    // END TODO

    get name()
    {
        return encode(this.challenge.name);
    }

    get showDialog()
    {
        return this.value;
    }
    set showDialog(value)
    {
        this.$emit('input', value);
    }

    async confirm(): Promise<void>
    {
        if (this.challenge.id)
        {
            await this.challengeApiClient.delete(this.challenge.id);
        }
        this.$emit('confirm', true);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.$emit('input', false);
    }
}
</script>
