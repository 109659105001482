<script lang="ts">
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import BaseScoreChart from './scoreChart.vue';

export default class ScoreChart extends mixins(BaseScoreChart) 
{
    gridlineColor = this.isDark ? 'dimgrey' :  'darkgrey';
    textColor = this.isDark ? 'lightgrey' : 'dimgrey';
}
</script>
<style scoped>
.scoreChartDark
{
  
}
.usCyberGamesSeasonII .scoreChartDark
{
  background-color: rgba(0, 0, 0, 0.7)
}
</style>