<template>
    <div>
        <v-progress-circular indeterminate size="20" :width="3" :color="color || 'unset'" :dark="dark" :light="light" class="progress"></v-progress-circular>
        <template v-if="message !== false">{{ message || $t('LOADING_MESSAGE')}}</template>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";
@Component({})
export default class Loading extends Vue {
    @Prop() message;
    @Prop(String) color:string
    @Prop(Boolean) dark:boolean;
    @Prop(Boolean) light:boolean;
}
</script>

<style>
.progress {
    vertical-align: top;
    padding-right: 2.5em;
}
</style>