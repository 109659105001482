enum NotificationEvent {
    CompetitionUpdated = 'compatition_updated',
    ChallengesUpdated = 'challenges_updated',
    libraryEntriesUpdated = 'library_entries_updated',
    TeamUpdated = 'team_updated',
    ScoreUpdated = 'score_updated',
    SubmissionUpdated = 'submission_updated',
    ChallengeStatisticsUpdated = "ChallengeStatisticsUpdated",
    TeamMembershipUpdated = 'team_membership_updated',
    TeamMembersUpdated = 'team_members_updated',
    PlayerUpdated = 'player_updated',
    AlertUpdated = 'alert_updated'
};

export default NotificationEvent;