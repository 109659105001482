<template>
    <v-row class="mt-1">
        <v-col cols="12" sm="6" md="4" lg="3" v-for="challenge in getChallenges(category)" :key="challenge.id">
            <challenge :challenge="challenge" :id="`challenge_${challenge.id}`" />
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import StoreGetter from '../../interfaces/storeGetter';
import { IChallenge } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import TitleStrings from '@/entities/strings/definitions/titleStrings';

@Component({
    metaInfo() {
        return { title: `${this.category} | ${TitleStrings.en.TITLE_CHALLENGES}` }
    }
})
export default class CategorizedChallenges extends Vue 
{   
    @Getter(StoreGetter.GetChallenges) getChallenges: (category?:string) => IChallenge[];

    get category():string
    {
        return this.$route.params.category.toLowerCase();
    }
}
</script>