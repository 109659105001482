<template>
    <div>
        <v-row row wrap class="ma-2 py-3" justify="space-between">
            <h2 class="px-3">{{$t('LIBRARY_TITLE')}}</h2>
            <div>
                <v-layout>
                    <v-flex ml-3>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="showFilterLibraryEntriesDialog = true" v-on="on" small fab outlined :aria-label="$t('LIBRARY_FILTER_TOOLTIP')" class="filterButton">
                                    <v-icon>filter_list</v-icon>
                                </v-btn>
                            </template>
                            {{ $t('LIBRARY_FILTER_TOOLTIP') }}
                        </v-tooltip>
                    </v-flex>
                    <v-flex ml-3>
                        <v-tooltip v-if="canCreateLibraryEntry(competition.id, competition.organizationId)" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="onAddClicked" v-on="on" small fab outlined :aria-label="$t('LIBRARY_ADD_TOOLTIP')">
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </template>
                            {{$t('LIBRARY_ADD_TOOLTIP')}}
                        </v-tooltip>
                    </v-flex>
                    <v-flex ml-3>
                        <v-tooltip v-if="canImportLibraryEntry()" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="onImportClicked" v-on="on" small fab outlined :aria-label="$t('LIBRARY_IMPORT_TOOLTIP')">
                                    <v-icon>upload</v-icon>
                                </v-btn>
                            </template>
                            {{$t('LIBRARY_IMPORT_TOOLTIP')}}
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </div>
        </v-row>
        <v-row class="mb-4">
            <v-chip v-for="chip in chips" :key="chip" class="ml-4" color="secondary" close @click:close="removeFilter(chip)">{{ chip }}</v-chip>
        </v-row>
        <v-data-table show-select :page.sync="page" v-model="selectedEntryViews" item-key="entry.id" :dark="isDark" :headers="headers" :items="filteredEntries" :loading="isLoading" :loading-text="$t('LIBRARY_LOADING')" :no-data-text="$t('LIBRARY_NO_DATA')" class="elevation-0 alphaBackground" id="manageLibraryTable"  @toggle-select-all="onToggleSelectAllEntries" @item-selected="onEntrySelected" @current-items="onTableItemsChanged">
            <template v-slot:top>
                <table-header deleteButton :exportButton="canExportLibraryEntries()" addButton :selected="selectedEntries.length" :page="pageSize" :total="filteredEntries.length" :item="$t('LIBRARY_HEADER_ITEM_STRING')" :addItem="$t('LIBRARY_HEADER_ADD_ITEM_STRING')" @select-all="onSelectAllEntries" @clear="onClearSelectedEntries" @delete="onDeleteSelectedEntries" @add="onAddSelectedEntries" @export="onExportSelectedEntries"/>
            </template>
             <template v-slot:item.action="{ item }">
                <accessible-menu offset-y :label="$t('LIBRARY_ACTIONS_LABEL', { name: item.name })">
                    <v-list>
                        <v-list-item v-if="canUpdateLibraryEntry" @click="onEditClicked(item.entry)">
                            <v-list-item-avatar>
                                <v-icon>edit</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('LIBRARY_EDIT')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="canDeleteLibraryEntry" @click="onDeleteClicked(item.entry)">
                            <v-list-item-avatar>
                                <v-icon>delete</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('LIBRARY_DELETE')}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </accessible-menu>

            </template>
            <template v-slot:item.stars="{ item }">
                <stars :value="item.stars" :entryName="item.name" ariaLabelString="LIBRARY_CORRECT_SUBMISSIONS_LABEL"/>
            </template>
            <template v-slot:item.status="{ item }">
                <template v-if="!item.isEnabled">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small :dark="isDark">pause</v-icon>
                        </template>
                        <span>{{$t('LIBRARY_HEADER_STATUS_DISABLED_TOOLTIP')}}</span>
                    </v-tooltip>
                    <span class="visually-hidden">{{$t('LIBRARY_HEADER_STATUS_DISABLED_TOOLTIP')}}</span>
                </template>
                <template v-if="item.isDefaultEntry">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small :dark="isDark">mdi-download</v-icon>
                        </template>
                        <span>{{$t('LIBRARY_HEADER_STATUS_DEFAULT_TOOLTIP')}}</span>
                    </v-tooltip>
                    <span class="visually-hidden">{{$t('LIBRARY_HEADER_STATUS_DEFAULT_TOOLTIP')}}</span>
                </template>
                <template  v-if="item.isOrganizationEntry">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small :dark="isDark">account_balance</v-icon>
                        </template>
                        <span>{{$t('LIBRARY_HEADER_STATUS_ORGANIZATIONAL_TOOLTIP')}}</span>
                    </v-tooltip>
                    <span class="visually-hidden">{{$t('LIBRARY_HEADER_STATUS_ORGANIZATIONAL_TOOLTIP')}}</span>
                </template>
                <template v-if="item.isPersonalEntry">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small :dark="isDark">lock</v-icon>
                        </template>
                        <span>{{$t('LIBRARY_HEADER_STATUS_PERSONAL_TOOLTIP')}}</span>
                    </v-tooltip>
                    <span class="visually-hidden">{{$t('LIBRARY_HEADER_STATUS_PERSONAL_TOOLTIP')}}</span>
                </template>
            </template>
            <template v-slot:header.action>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }"> 
                        <v-icon v-on="on" @click="onExportClicked" :aria-label="$t('LIBRARY_HEADER_EXPORT')" :dark="isDark">save_alt</v-icon>
                    </template>
                    <span> {{$t('LIBRARY_HEADER_EXPORT')}} </span>
                </v-tooltip>
            </template>
            <template v-slot:header.data-table-select="{ props, on }">
                <v-checkbox :aria-label="$t('LIBRARY_TOGGLE_SELECT_ALL_LABEL')" class="pa-0 ma-0"
                    hide-details @change="on.input" :input-value="props.value" 
                    :indeterminate="props.indeterminate" />
            </template>
            <template v-slot:item.data-table-select="{ item, isSelected, select }">
                <v-checkbox class="pa-0 ma-0" hide-details
                    :aria-label="$t('LIBRARY_SELECT_LABEL', {name: item.name})" 
                    @change="select" :input-value="isSelected" />
            </template>
        </v-data-table>
        <filter-dialog v-model="showFilterLibraryEntriesDialog" :filters.sync="filters" :title="$t('LIBRARY_FILTER_DIALOG_TITLE')" @update:filters="onFiltersChanged"/>
        <add-edit-library-entry-dialog  :authors="authors" v-model="showEditDialog" :libraryEntryId="selectedEntry.id" @confirm="refresh" />
        <delete-library-entry-dialog v-model="showDeleteDialog" :entry="selectedEntry" @confirm="refresh" />
        <delete-library-entries-dialog v-model="showDeleteEntriesDialog" :entries="selectedEntries" @confirm="refresh" />
        <import-library-entries-dialog v-model="showImportDialog" @confirm="refresh" />
        <export-library-entries-dialog v-model="showExportEntriesDialog" :entries="selectedEntries" @confirm="refresh"/>
        <add-challenges-from-library-dialog v-model="showAddSelectedEntriesDialog" @confirm="onChallengesAdded" :libraryEntries="selectedEntries" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import StoreGetter from '@interfaces/storeGetter';
import { ILibraryEntry, LibraryEntry } from '@cyber-range/cyber-range-api-ctf-library-client';
import LibraryEntryView from './LibraryEntryView';
import { csvExport } from '@/utils/csvExport';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import TitleStrings from '@/entities/strings/definitions/titleStrings';
import { Watch } from 'vue-property-decorator';
import { LibraryFilterRecord } from './libraryFilterRecord';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useLibraryEntryStore } from '@stores/libraryEntryStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';

@Component({ metaInfo: { title: TitleStrings.en.TITLE_MANAGE_LIBRARY }})
export default class Library extends Vue 
{
    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get authors(): {name: string, email: string}[]
    {
        return useLibraryEntryStore().libraryAuthors;
    }
    get categories(): string[]
    {
        return useLibraryEntryStore().libraryCategories;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    canCreateLibraryEntry(competitionId:string, organizationId:string): boolean
    {
        return useAuthorizationStore().canCreateLibraryEntry(competitionId, organizationId);
    }
    canImportLibraryEntry(): boolean
    {
        return useAuthorizationStore().canImportLibraryEntry();
    }
    canExportLibraryEntries(): boolean
    {
        return useAuthorizationStore().canExportLibraryEntries();
    }
    // END TODO

    headers = [];
    visibleEntryIds:string[] = [];
    selectedEntry:ILibraryEntry = new LibraryEntry();
    selectedEntryViews:LibraryEntryView[] = []
    showDeleteDialog = false;
    showDeleteEntriesDialog = false;
    showEditDialog = false;
    showImportDialog = false;
    showExportEntriesDialog = false;
    showAddSelectedEntriesDialog = false;
    showFilterLibraryEntriesDialog = false;
    filters: Partial<LibraryFilterRecord> = {};
    page: number = 1;

    get canUpdateLibraryEntry(): boolean
    {
        return useAuthorizationStore().canUpdateLibraryEntry(this.competition.id, this.competition.organizationId);
    }
    get canDeleteLibraryEntry(): boolean
    {
        return useAuthorizationStore().canDeleteLibraryEntry(this.competition.id, this.competition.organizationId);
    }

    @Watch('categories', { immediate: true, deep: true })
    onEntriesChanged()
    {
        if (!this.filters.category) return;

        this.filters.category.items = this.categories;
        this.filters.category.value = this.filters.category.value.filter(value => this.categories.includes(value))
    }

    onFiltersChanged() {
        this.page = 1;
    }

    get entries(): LibraryEntryView[]
    {
        const entries: ILibraryEntry[] = useLibraryEntryStore().sortedLibraryEntries;
        const views: LibraryEntryView[] = []

        for (const entry of entries)
        {
            const view = new LibraryEntryView(entry);
            if ((view.isGlobalEntry && this.canCreateGlobalEntry)
                || (view.isOrganizationEntry && this.canCreateOrganizationalEntry)
                || (view.isPersonalEntry))
            {
                views.push(view);
            }
        }
        return views;
    }

    get chips(): string[]
    {
        const chips = [];

        if (this.filters.author?.value)
        {
            chips.push(this.$t('LIBRARY_FILTER_CHIP_AUTHOR_PREFIX').toString() + this.filters.author.value);
        }

        for (const category of this.filters.category?.value)
        {
            chips.push(this.$t('LIBRARY_FILTER_CHIP_CATEGORY_PREFIX').toString() + category);
        }

        if (this.filters.defaultChallenge?.value)
        {
            chips.push(this.$t('LIBRARY_FILTER_CHIP_DEFAULT_CHALLENGE').toString());
        }

        return chips;
    }

    removeFilter(filter: string)
    {
        if (filter.startsWith(this.$t('LIBRARY_FILTER_CHIP_AUTHOR_PREFIX').toString()))
        {
            this.filters.author.value = '';
        }
        else if (filter.startsWith(this.$t('LIBRARY_FILTER_CHIP_CATEGORY_PREFIX').toString()))
        {
            const category = filter.slice(this.$t('LIBRARY_FILTER_CHIP_CATEGORY_PREFIX').toString().length);
            this.filters.category.value = this.filters.category.value.filter(value => value !== category)
        }
        else if (filter === this.$t('LIBRARY_FILTER_CHIP_DEFAULT_CHALLENGE').toString())
        {
            this.filters.defaultChallenge.value = false;
        }
    }

    get filteredEntries(): LibraryEntryView[]
    {
        return this.entries.filter(entry => {
            if (this.filters.author?.value && !entry.author?.toLowerCase().includes(this.filters.author.value.toLowerCase()))
            {
                return false;
            }

            if (this.filters.category?.value.length > 0 && !this.filters.category.value.includes(entry.category))
            {
                return false;
            }

            if (this.filters.defaultChallenge?.value && !entry.isDefaultEntry)
            {
                return false;
            }

            return true;
        })
    }

    get canCreateGlobalEntry(): boolean
    {
        return this.canCreateLibraryEntry(undefined, undefined);
    }

    get canCreateOrganizationalEntry(): boolean
    {
        return this.canCreateLibraryEntry(undefined, this.competition.organizationId);
    }

    get selectedEntries(): ILibraryEntry[]
    {
        return this.selectedEntryViews.map(({ entry }) => entry);
    }

    get pageSize(): number
    {
        return this.visibleEntryIds.length
    }

    created()
    {
        this.headers = [
            { text: '', value: 'action', align: 'center', sortable: false },
            { text: this.$t('LIBRARY_HEADER_NAME'), value: 'name' },
            { text: this.$t('LIBRARY_HEADER_CATEGORY'), value: 'category' },
            { text: this.$t('LIBRARY_HEADER_STATUS'), value: 'status' },
            { text: this.$t('LIBRARY_HEADER_RATING'), value: 'stars' },
            { text: this.$t('LIBRARY_HEADER_APPEARANCE'), value: 'appearance', align: 'right' },
        ]

        const filters = new LibraryFilterRecord()

        if (useAuthorizationStore().canUpdateChallengeProtectedInformation()) {
            this.headers.splice(2, 0, { text: this.$t('LIBRARY_HEADER_AUTHOR'), value: 'author' });
            this.headers.splice(2, 0, { text: this.$t('LIBRARY_HEADER_CREATION_DATE'), value: 'creationDate' });
        } else {
            delete filters.author
        }

        this.filters = filters
    }
    async mounted() 
    {
        await this.refresh();
    }

    async refresh()
    {
        this.onClearSelectedEntries();
        useLibraryEntryStore().fetchLibraryEntries();
    }

    async onDeleteClicked(entry:ILibraryEntry)
    {
        this.selectedEntry = entry;
        this.showDeleteDialog = true;
    }

    async onEditClicked(entry:ILibraryEntry)
    {
        this.selectedEntry = entry;
        this.showEditDialog = true;
    }

    async onAddClicked()
    {
        this.selectedEntry = new LibraryEntry();
        this.showEditDialog = true;
    }
    
    async onImportClicked()
    {
        this.showImportDialog = true;
    }

    async onExportClicked()
    {
        await csvExport(
                ['Challenge','Category','IsEnabled','IsDefaultEntry','IsOrganizationEntry','IsPersonalEntry','Stars','Appearance'],
                this.entries.map((e:LibraryEntryView) => [e.name, e.category, e.isEnabled, e.isDefaultEntry, e.isOrganizationEntry, e.isPersonalEntry, `${e.stars*20}%`, e.appearance]), 
                `ctf_library_entries.csv`);
    }

    onTableItemsChanged(items: LibraryEntryView[])
    {
        this.visibleEntryIds = items.map(entryView => entryView.entry.id);
    }

    onToggleSelectAllEntries({ value }: { value: boolean, items: LibraryEntryView[] })
    {
        // Clear all library entries if value if false
        if (!value)
        {
            this.onClearSelectedEntries();
        }
    }

    onEntrySelected({ value, item }: { value: boolean, item: LibraryEntryView })
    {
        // If a library entry on the page is deselected when all library entries were selected, change selection to only be the library entries on this page minus the deselected library entry
        if (this.selectedEntries.length === this.entries.length && !value)
        {
            this.selectedEntryViews = this.selectedEntryViews.filter(({ entry }) => entry.id !== item.entry.id && this.visibleEntryIds.includes(entry.id));
        }
    }

    onSelectAllEntries()
    {
        this.selectedEntryViews = [...this.filteredEntries];
    }

    onClearSelectedEntries()
    {
        this.selectedEntryViews = [];
    }

    async onDeleteSelectedEntries()
    {
        if (this.selectedEntries.length === 1)
        {
            this.selectedEntry = this.selectedEntries[0];
            this.showDeleteDialog = true;
        }
        else if (this.selectedEntries.length > 1)
        {
            this.showDeleteEntriesDialog = true;
        }
    }

    onExportSelectedEntries()
    {
        this.showExportEntriesDialog = true;
    }

    onAddSelectedEntries()
    {
        this.showAddSelectedEntriesDialog = true;
    }

    onChallengesAdded()
    {
        this.showAddSelectedEntriesDialog = false;
    }
}
</script>

<style scoped>
.theme--dark .v-data-table
{
    
}
</style>
<style>
.v-data-table td
{
    color: var(--v-text-darken1) !important;
}
</style>
