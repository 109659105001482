export default {
    en: {
        WELCOME_TITLE: "Player Options",
        WELCOME_PLAY_SOLO: "Play Solo",

        WELCOME_JOIN_A_TEAM: "Join a Team",
        WELCOME_JOIN_A_TEAM_TITLE: "Join a Team",
        WELCOME_JOIN_A_TEAM_MESSAGE: "To join a new team, please select the team to join below:",
        WELCOME_JOIN_A_TEAM_TEAM_NAME: "Team",
        WELCOME_JOIN_A_TEAM_CANCEL: "Cancel",
        WELCOME_JOIN_A_TEAM_CONFIRM: "Join",
        WELCOME_JOIN_A_TEAM_TEAM_NO_MATCHED: "The team with that name does not exist",
        WELCOME_JOIN_A_TEAM_IS_FULL: "{teamName} (full)",
        WELCOME_CREATE_A_TEAM: "Create a Team",
        WELCOME_CREATE_A_TEAM_TITLE: "Create a New Team",
        WELCOME_CREATE_A_TEAM_MESSAGE: "To create a new team, please enter the new team name below:",
        WELCOME_CREATE_A_TEAM_TEAM_NAME: "Team Name",
        WELCOME_CREATE_A_TEAM_CANCEL: "Cancel",
        WELCOME_CREATE_A_TEAM_CONFIRM: "Create",
        WELCOME_ACCOUNT_BEING_PREPARED: "Your account is being prepared..."
    }
};
