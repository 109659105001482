<template>
    <div>
        <v-row row wrap class="ma-2 py-3" justify="space-between">
            <h2 class="px-3">{{$t('TEAMS_TITLE')}}</h2>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn @click="onAddClicked" v-on="on" small fab outlined :aria-label="$t('TEAMS_ADD_TOOLTIP')">
                        <v-icon>add</v-icon>
                    </v-btn>
                </template>
                {{$t('TEAMS_ADD_TOOLTIP')}}
            </v-tooltip>
        </v-row>
        <v-data-table show-select v-model="selectedTeamViews" item-key="team.id" :dark="isDark" :headers="headers" :items="teams" :loading="isLoading" :loading-text="$t('TEAMS_LOADING')" :no-data-text="$t('TEAMS_NO_DATA')" class="elevation-0 alphaBackground" id="manageTeamsTable"  @toggle-select-all="onToggleSelectAllTeams" @item-selected="onTeamSelected" @current-items="onTableItemsChanged">
            <template v-slot:top>
                <table-header deleteButton :selected="selectedTeams.length" :page="pageSize" :total="teams.length" item="teams" @select-all="onSelectAllTeams" @clear="onClearSelectedTeams" @delete="onDeleteSelectedTeams" />
            </template>
            <template v-slot:item.action="{ item }">
                
                <accessible-menu offset-y :label="$t('TEAMS_ACTIONS_LABEL', { name: item.name })">
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on">settings</v-icon>
                    </template>
                    <v-list>
                        <v-list-item @click="onViewClicked(item.team)">
                            <v-list-item-avatar>
                                <v-icon>show_chart</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('TEAMS_VIEW')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="canUpdateTeam(item.team.id, competition.id, competition.organizationId)" @click="onEditClicked(item.team)">
                            <v-list-item-avatar>
                                <v-icon>edit</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('TEAMS_EDIT')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="canDeleteTeam(item.team.id, competition.id, competition.organizationId)" @click="onDeleteClicked(item.team)">
                            <v-list-item-avatar>
                                <v-icon>delete</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title>{{$t('TEAMS_DELETE')}}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </accessible-menu>

            </template>
            <template v-slot:header.action>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" :aria-label="$t('TEAMS_EXPORT_LABEL')" 
                            @click="onExportClicked">save_alt</v-icon>
                    </template>
                    <span>{{$t('TEAMS_EXPORT_LABEL')}}</span>
                </v-tooltip>
            </template>
            <template v-slot:item.name="{ item }">
                {{ item.name }}
                <v-tooltip v-if="item.hidden" bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small :dark="isDark" class="ml-2">visibility_off</v-icon>
                    </template>
                    <span>{{$t('TEAMS_HIDDEN_TOOLTIP')}}</span>
                </v-tooltip>
            </template>
            <template v-slot:item.stars="{ item }">
                <v-rating :show="true" :value="item.stars" half-increments :readonly="true"/>
            </template>
            <template v-slot:header.data-table-select="{ props, on }">
                <v-checkbox :aria-label="$t('TEAMS_TOGGLE_SELECT_ALL_LABEL')" class="pa-0 ma-0"
                    hide-details @change="on.input" :input-value="props.value" 
                    :indeterminate="props.indeterminate" />
            </template>
            <template v-slot:item.data-table-select="{ item, isSelected, select }">
                <v-checkbox class="pa-0 ma-0" hide-details
                    :aria-label="$t('TEAMS_SELECT_LABEL', {name: item.name})" 
                    @change="select" :input-value="isSelected" />
            </template>
        </v-data-table>
        <delete-team-dialog v-model="showTeamDeleteDialog" :team="selectedTeam" @confirm="refresh" />
        <delete-teams-dialog v-model="showTeamsDeleteDialog" :teams="selectedTeams" @confirm="refresh" />
        <edit-team-dialog v-model="showTeamEditDialog" :team="selectedTeam" @confirm="refresh" />
        <view-team-dialog v-model="showTeamViewDialog" :team="selectedTeam" @confirm="refresh" />
        <add-team-dialog v-model="showTeamAddDialog" @confirm="refresh" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';
import StoreGetter from '@/interfaces/storeGetter';
import { TeamFilter, ITeam, ITeamApiClient, Team } from '@cyber-range/cyber-range-api-ctf-team-client';
import { IPageResponse } from '@cyber-range/cyber-range-api';
import TeamView from './TeamView';
import { ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { IPlayerApiClient, IPlayer, PlayerFilter, IPlayerPage } from '@cyber-range/cyber-range-api-ctf-player-client';
import {csvExport} from '@/utils/csvExport';
import Config from '@/config';
import TitleStrings from '@/entities/strings/definitions/titleStrings';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';
import { useAuthorizationStore } from '@stores/authorizationStore';

@Component({ metaInfo: { title: TitleStrings.en.TITLE_MANAGE_TEAMS }})
export default class Teams extends Vue 
{
    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get teamApiClient(): ITeamApiClient
    {
        return useApiClientStore().teamApiClient;
    }
    get playerApiClient(): IPlayerApiClient
    {
        return useApiClientStore().playerApiClient;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    canUpdateTeam(teamId:string, competitionId:string, organizationId:string): boolean
    {
        return useAuthorizationStore().canUpdateTeam(teamId, competitionId, organizationId);
    }
    canDeleteTeam(teamId:string, competitionId:string, organizationId:string): boolean
    {
        return useAuthorizationStore().canDeleteTeam(teamId, competitionId, organizationId);
    }
    // END TODO

    teams:TeamView[] = [];
    headers = [];

    visibleTeamIds:string[] = [];
    selectedTeam:ITeam = new Team();
    selectedTeamViews:TeamView[] = [];
    showTeamDeleteDialog = false;
    showTeamsDeleteDialog = false;
    showTeamEditDialog = false;
    showTeamViewDialog = false;
    showTeamAddDialog = false;

    get selectedTeams(): ITeam[]
    {
        return this.selectedTeamViews.map(teamView => teamView.team);
    }

    get pageSize(): number
    {
        return this.visibleTeamIds.length;
    }

    created()
    {
        this.headers = [
            { text: '', value: 'action', align: 'center', sortable: false },
            { text: this.$t('TEAMS_HEADER_TEAM_NAME'), value: 'name' },
            { text: this.$t('TEAMS_HEADER_LEADER_NAME'), value: 'leaderName' },
            { text: this.$t('TEAMS_HEADER_CORRECT_FLAG_SUBMISSION'), value: 'stars', align: 'center' },
            { text: this.$t('TEAMS_HEADER_SCORE'), value: 'score', align: 'right' }
        ]
    }

    async mounted() 
    {
        await this.refresh();
    }

    async refresh()
    {
        this.onClearSelectedTeams();
        let [teams, players] = await Promise.all([this.getTeams(), this.getPlayers()]);
        
        let views = teams.map(team => {
            let view = new TeamView(team);
            view.leaderName = players[view.leaderUserId]?.name;
            return view;
        })
        
        this.teams = views;
    }

    async getPlayers(): Promise<{[userId:string]:IPlayer}>
    {
        let players:{[userId:string]:IPlayer} = {};

        let filter = new PlayerFilter({competitionId: this.competition.id, limit:Config.DEFAULT_FETCH_SIZE});
        let page:IPlayerPage;

        do
        {
            page = await this.playerApiClient.get(filter);
            filter.token = page.nextPageToken;
            for(let item of page.items)
            {
                players[item.userId] = item;
            }
        }
        while(page.nextPageToken);

        return players;
    }

    async getTeams(): Promise<ITeam[]>
    {
        let teams:ITeam[] = [];

        let filter = new TeamFilter({competitionId: this.competition.id, limit: Config.DEFAULT_FETCH_SIZE});
        let page:IPageResponse<ITeam>;

        do
        {
            page = await this.teamApiClient.get(filter);
            filter.token = page.nextPageToken;
            teams = teams.concat(page.items);
        }
        while(page.nextPageToken);

        return teams;
    }

    async onExportClicked()
    {
        await csvExport(
                this.headers.slice(1).map(h=>h.text), 
                this.teams.map(t => [t.name, t.leaderName, `${t.stars*20}%`, t.score]), 
                `ctf_teams.csv`);
    }

    onDeleteClicked(team:ITeam)
    {
        this.selectedTeam = team;
        this.showTeamDeleteDialog = true;
    }

    onEditClicked(team:ITeam)
    {
        this.selectedTeam = team;
        this.showTeamEditDialog = true;
    }

    onViewClicked(team:ITeam)
    {
        this.selectedTeam = team;
        this.showTeamViewDialog = true;
    }

    onAddClicked()
    {
        this.showTeamAddDialog = true;
    }

    onTableItemsChanged(items: TeamView[])
    {
        this.visibleTeamIds = items.map(({ team }) => team.id);
    }

    onToggleSelectAllTeams({ value }: { value: boolean, items: TeamView[] })
    {
        // Clear all selected teams if value if false
        if (!value)
        {
            this.onClearSelectedTeams();
        }
    }

    onTeamSelected({ value, item }: { value: boolean, item: TeamView })
    {
        // If a team on the page is deselected when all teams were selected, change selection to only be the teams on this page minus the deselected team
        if (this.selectedTeamViews.length === this.teams.length && !value)
        {
            this.selectedTeamViews = this.selectedTeamViews.filter(({ team }) => team.id !== item.team.id && this.visibleTeamIds.includes(team.id));
        }
    }

    onSelectAllTeams()
    {
        this.selectedTeamViews = [...this.teams];
    }

    onClearSelectedTeams()
    {
        this.selectedTeamViews = [];
    }

    async onDeleteSelectedTeams()
    {
        if (this.selectedTeamViews.length === 1)
        {
            this.selectedTeam = this.selectedTeams[0];
            this.showTeamDeleteDialog = true;
        }
        else if (this.selectedTeamViews.length > 1)
        {
            this.showTeamsDeleteDialog = true;
        }
        else
        {
            // Do nothing
        }
    }
}
</script>