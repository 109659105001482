<template>
    <confirmation-dialog v-model="showConfirmationDialog" :title="$t('CHALLENGE_IMPORT_DIALOG_TITLE')" 
        @confirm="confirm" @cancel="cancel" :loading="isLoading" 
        :confirm="!importCompleted ? $t('CHALLENGE_IMPORT_DIALOG_IMPORT') : $t('CHALLENGE_IMPORT_DIALOG_CONFIRM')"
        :cancel="!importCompleted && $t('CHALLENGE_IMPORT_DIALOG_CANCEL')"
    >
        <v-container v-if="!(isLoading || importCompleted)" id="importFileInputContainer">
            <span v-html="$t('CHALLENGE_IMPORT_DIALOG_MESSAGE')" />
            <v-form ref="form" :lazy-validation="true">
                <v-file-input 
                    :label=" $t('CHALLENGE_IMPORT_DIALOG_FILE_ADD')" 
                    v-model="file" :rules="fileRules" 
                    show-size 
                    :dark="isDialogDark" 
                    :light="isDialogLight" 
                    class="importChallengesDialogFile" 
                />
            </v-form>
        </v-container>
        <v-row v-else-if="isLoading" justify="center" align="center" id="importProgress">
            <v-progress-circular 
                indeterminate size="20" 
                :width="3" color="unset" 
                :dark="isDialogDark" :light="isDialogLight" class="progress">
            </v-progress-circular>
        </v-row>
        <v-container v-else id="importResultsContainer">
            <v-row v-if="importStatistics.statistics.failed > 0" justify="center" id="importFailureIcon">
                <p class="text-center pt-3"><v-icon color="warning" x-large>report_problem</v-icon></p>
            </v-row>
            <v-row v-else justify="center" id="importSuccessIcon">
                <p class="text-center pt-3"><v-icon color="success" x-large>check</v-icon></p>
            </v-row>
            <v-row justify="center" id="importSuccessMessage">    
                <span v-html="successMessage" 
                />
            </v-row>
            <v-row v-if="importStatistics.statistics.failed > 0" justify="center" id="importFailureMessage">    
                <span v-html="errorMessage" 
                />
            </v-row>
            <v-container fluid v-if="importStatistics.statistics.failed > 0" class="mt-3 text-center" id="importErrorMessages">
                <v-row justify="center" v-for="error, i of importStatistics.errors" :key="error.entryName" :index="i" :id="`importError${i}`">
                    <span>
                        {{constructErrorDetails(error)}}
                    </span>
                </v-row>
            </v-container>
        </v-container>
    </confirmation-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Rule from '@/validations/Rule';
import Config from '@/config';
import { IImportStatistics, ICompetitionApiClient, ImportFormat, ICompetition } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';
import { useCompetitionStore } from '@stores/competitionStore';

@Component({})
export default class ImportChallengesDialog extends Vue 
{    
    @Prop(Boolean) value: boolean;
    
    // TODO: Change this to composition api
    get isDialogDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isDialogLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    get competitionApiClient(): ICompetitionApiClient
    {
        return useApiClientStore().competitionApiClient;
    }
    get competition(): ICompetition
    {
        return useCompetitionStore().currentCompetition;
    }
    // END TODO

    importCompleted: boolean = false;
    importStatistics: IImportStatistics = undefined;
    file: File = null;
    fileRules = [
        (v: any) => v?.size 
            ? Rule.maxSizeInMB(v, Config.MAX_IMPORT_CHALLENGES_FILE_SIZE_IN_MB) 
            : true
    ];

    get showConfirmationDialog() {
        return this.value;
    }

    get successMessage() {
        return this.$tc('CHALLENGE_IMPORT_DIALOG_SUCCESSFUL_IMPORTS', 
            this.importStatistics.statistics.completed); 
    }

    get errorMessage() {
        return this.$tc('CHALLENGE_IMPORT_DIALOG_FAILED_IMPORTS', 
            this.importStatistics.statistics.failed,
            {
                conjunctive: this.importStatistics.statistics.completed > 0
                    ? this.$t('CHALLENGE_IMPORT_DIALOG_FAILED_IMPORTS_CONJUNCTIVE')
                    : ''            
            }
        );
    }

    constructErrorDetails(error: {entryName: string, message: string}) {
        return `'${error.entryName}' - ${error.message}`
    }
        
    async confirm() 
    {
        if (this.importCompleted) {
            this.$emit('confirm', true);
            this.importCompleted = false;
            this.importStatistics = null;
            this.file = undefined;
            this.close();
            return;    
        }
        
        if((<any>this.$refs.form).validate() === false || this.file === undefined) return;
        
        if (this.file.type.includes('text') || this.file.name.endsWith(".ctf")) {
            this.importStatistics = await this.competitionApiClient.import(
                this.competition.id, await this.file.text(), ImportFormat.Text
            );
            this.importCompleted = true;
        }
        else if (this.file.type.includes('gzip') || this.file.name.endsWith(".ctfz")) {
            this.importStatistics = await this.competitionApiClient.import(
                this.competition.id, Buffer.from(await this.file.arrayBuffer()), ImportFormat.Compressed
            );
            this.importCompleted = true;
        }
    }
    
    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }
    
    close()
    {
        this.$emit('input', false);
    }
}
</script>
