import ITheme from "@/interfaces/iTheme";
import backgroundImage from "@assets/themes/cyberIcons/cyberIconsBackgroundImage.svg";

export class CyberIconsTheme implements ITheme
{
    static Id = 'cybericons';
    id = CyberIconsTheme.Id;

    name = 'Cyber Icons';

    colors = {
                application: '#3C5A82', //#051c29
                text: '#E0E0E0',
                confirm: '#21b68b',
                cancel: '#000000',
                secondary: '#62B4E4',
                primary: '#FFFFFF',
                falseswitch: '#808080',
                solved: '#69a9db',
                solvedText: '#000000',
                unsolved: '#21b68b',
                unsolvedText: '#000000',
                noMoreAttempts: '#E5803C',
                noMoreAttemptsText: '#000000',
                disabled: '#FF4B1F',
                tag: '#62B4E4',
                activeTab: '#62B4E4',
                dialogText: '#303030',
                dialogSuccessText: '#4CAF50',
                dialogFailureText: '#F44336',
                dialogIcon: '#553A97',
                success: '#4CAF50',
                warning: '#FF9800',
                link: '#62B4E4'
             };
        
    fontFamily = "ShareTech";
    backgroundImage = backgroundImage;
    isDark = true;
    isDialogDark = false;
    isConfirmLight = true;
}