<template>
    <v-card role="button" :aria-label="challenge.name" tag="article" class="elevation-0 alphaBackground obviousWhenSelected" @click="onClicked" v-on:keyup.enter="onClicked" v-on:keyup.space="onClicked" :disabled="isLoading" :color="color" align="center" :class="classes">
        <v-card-title>
            <header>
                {{challenge.name}}
            </header>
        </v-card-title>
        <v-card-text>
            <template v-if="isSolved">
                <v-icon>done</v-icon>
            </template>
            <v-icon v-else-if="!hasRemainingAttempts">close</v-icon>
            <template v-if="!challenge.enabled">
                <v-icon color='disabled' class='challengeDisabledIcon'>visibility_off</v-icon>
            </template>
            <template v-else>
                {{challenge.points}}
            </template>
        </v-card-text>
        <challenge-dialog v-if="showChallenge" v-model="showChallenge" :challengeId="challenge.id" @edit="onEditChallenge" @result="onSubmissionResult" class="challengeDialog"/>
        <add-edit-challenge-dialog v-model="showEditChallengeDialog" :challengeId="challenge.id" @confirm="onChallengeUpdated" @deleted="onChallengeUpdated" class="addEditChallengeDialog" />
        <submission-result-dialog v-model="showSubmissionResultDialog" :submission-result="submissionResult" class="submissionResultDialog"/>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import StoreGetter from '@interfaces/storeGetter';
import { IChallenge, ISubmissionResult, SubmissionResult } from '@cyber-range/cyber-range-api-ctf-challenge-client';
import { Prop } from 'vue-property-decorator';
import StoreAction from '@interfaces/storeAction';
import { IChallengeSubmissionStatistics } from '@cyber-range/cyber-range-api-ctf-submission-client';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';

@Component({components:{}})
export default class Challenge extends Vue 
{   
    @Prop(Object) challenge:IChallenge;
    @Getter(StoreGetter.IsSolved) checkIsSolved: (challengeId:string) => boolean;
    @Getter(StoreGetter.MyTeamsChallengesStatistics) getMyTeamsChallengeStatistics: (challengeId:string) => IChallengeSubmissionStatistics;
    @Action(StoreAction.FetchChallenges) fetchChallenges: ()=>Promise<void>;

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDark;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    // END TODO

    showChallenge:boolean = false;
    showEditChallengeDialog:boolean = false;
    showSubmissionResultDialog:boolean = false;
    submissionResult:ISubmissionResult = new SubmissionResult();

    get isSolved(): boolean
    {
        return this.checkIsSolved(this.challenge.id);
    }

    get hasRemainingAttempts(): boolean
    {
        if (this.challenge.settings?.maxNumberOfAttempts == undefined)
        {
            return true;
        }

        const statistics = this.getMyTeamsChallengeStatistics(this.challenge.id);
        if (!statistics) 
        {
            return true;
        }

        return statistics.failedAttempts < this.challenge.settings.maxNumberOfAttempts;
    }

    get color(): string
    {
        if (this.isSolved)
        {
            return 'solved';
        }
        else if (!this.hasRemainingAttempts)
        {
            return 'noMoreAttempts'
        }
        else
        {
            return 'unsolved'
        }
    }

    get classes()
    {
        return [`${this.color}Text--text`, this.color];
    }

    onClicked()
    {
        this.showChallenge = true;
    }

    async onEditChallenge()
    {
        this.showEditChallengeDialog = true;
    }

    async onChallengeUpdated()
    {
        await this.fetchChallenges();
    }

    onSubmissionResult(result:ISubmissionResult)
    {
        this.submissionResult = result;
        this.showSubmissionResultDialog = true;
    }
}
</script>
<style scoped>
header
{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

.usCyberGamesSeasonII .solved.obviousWhenSelected:focus
{
    outline: 6px solid var(--v-unsolved-base);
}
.usCyberGamesSeasonII .unsolved.obviousWhenSelected:focus
{
    outline: 6px solid var(--v-solved-base);
}
</style>