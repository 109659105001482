<template>
    <div>
        <confirmation-dialog v-model="showDialog" :title="$t('LIBRARY_ENTRIES_DIALOG_TITLE')" :confirm="$t('LIBRARY_ENTRIES_DIALOG_CONFIRM')" :confirmDisabled="!highlightedEntry.id" @confirm="confirm" @cancel="cancel" :disabled="isLoading" id="LibraryEntriesDialog">
            
            <v-row row wrap>
                <v-col cols="12" sm="5" class="pl-0 pt-0">
                    <library-entries-list v-model="selectedEntries" @value="onEntrySelected" />
                </v-col>
                
                <v-col cols="12" sm="7" class="pt-0">
                    <library-entry-preview v-if="highlightedEntry.id" :entry="highlightedEntry" class="libraryEntryPreview" />
                    <library-entries-dialog-splash-screen v-else class="libraryEntriesDialogSplashScreen" />
                </v-col>
            </v-row>
        </confirmation-dialog>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import { ILibraryEntry, LibraryEntry } from '@cyber-range/cyber-range-api-ctf-library-client';
import { useThemeStore } from '@/stores/themeStore';
import { useApiClientStore } from '@stores/apiClientStore';

@Component
export default class LibraryEntriesDialog extends Vue 
{
    @Prop(Boolean) value:boolean;
    @PropSync("entries", Array) selectedEntries: ILibraryEntry[]

    // TODO: Change this to composition api
    get isDark():boolean
    {
        return useThemeStore().isDialogDark;
    }
    get isLight():boolean
    {
        return useThemeStore().isDialogLight;
    }
    get isLoading(): boolean
    {
        return useApiClientStore().isLoading;
    }
    // END TODO
    
    showDialog:boolean = false;
    highlightedEntry:ILibraryEntry = new LibraryEntry();

    @Watch('value')
    onValueChanged(value:boolean)
    {
        if(value) this.load();
    }

    onEntrySelected(entry:ILibraryEntry): void
    {
        this.highlightedEntry = entry;
    }

    mounted() 
    {
        if(this.value) this.load();
    }

    async load() 
    {
        this.showDialog = true;
    }
    
    async confirm()
    {
        this.$emit('confirm', this.selectedEntries);
        this.close();
    }

    cancel()
    {
        this.$emit('cancel', true);
        this.close();
    }

    close()
    {
        this.showDialog = false;
        this.$emit('input', false);
    }
}
</script>